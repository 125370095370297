import React, { Component } from "react";
import firebase from "firebase";
import "firebase/firestore";
import * as alasql from "alasql";
import "./Stand.css";
import "firebase/auth";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import Moment from "moment";
//import Button from "@material-ui/core/Button";

const firestore = firebase.firestore();
//firestore.settings({ timestampsInSnapshots: true });

class Stand5Pins extends Component {
  constructor(props) {
    super(props);
    // console.log("This.props.match", this.props.match);
    this.state = {
      data: [],
      avond: this.props.avond,
      persoonlijk: this.props.details === "persoonlijk",
      team: this.props.details === "team",
      wedstrijden: this.props.details === "wedstrijden",
      dagdeel: "",
      typestand: "",
      wed: [],
      kleur: false,
      poule: "",
      filteredName: ""
    };
  }
  state = { data: [], dagdeel: "" };

  componentDidMount() {
    let done = false;
    if (
      window.location.href
        .split("//")[1]
        .split("/")[1]
        .indexOf("maandagavond") > -1
    ) {
      this.setState({ typestand: "Maandag" });
      done = true;
    }
    if (
      !done &&
      window.location.href
        .split("//")[1]
        .split("/")[1]
        .indexOf("woensdagavond") > -1
    ) {
      this.setState({ typestand: "Woensdag" });
      done = true;
    }
    if (
      !done &&
      window.location.href
        .split("//")[1]
        .split("/")[1]
        .indexOf("donderdagavond") > -1
    ) {
      this.setState({ typestand: "MP" });
      done = true;
    }
    // if (!done) {
    //   this.setState({ typestand: "BSK" });
    //   done = true;
    // }
    if (!done) {
      this.setState({ poule: "A" });
      done = true;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      if (prevState.poule !== this.state.poule) {
        this.getData();
      }
    }
  }

  getData = async () => {
    let dagdeel = "";
    var keys = this.props.avond;

    if (keys === null) {
      keys = "niets";
    }

    console.log("keys: ", keys);
    switch (keys.toLowerCase()) {
      case "nk5pins":
        //console.log("NK5Pins");
        //dagdeel = "NK5Pins";
        dagdeel = "5Pins";
        break;
      case "biatlon":
        console.log("Biatlon");
        // dagdeel = "NK5Pins";
        dagdeel = "Biatlon";
        break;

      default:
        dagdeel = "niets";
        break;
    }
    // const eenweekgeleden = new Date(
    //   new Date() - 60 * 60 * 24 * 7 * 1000
    // ).getTime();
    this.setState({ dagdeel: keys });
    // console.log(dagdeel);
    var gespeeldewedstrijdenRef = firestore.collection(
      `/OnderOns/Wedstrijden/${dagdeel}/`
    );
    var spelersdetails = firestore.collection(
      `/OnderOns/AlleSpelerslijsten/${dagdeel}/`
    );

    // const UpdatePunten = rec => {
    //   //console.log(rec);

    //   // if (this.state.typestand === "Woensdag") {
    //   //   //rec.Punten = 0;
    //   //   rec.Punten = rec.OrgPunten;
    //   //   return rec;
    //   // }
    //   if (this.state.typestand === "BSK") {
    //     //console.log(rec);
    //     let done = false;
    //     if (rec.Winnaar === "Remise") {
    //       rec.Punten += 1;
    //     }
    //     //Extra punt voor minder dan 26 beurten
    //     if (rec.Beurten < 25 && rec.Winnaar === rec.Naam) {
    //       rec.Punten += 1;
    //     }
    //     done = true;

    //     if (!done) {
    //       if (rec.Naam.startsWith(rec.Winnaar)) {
    //         rec.Punten += 2;
    //         //Extra punt voor minder dan 26 beurten
    //         if (rec.Beurten < 25) {
    //           rec.Punten += 1;
    //         }
    //       }
    //     }
    //     done = true;
    //     //("Returned", rec.Naam, rec.Punten);
    //     return rec;
    //   }
    //   if (this.state.typestand === "Maandag" || "Woensdag") {
    //     //console.log(rec);
    //     let done = false;
    //     let dubbelevoornaam = false;
    //     const extrapunt =
    //       parseFloat(rec.Gemiddelde.replace(",", ".")) >
    //       parseFloat(rec.Gem.replace(",", "."))
    //         ? 1
    //         : 0;
    //     if (rec.Winnaar === "Remise") {
    //       rec.Punten = 1 + extrapunt;
    //       done = true;
    //     }

    //     if (
    //       !done &&
    //       rec.Naam.startsWith(rec.Winnaar) &&
    //       rec.Tegenstander.startsWith(rec.Winnaar)
    //     ) {
    //       dubbelevoornaam = true;
    //       // console.log("2 dezelfde namen");
    //       // console.log(rec.Naam, rec.Punten);
    //       if (rec.Temakencaramboles === rec.Caramboles) {
    //         rec.Punten = 2 + extrapunt;
    //         done = true;
    //         //console.log(rec);
    //         //console.log(rec.Naam, rec.Punten);
    //       } else {
    //         // Hier moet nog iets gebeuren met beurtenlimiet
    //         rec.Punten = 0 + extrapunt;
    //         done = true;
    //       }
    //       //console.log(rec.Naam, rec.Punten);
    //     }

    //     if (!done && dubbelevoornaam) {
    //     }

    //     if (!done && rec.Naam.startsWith(rec.Winnaar)) {
    //       rec.Punten = 2 + extrapunt;
    //       done = true;
    //     }
    //     if (!done) {
    //       rec.Punten = 0 + extrapunt;
    //     }
    //     // if (rec.Naam.startsWith("Cor")) {
    //     //   console.log(rec);
    //     // }
    //     return rec;
    //   }
    //   if (this.state.typestand === "MP") {
    //     //rec.Punten = rec.OrgPunten;
    //     //In de opmaak van de stand wordt automatisch gesorteerd op MP% als this.state.typestand === "MP"
    //     return rec;
    //   }
    // };

    const GetMoyenne = spArr => {
      var moy = [];
      moy = spelers.filter(function(el) {
        return el.Naam === spArr;
      });
      // console.log("Moy", moy);
      let retwaarde = "";
      try {
        retwaarde = moy[0].Moyenne.toString().replace(".", ",");
      } catch (error) {
        retwaarde = "";
      }
      return retwaarde;
    };
    const spelers = [];

    const HaalDataOp = poule => {
      spelersdetails
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(function(doc) {
            // Eerst de uitslag platmaken
            let tmpRec = [];

            tmpRec = {
              Naam: doc.data().Naam + " " + doc.data().Achternaam,
              Moyenne: doc.data().Moyenne,
              id: doc.id
            };
            spelers.push(tmpRec);
          });
        })
        // .then(() => {
        //   // var moy = [];
        //   // moy = spelers.filter(function(el) {
        //   //   return el.Naam === "Andre Kater";
        //   // });
        //   // let a = GetMoyenne(moy);
        //   // console.log(a);
        // })
        .then(() => {
          gespeeldewedstrijdenRef
            .where("Poule", "==", this.state.poule)
            //.limit(8)
            .get()
            .then(querySnapshot => {
              const stand = [];
              const wedstrijddetails = [];
              querySnapshot.forEach(function(doc) {
                //console.log(doc.data());
                // Eerst de uitslag platmaken
                let tmpRec = [];
                let wedstrijdUitslag = [];
                let tmpwed = [];
                tmpwed = {
                  id: doc.id,
                  Datum: new Date(doc.data().Datum).toISOString().split("T")[0],
                  Tijd: new Date(doc.data().Datum).toISOString().split("T")[1],
                  Starttijd: new Date(doc.data().Starttijd).getTime(),
                  Eindtijd: doc.data().Eindtijd,

                  // parseInt(
                  //   new Date(doc.data().Datum).getDay().toString() +
                  //     new Date(doc.data().Datum).getMonth().toString() +
                  //     new Date(doc.data().Datum).getFullYear().toString()
                  //),
                  Winnaar: doc.data().Winnaar,
                  Voornaam1: doc.data().Speler1,
                  Voornaam2: doc.data().Speler2,
                  Achternaam1: doc.data().Speler1Achternaam,
                  Achternaam2: doc.data().Speler2Achternaam,
                  AantalsetsSpeler1:
                    doc.data().BestOf === 3
                      ? doc.data().AantalsetsSpeler1 === 2 &&
                        doc.data().AantalsetsSpeler2 === 0
                        ? 3
                        : doc.data().AantalsetsSpeler1
                      : doc.data().AantalsetsSpeler1,
                  AantalsetsSpeler2:
                    doc.data().BestOf === 3
                      ? doc.data().AantalsetsSpeler2 === 2 &&
                        doc.data().AantalsetsSpeler1 === 0
                        ? 3
                        : doc.data().AantalsetsSpeler2
                      : doc.data().AantalsetsSpeler2,
                  Set1PuntenSP1: doc.data().Speler1Set1Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set1Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set2PuntenSP1: doc.data().Speler1Set2Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set2Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set3PuntenSP1: doc.data().Speler1Set3Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set3Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set1PuntenSP2: doc.data().Speler2Set1Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set1Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set2PuntenSP2: doc.data().Speler2Set2Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set2Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set3PuntenSP2: doc.data().Speler2Set3Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set3Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Biatlon3BgemSp1: doc.data().Speler1Verloop3Banden
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Verloop3Banden.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      ) / doc.data().Speler1Verloop3Banden.split(",").length
                    : 0,
                  Biatlon3BgemSp2: doc.data().Speler2Verloop3Banden
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Verloop3Banden.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      ) / doc.data().Speler2Verloop3Banden.split(",").length
                    : 0,

                  AantalBeurten3BandenBiatlon: doc.data()
                    .AantalBeurten3BandenBiatlon
                    ? doc.data().AantalBeurten3BandenBiatlon === 99
                      ? null
                      : doc.data().AantalBeurten3BandenBiatlon
                    : null,
                  Biatlon3bSerieSP1: doc.data().Speler1Verloop3Banden,
                  Biatlon3bSerieSP2: doc.data().Speler2Verloop3Banden,
                  Speler1:
                    doc.data().Speler1 +
                    " " +
                    doc.data().Speler1Achternaam.substring(0, 1),
                  Speler2:
                    doc.data().Speler2 +
                    " " +
                    doc.data().Speler2Achternaam.substring(0, 1),
                  Gem1: (doc.data().GemaaktSP1 / doc.data().Beurten).toFixed(3),
                  Gem2: (doc.data().GemaaktSP2 / doc.data().Beurten).toFixed(3),
                  vijfPinsMoySP1: parseFloat(
                    doc.data().GemiddeldeSpeler1.replace(",", ".")
                  ).toFixed(3),
                  vijfPinsMoySP2: parseFloat(
                    doc.data().GemiddeldeSpeler2.replace(",", ".")
                  ).toFixed(3),
                  Beurten: doc.data().Beurten,
                  HS1: doc.data().Speler1HoogsteSerie,
                  HS2: doc.data().Speler2HoogsteSerie,
                  TemakenCarambolesSpeler1: doc.data().TemakenCarambolesSpeler1,

                  TemakenCarambolesSpeler2: doc.data().TemakenCarambolesSpeler2,
                  GemaaktSP1: doc.data().GemaaktSP1,
                  GemaaktSP2: doc.data().GemaaktSP2,
                  Speler1PuntenBelgischsysteem: doc.data()
                    .Speler1PuntenBelgischsysteem,
                  Speler1Punten:
                    doc.data().BestOf === 3
                      ? doc.data().Speler1Punten === 3
                        ? 1
                        : doc.data().Speler1Punten
                      : doc.data().Speler1Punten,
                  Speler2Punten:
                    doc.data().BestOf === 3
                      ? doc.data().Speler2Punten === 3
                        ? 1
                        : doc.data().Speler2Punten
                      : doc.data().Speler2Punten,
                  Speler2PuntenBelgischsysteem: doc.data()
                    .Speler2PuntenBelgischsysteem,

                  Thuis: doc.data().Thuis,
                  Uit: doc.data().Uit,
                  Fase: doc.data().Fase,
                  Poule: parseInt(
                    Math.min(doc.data().Thuis, doc.data().Uit).toString() +
                      Math.max(doc.data().Thuis, doc.data().Uit).toString(),
                    10
                  )
                };
                console.log("Wedstrijden parse", tmpwed);
                wedstrijdUitslag = tmpwed;
                tmpRec = {
                  id: doc.id,
                  Datum: doc.data().Datum,
                  Naam: doc.data().Speler1 + " " + doc.data().Speler1Achternaam,
                  AantalSetsSpelerVoor:
                    doc.data().BestOf === 3
                      ? doc.data().AantalsetsSpeler1 === 2 &&
                        doc.data().AantalsetsSpeler2 === 0
                        ? 3
                        : doc.data().AantalsetsSpeler1
                      : doc.data().AantalsetsSpeler1,
                  AantalSetsSpelerTegen:
                    doc.data().BestOf === 3
                      ? doc.data().AantalsetsSpeler2 === 2 &&
                        doc.data().AantalsetsSpeler1 === 0
                        ? 3
                        : doc.data().AantalsetsSpeler2
                      : doc.data().AantalsetsSpeler2,
                  vijfPinsMoySP1: parseFloat(
                    doc.data().GemiddeldeSpeler1.replace(",", ".")
                  ).toFixed(3),
                  vijfPinsMoySP2: parseFloat(
                    doc.data().GemiddeldeSpeler2.replace(",", ".")
                  ).toFixed(3),
                  Set1PuntenSP1: doc.data().Speler1Set1Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set1Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set2PuntenSP1: doc.data().Speler1Set2Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set2Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set3PuntenSP1: doc.data().Speler1Set3Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set3Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set1PuntenSP2: doc.data().Speler2Set1Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set1Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set2PuntenSP2: doc.data().Speler2Set2Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set2Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set3PuntenSP2: doc.data().Speler2Set3Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set3Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Caramboles: doc.data().GemaaktSP1,
                  Tegenstander:
                    doc.data().Speler2 + " " + doc.data().Speler2Achternaam,
                  Temakencaramboles: doc.data().TemakenCarambolesSpeler1,
                  TegenstanderCaramboles: doc.data().GemaaktSP2,
                  TegenstanderTemakencaramboles: doc.data()
                    .TemakenCarambolesSpeler2,
                  Gespeeld: 1,
                  Beurten: doc.data().Beurten,
                  Gemiddelde: doc.data().GemiddeldeSpeler1,
                  Gem: parseFloat(
                    GetMoyenne(
                      doc.data().Speler1 + " " + doc.data().Speler1Achternaam
                    ).replace(",", ".")
                  ).toFixed(keys.indexOf("3b") > 0 ? 3 : 2),
                  OrgPunten: doc.data().Speler1Punten,
                  Punten:
                    doc.data().BestOf === 3
                      ? doc.data().Speler1Punten === 3
                        ? 1
                        : doc.data().Speler1Punten
                      : doc.data().Speler1Punten,

                  HoogsteSerie: doc.data().Speler1HoogsteSerie || 0,

                  Moyenne:
                    doc.data().GemaaktSP1 ===
                    doc.data().TemakenCarambolesSpeler1
                      ? parseFloat(
                          doc.data().GemiddeldeSpeler1.replace(",", ".")
                        ).toFixed(keys.indexOf("3b") > 0 ? 3 : 2)
                      : 0,
                  Team: doc.data().Thuis || 0,
                  Winnaar: doc.data().Winnaar
                  // Set1Punten:
                  //   doc
                  //     .data()
                  //     .Speler1Set1Scoreverloop.split(",")
                  //     .reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0,
                  // Set2Punten:
                  //   doc
                  //     .data()
                  //     .Speler1Set2Scoreverloop.split(",")
                  //     .reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0,
                  // Set3Punten:
                  //   doc
                  //     .data()
                  //     .Speler1Set3Scoreverloop.split(",")
                  //     .reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0
                };
                //let eindRec = [];
                //eindRec = UpdatePunten(tmpRec, true);
                // console.log(eindRec);
                //stand.push(eindRec);
                //console.log("Speler 1 : ", tmpRec);
                stand.push(tmpRec);
                // console.log("TmpRec", tmpRec);
                tmpRec = {
                  id: doc.id,
                  Naam: doc.data().Speler2 + " " + doc.data().Speler2Achternaam,
                  Caramboles: doc.data().GemaaktSP2,
                  AantalSetsSpelerVoor:
                    doc.data().BestOf === 3
                      ? doc.data().AantalsetsSpeler2 === 2 &&
                        doc.data().AantalsetsSpeler1 === 0
                        ? 3
                        : doc.data().AantalsetsSpeler2
                      : doc.data().AantalsetsSpeler2,
                  AantalSetsSpelerTegen:
                    doc.data().BestOf === 3
                      ? doc.data().AantalsetsSpeler1 === 2 &&
                        doc.data().AantalsetsSpeler2 === 0
                        ? 3
                        : doc.data().AantalsetsSpeler1
                      : doc.data().AantalsetsSpeler1,
                  Set1PuntenSP2: doc.data().Speler1Set1Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set1Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set2PuntenSP2: doc.data().Speler1Set2Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set2Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set3PuntenSP2: doc.data().Speler1Set3Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler1Set3Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set1PuntenSP1: doc.data().Speler2Set1Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set1Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set2PuntenSP1: doc.data().Speler2Set2Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set2Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  Set3PuntenSP1: doc.data().Speler2Set3Scoreverloop
                    ? parseInt(
                        doc
                          .data()
                          .Speler2Set3Scoreverloop.split(",")
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      )
                    : 0,
                  vijfPinsMoySP1: parseFloat(
                    doc.data().GemiddeldeSpeler2.replace(",", ".")
                  ).toFixed(3),
                  vijfPinsMoySP2: parseFloat(
                    doc.data().GemiddeldeSpeler1.replace(",", ".")
                  ).toFixed(3),
                  Temakencaramboles: doc.data().TemakenCarambolesSpeler2,
                  TegenstanderCaramboles: doc.data().GemaaktSP1,
                  TegenstanderTemakencaramboles: doc.data()
                    .TemakenCarambolesSpeler1,
                  Tegenstander:
                    doc.data().Speler1 + " " + doc.data().Speler1Achternaam,
                  Gespeeld: 1,
                  Beurten: doc.data().Beurten,
                  Gem: parseFloat(
                    GetMoyenne(
                      doc.data().Speler2 + " " + doc.data().Speler2Achternaam
                    ).replace(",", ".")
                  ).toFixed(keys.indexOf("3b") > 0 ? 3 : 2),
                  Gemiddelde: doc.data().GemiddeldeSpeler2,
                  OrgPunten: doc.data().Speler2Punten,
                  Punten:
                    doc.data().BestOf === 3
                      ? doc.data().Speler2Punten === 3
                        ? 1
                        : doc.data().Speler2Punten
                      : doc.data().Speler2Punten,
                  HoogsteSerie: doc.data().Speler2HoogsteSerie || 0,
                  Moyenne:
                    doc.data().GemaaktSP2 ===
                    doc.data().TemakenCarambolesSpeler2
                      ? parseFloat(
                          doc.data().GemiddeldeSpeler2.replace(",", ".")
                        ).toFixed(keys.indexOf("3b") > 0 ? 3 : 2)
                      : 0,
                  Team: doc.data().Uit || 0,
                  Winnaar: doc.data().Winnaar

                  // Set1Punten:
                  //   doc
                  //     .data()
                  //     .Speler2Set1Scoreverloop.split(",")
                  //     .reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0,
                  // Set2Punten:
                  //   doc
                  //     .data()
                  //     .Speler2Set2Scoreverloop.split(",")
                  //     .reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0,
                  // Set3Punten:
                  //   doc
                  //     .data()
                  //     .Speler2Set2Scoreverloop.split(",")
                  //     .reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0
                };
                // let eindRec2 = [];
                // eindRec2 = UpdatePunten(tmpRec, true);

                // stand.push(eindRec2);
                stand.push(tmpRec);
                //console.log("Speler 2:", tmpRec);
                // let updatedWedstrijdPunten = [];
                // updatedWedstrijdPunten = UpdatePunten(wedstrijdUitslag, true);

                wedstrijddetails.push(wedstrijdUitslag);
              });
              //console.log("Wedstrijddetails", wedstrijddetails);
              //!------ keysort toekennen aan de array
              stand.keySort = function(keys) {
                keys = keys || {};

                var obLen = function(obj) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++;
                  }
                  return size;
                };

                var obIx = function(obj, ix) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key;
                      size++;
                    }
                  }
                  return false;
                };

                var keySort = function(a, b, d) {
                  d = d !== null ? d : 1;
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0;
                  return a > b ? 1 * d : -1 * d;
                };

                var KL = obLen(keys);

                if (!KL) return this.sort(keySort);

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === "desc" || keys[k] === -1
                      ? -1
                      : keys[k] === "skip" || keys[k] === 0
                      ? 0
                      : 1;
                }

                this.sort(function(a, b) {
                  var sorted = 0,
                    ix = 0;

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix);
                    if (k) {
                      var dir = keys[k];
                      sorted = keySort(a[k], b[k], dir);
                      ix++;
                    }
                  }
                  return sorted;
                });
                return this;
              };
              const sortorder = { Naam: "asc" };
              stand.keySort(sortorder);

              var res = null;
              var res2 = null;
              //console.log(stand);

              // de team resultaten verzamelen
              if (
                keys.toLowerCase() === "dinsdagmiddag" ||
                keys.toLowerCase() === "standbsk2019"
              ) {
                res = alasql(
                  "Select Team as Naam, Count(Team) / 4 as Gespeeld  , Sum (Punten) as Punten , (  Sum(Punten) / (Count(Team) * 13)  * 100 ) as PercMP  from ? GROUP BY Team",
                  [stand]
                );
              } else {
                // Deze query is de stand
                //console.log("Gebruiken we deze?", stand);
                res = alasql(
                  //  "Select Naam , Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy , Sum (Punten) / (Count(Naam)) as MPPerc from ? GROUP BY Naam",
                  "Select Naam , Count(Naam) as Gespeeld , Sum(AantalSetsSpelerVoor) as AantalSetsSpelerVoor,  Sum(AantalSetsSpelerTegen) as AantalSetsSpelerTegen, Sum(Set1PuntenSP1 + Set2PuntenSP1 + Set3PuntenSP1) as aantalpuntensp1 , Sum(Set1PuntenSP2 + Set2PuntenSP2 + Set3PuntenSP2) as aantalpuntensp2 , (Sum(Set1PuntenSP1 + Set2PuntenSP1 + Set3PuntenSP1) / Sum(Set1PuntenSP2 + Set2PuntenSP2 + Set3PuntenSP2)) as vijfPinsAVG ,Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy, Max(Gem) as Gem , Sum (Punten) / (Count(Naam)) as MPPerc , Sum(Caramboles) / (Count(Naam)  * Max(Temakencaramboles) ) * 100  as CarPerc from ? GROUP BY Naam",

                  [stand]
                );
                console.log("Query stand ", res);
                console.log("Stand", stand);
              }

              //!------ keysort toekennen aan de array
              res.keySort = function(keys) {
                keys = keys || {};

                var obLen = function(obj) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++;
                  }
                  return size;
                };

                var obIx = function(obj, ix) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key;
                      size++;
                    }
                  }
                  return false;
                };

                var keySort = function(a, b, d) {
                  d = d !== null ? d : 1;
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0;
                  return a > b ? 1 * d : -1 * d;
                };

                var KL = obLen(keys);

                if (!KL) return this.sort(keySort);

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === "desc" || keys[k] === -1
                      ? -1
                      : keys[k] === "skip" || keys[k] === 0
                      ? 0
                      : 1;
                }

                this.sort(function(a, b) {
                  var sorted = 0,
                    ix = 0;

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix);
                    if (k) {
                      var dir = keys[k];
                      sorted = keySort(a[k], b[k], dir);
                      ix++;
                    }
                  }
                  return sorted;
                });
                return this;
              };

              const sortorderDef = {
                // PercMP: "desc"  // is dinsdagmiddag ivm ongelijke partijen.

                Punten: "desc",
                Gespeeld: "asc",
                AantalSetsSpelerVoor: "desc",
                AantalSetsSpelerTegen: "asc",
                // vijfPinsMoySP1: "asc"
                vijfPinsAVG: "desc"
              };

              res.keySort(sortorderDef);

              this.setState({ data: res });

              //Wedstrijd resultaten:
              if (keys.toLowerCase() === "deze niet") {
                // res = alasql(
                //   "Select Team as Naam, Count(Team) as Gespeeld , Sum (Punten) as Punten , (  Sum(Punten) / (Count(Team) * 10)  * 100 ) as PercMP  from ? GROUP BY Team",
                //   [stand]
                // );

                res2 = alasql(
                  "Select Poule as Poule, Count(Poule) as Gespeeld  , Naam as Naam, Achternaam as Achternaam , Sum (Punten) as Punten , (  Sum(Punten) / (Count(Team) * 13)  * 100 ) as PercMP  from ? GROUP BY Poule",
                  [stand]
                );
              } else {
                //Deze gebruiken we
                console.log("Deze stand?");
                res2 = alasql(
                  //  "Select Naam , Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy , Sum (Punten) / (Count(Naam)) as MPPerc from ? GROUP BY Naam",
                  "Select Poule , Naam , Achternaam ,  Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy, Max(Gem) as Gem , Sum (Punten) / (Count(Naam)) as MPPerc , Sum(Caramboles) / (Count(Naam)  * Max(Temakencaramboles) ) * 100  as CarPerc from ? GROUP BY Poule, Naam",

                  [stand]
                );
              }
              console.log("res2", res2);
              console.log("Opgehaalde gegevens ", stand);
              //!------ keysort toekennen aan de array
              res2.keySort = function(keys) {
                keys = keys || {};

                var obLen = function(obj) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++;
                  }
                  return size;
                };

                var obIx = function(obj, ix) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key;
                      size++;
                    }
                  }
                  return false;
                };

                var keySort = function(a, b, d) {
                  d = d !== null ? d : 1;
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0;
                  return a > b ? 1 * d : -1 * d;
                };

                var KL = obLen(keys);

                if (!KL) return this.sort(keySort);

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === "desc" || keys[k] === -1
                      ? -1
                      : keys[k] === "skip" || keys[k] === 0
                      ? 0
                      : 1;
                }

                this.sort(function(a, b) {
                  var sorted = 0,
                    ix = 0;

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix);
                    if (k) {
                      var dir = keys[k];
                      sorted = keySort(a[k], b[k], dir);
                      ix++;
                    }
                  }
                  return sorted;
                });
                return this;
              };
              wedstrijddetails.keySort = function(keys) {
                keys = keys || {};

                var obLen = function(obj) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++;
                  }
                  return size;
                };

                var obIx = function(obj, ix) {
                  var size = 0,
                    key;
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key;
                      size++;
                    }
                  }
                  return false;
                };

                var keySort = function(a, b, d) {
                  d = d !== null ? d : 1;
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0;
                  return a > b ? 1 * d : -1 * d;
                };

                var KL = obLen(keys);

                if (!KL) return this.sort(keySort);

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === "desc" || keys[k] === -1
                      ? -1
                      : keys[k] === "skip" || keys[k] === 0
                      ? 0
                      : 1;
                }

                this.sort(function(a, b) {
                  var sorted = 0,
                    ix = 0;

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix);
                    if (k) {
                      var dir = keys[k];
                      sorted = keySort(a[k], b[k], dir);
                      ix++;
                    }
                  }
                  return sorted;
                });
                return this;
              };
              // const soDefWed = {
              //   Punten: "desc"
              // };
              // res2.keySort(soDefwed);
              wedstrijddetails.keySort({
                Datum: "desc",
                Poule: "asc",
                Tijd: "asc"
              });
              this.setState({ wed: wedstrijddetails });
              // console.log("Wedstrijden:", wedstrijddetails);
            });
        });
    };

    HaalDataOp(this.state.poule);
  };

  render() {
    let HS = 0;
    let HSNaam = "";
    let Driebanden = this.state.dagdeel.indexOf("3b") > 0;
    let Naam =
      this.state.dagdeel === "dinsdagmiddag" ||
      this.state.dagdeel === "StandBSK2019"
        ? "Team "
        : "";
    //console.log(this.state.dagdeel);
    const TeamNamen = [
      "null",
      "ADO 1",
      "BCO",
      "Bob & Gerard",
      "OBIS 3",
      "ADO 1",
      "Kameraden",
      "BSK",
      "Rene & Gerrit",
      "T&T",
      "ADO 2",
      "ADO 3",
      "MariJel",
      "Vriezers",
      "Jari & Corry",
      "CePi"
    ];
    // console.log(this.state.dagdeel);

    const OverzichtGespeeldeWedstrijden = type => {
      let regel = [];
      //regel.push("<tbody>");
      let dag = new Date("1-1-1900");
      let zelfdepoule = 1;
      //console.log(this.state.dagdeel);
      //console.log(JSON.stringify(this.state.wed));
      this.state.dagdeel === "dinsdagmiddag" ||
      this.state.dagdeel === "StandBSK2019" ||
      this.state.dagdeel === "NK5Pins" ||
      this.state.dagdeel === "Biatlon"
        ? this.state.wed.map(function(d, idx) {
            regel.push(
              // Dit is de te wijzigen opmaak
              <tr key={idx}>
                <td
                  style={{
                    padding: 3,
                    //fontWeight: 500,
                    width: 100,
                    height: 20,
                    fontSize: 14,
                    color:
                      d.Winnaar === d.Voornaam1
                        ? "blue"
                        : d.Winnaar === "Remise"
                        ? "green"
                        : "black",
                    fontWeight:
                      d.Winnaar === d.Voornaam1
                        ? 700
                        : d.Winnaar === "Remise"
                        ? 500
                        : 300
                    // borderRadius: "8px",
                    // backgroundColor:
                    //   d.Winnaar === d.Voornaam1
                    //     ? "gold"
                    //     : d.Winnaar !== "Remise"
                    //     ? "#f0f0f5"
                    //     : "white"
                  }}
                >
                  {d.Voornaam1} {d.Achternaam1}
                </td>

                <td
                  style={{
                    width: "5%",
                    fontWeight: "bold",
                    textAlign: "right"
                  }}
                >
                  {d.Speler1Punten}
                </td>
                <td style={{ textAlign: "right", color: "green" }}>
                  {d.AantalsetsSpeler1 ? d.AantalsetsSpeler1 : 0}
                </td>
                <td style={{ textAlign: "right", color: "red" }}>
                  {d.AantalsetsSpeler2 ? d.AantalsetsSpeler2 : 0}
                </td>
                <td style={{ textAlign: "right", color: "gray" }}>
                  {d.vijfPinsMoySP1
                    ? parseFloat(
                        (d.Set1PuntenSP1 + d.Set2PuntenSP1 + d.Set3PuntenSP1) /
                          (d.Set1PuntenSP2 + d.Set2PuntenSP2 + d.Set3PuntenSP2)
                      ).toFixed(3)
                    : "FOUT"}
                </td>
                <td
                  style={{
                    float: "none",
                    position: "relative",
                    paddingLeft: 22
                  }}
                >
                  <Avatar
                    style={{
                      marginLeft: 10,
                      width: 30,
                      height: 30,
                      color: "white",
                      backgroundColor:
                        d.Set1PuntenSP1 > d.Set1PuntenSP2 ? "green" : "#a83a32",
                      marginBottom: 3
                    }}
                  >
                    {d.Set1PuntenSP1}
                  </Avatar>
                </td>
                <td
                  style={{
                    float: "none",
                    position: "relative",
                    paddingLeft: 22
                  }}
                >
                  {(d.Set2PuntenSP1 > 0 ||
                    d.Set2PuntenSP2 ||
                    d.AantalBeurten3BandenBiatlon) && (
                    <Avatar
                      style={{
                        marginLeft: 10,
                        width: 30,
                        height: 30,
                        color: "white",
                        backgroundColor:
                          d.Set2PuntenSP1 > d.Set2PuntenSP2
                            ? "green"
                            : d.AantalBeurten3BandenBiatlon
                            ? "steelblue"
                            : "#a83a32"
                      }}
                    >
                      {d.AantalBeurten3BandenBiatlon
                        ? d.Biatlon3bSerieSP1.split(",").length
                        : d.Set2PuntenSP1}
                    </Avatar>
                  )}
                </td>
                <td
                  style={{
                    float: "none",
                    position: "relative",
                    paddingLeft: 22
                  }}
                >
                  {(d.Set3PuntenSP1 > 0 || d.AantalBeurten3BandenBiatlon) &&
                    (d.AantalBeurten3BandenBiatlon ? (
                      d.Biatlon3BgemSp1.toFixed(3)
                    ) : (
                      <Avatar
                        style={{
                          marginLeft: 10,
                          width: 30,
                          height: 30,
                          color: "white",
                          backgroundColor:
                            d.Set3PuntenSP1 > d.Set3PuntenSP2
                              ? "green"
                              : "#a83a32"
                        }}
                      >
                        {d.Set3PuntenSP1}
                      </Avatar>
                    ))}
                </td>
              </tr>
            );

            regel.push(
              <tr>
                <td
                  style={{
                    padding: 3,
                    // fontWeight: 500,
                    width: "20%",
                    fontSize: 14,
                    color:
                      d.Winnaar === d.Voornaam2
                        ? "blue"
                        : d.Winnaar === "Remise"
                        ? "green"
                        : "black",
                    fontWeight:
                      d.Winnaar === d.Voornaam2
                        ? 700
                        : d.Winnaar === "Remise"
                        ? 500
                        : 300
                    // borderRadius: "8px",
                    // backgroundColor:
                    //   d.Winnaar === d.Voornaam2
                    //     ? "gold"
                    //     : d.Winnaar !== "Remise"
                    //     ? "#f0f0f5"
                    //     : "white"
                  }}
                >
                  {d.Voornaam2} {d.Achternaam2}
                </td>

                <td
                  style={{
                    width: "5%",
                    fontWeight: "bold",
                    textAlign: "right"
                  }}
                >
                  {d.Speler2Punten}
                </td>
                <td style={{ textAlign: "right", color: "green" }}>
                  {d.AantalsetsSpeler2 ? d.AantalsetsSpeler2 : 0}
                </td>
                <td style={{ textAlign: "right", color: "red" }}>
                  {d.AantalsetsSpeler1 ? d.AantalsetsSpeler1 : 0}
                </td>
                <td style={{ textAlign: "right", color: "gray", width: "7%" }}>
                  {d.vijfPinsMoySP1
                    ? parseFloat(
                        (d.Set1PuntenSP2 + d.Set2PuntenSP2 + d.Set3PuntenSP2) /
                          (d.Set1PuntenSP1 + d.Set2PuntenSP1 + d.Set3PuntenSP1)
                      ).toFixed(3)
                    : "FOUT"}
                </td>
                <td
                  style={{
                    float: "none",
                    position: "relative",
                    paddingLeft: 22
                  }}
                >
                  <Avatar
                    style={{
                      marginLeft: 10,
                      width: 30,
                      height: 30,
                      color: "white",
                      backgroundColor:
                        d.Set1PuntenSP2 > d.Set1PuntenSP1 ? "green" : "#a83a32"
                    }}
                  >
                    {d.Set1PuntenSP2}
                  </Avatar>
                </td>
                <td
                  style={{
                    float: "none",
                    position: "relative",
                    paddingLeft: 22
                  }}
                >
                  {(d.Set2PuntenSP2 > 0 ||
                    d.Set2PuntenSP1 > 0 ||
                    d.AantalBeurten3BandenBiatlon) && (
                    <Avatar
                      style={{
                        marginLeft: 10,
                        width: 30,
                        height: 30,
                        color: "white",
                        backgroundColor:
                          d.Set2PuntenSP2 > d.Set2PuntenSP1
                            ? "green"
                            : d.AantalBeurten3BandenBiatlon
                            ? "steelblue"
                            : "#a83a32"
                      }}
                    >
                      {d.AantalBeurten3BandenBiatlon
                        ? d.Biatlon3bSerieSP2.split(",").length
                        : d.Set2PuntenSP2}
                    </Avatar>
                  )}
                </td>
                <td
                  style={{
                    float: "none",
                    position: "relative",
                    paddingLeft: 22
                  }}
                >
                  {(d.Set3PuntenSP2 > 0 || d.AantalBeurten3BandenBiatlon > 0) &&
                    (d.AantalBeurten3BandenBiatlon ? (
                      d.Biatlon3BgemSp2.toFixed(3)
                    ) : (
                      <Avatar
                        style={{
                          marginLeft: 10,
                          width: 30,
                          height: 30,
                          color: "white",
                          backgroundColor:
                            d.Set3PuntenSP2 > d.Set3PuntenSP1
                              ? "green"
                              : "#a83a32"
                        }}
                      >
                        {d.Set3PuntenSP2}
                      </Avatar>
                    ))}
                </td>
              </tr>
            );
            regel.push(
              <tr style={{ height: 3 }}>
                <td colspan="7" style={{ height: 3 }}>
                  <hr style={{ height: 3 }}></hr>
                </td>
                <td
                  colspan="2"
                  style={{
                    height: 3,
                    color: "lightgray",
                    verticalAlign: "center",
                    fontSize: "smaller"
                  }}
                >
                  <Icon>watch_laterOutlined</Icon>

                  {parseInt(Moment(d.Eindtijd - d.Starttijd).format("h")) > 1
                    ? "> 1 uur"
                    : Moment(d.Eindtijd - d.Starttijd).format("m [min]")}
                </td>
              </tr>
            );
          })
        : this.state.wed.map(function(d, idx) {
            if (dag !== d.Datum) {
              regel.push(
                <tr key={idx}>
                  <td
                    colspan="3"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    Clubavond
                  </td>
                  <td colspan="1">
                    <hr />
                  </td>
                  <td
                    colspan="3"
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      textAlign: "right"
                    }}
                  >
                    {new Date(d.Datum).toLocaleDateString("nl-NL", {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })}
                  </td>
                </tr>
              );

              dag = d.Datum;
              zelfdepoule = d.Poule;
            } else {
              if (zelfdepoule !== d.Poule) {
                regel.push(
                  <tr key={idx}>
                    <td
                      colspan="3"
                      style={{ color: "green", fontWeight: "bold" }}
                    >
                      {d.thuisTeamNamen[d.Thuis]} tegen {TeamNamen[d.Uit]}
                    </td>
                    <td colspan="2">
                      <hr />
                    </td>
                    <td
                      colspan="2"
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        textAlign: "right"
                      }}
                    >
                      {new Date(d.Datum).toLocaleDateString("nl-NL", {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })}
                    </td>
                  </tr>
                );
                dag = d.Datum;
                zelfdepoule = d.Poule;
              }
            }
          });

      return regel;
    };

    const MaakStandOp = type => {
      let Pouletart = 0;
      let TeamEnd = 0;
      if (type === "A") {
        Pouletart = 0;
        TeamEnd = 7;
      }
      if (type === "B") {
        Pouletart = 7;
        TeamEnd = 15;
      }

      //console.log(type);
      const fontgrootte = this.state.kleur ? 24 : 18;
      //console.log("State kleur", this.state.kleur);
      let style1 = [
        {
          backgroundColor: this.state.kleur ? "gold" : "white",
          padding: 5,
          fontSize: this.state.kleur ? fontgrootte + 12 : fontgrootte,
          fontWeight: 700
        },
        {
          backgroundColor: this.state.kleur ? "silver" : "white",
          padding: 5,
          fontSize: this.state.kleur ? fontgrootte + 8 : fontgrootte,
          fontWeight: 700
        },
        {
          backgroundColor: this.state.kleur ? "#8C7853" : "white",
          color: this.state.kleur ? "white" : "black",
          fontSize: this.state.kleur ? fontgrootte + 4 : fontgrootte,
          padding: 5,
          fontWeight: 700
        }
      ];
      var i;
      for (i = 0; i < this.state.data.length; i++) {
        style1.push({
          backgroundColor: this.state.kleur ? "lightgray" : "white",
          color: "#000",
          opacity: this.state.kleur ? 0.5 : 1.0,
          padding: 5,
          fontSize: fontgrootte,
          fontWeight: 700
        });
      }
      // console.log(JSON.parse(style1[0]));
      let regel = [];
      let pouleheadergezet = false;
      this.state.data.map(function(d, idx) {
        // console.log("Totale stand", d);
        if (d.HoogsteSerie > HS) {
          //console.log(d.HoogsteSerie, d.Naam);
          HSNaam = d.Naam;
          HS = d.HoogsteSerie;
        }

        regel.push(
          <tr key={idx}>
            {/* <td align="right">{idx + 1}</td> */}

            <td style={style1[idx]}>
              {" "}
              {/* werkt niet */}
              {/* <button
                onClick={() => {
                  this.setState({ filteredName: d.Naam });
                }}
              >
                Filter
              </button> */}
              {Naam}
              {d.Naam ? d.Naam.replace("van der", "vd") : "Geen data!!"}
            </td>
            <td align="right" style={style1[idx]}>
              {d.Gespeeld ? d.Gespeeld : ""}
            </td>
            <td
              align="right"
              style={{
                ...style1[idx],
                color: "blue",
                fontWeight: "bold"
              }}
            >
              {/* <Avatar
                style={{
                  marginLeft: 0,
                  width: 35,
                  height: 35,
                  color: "white",
                  backgroundColor: "blue"
                }}
              > */}
              {d.Punten ? d.Punten : "0"}
              {/* </Avatar> */}
            </td>
            <td align="right" style={{ ...style1[idx], color: "green" }}>
              {d.AantalSetsSpelerVoor ? d.AantalSetsSpelerVoor : 0}
            </td>
            <td align="right" style={{ ...style1[idx], color: "red" }}>
              {d.AantalSetsSpelerTegen ? d.AantalSetsSpelerTegen : 0}
            </td>
            <td align="right" style={{ ...style1[idx], fontSize: 14 }}>
              {d.aantalpuntensp1
                ? parseFloat(d.aantalpuntensp1 / d.aantalpuntensp2)
                    .toFixed(3)
                    .toString()
                    .replace(".", ",")
                : 999}
            </td>
          </tr>
        );
      });
      //console.log(regel);
      return regel;
    };

    const GenereerKop = () => {
      const kopregel = [];
      this.state.dagdeel === "dinsdagmiddag" ||
      this.state.dagdeel === "StandBSK2019"
        ? kopregel.push(
            <thead key="1">
              <tr>
                {/* <th width="10%">Plaats</th> */}
                <th width="40%" style={{ padding: 3 }}>
                  Naam
                </th>

                <th width="20%">Gesp</th>
                <th width="20%">Pnt</th>
                <th width="20%">MP%</th>
              </tr>
            </thead>
          )
        : kopregel.push(
            <thead key="12">
              <tr>
                {/* <th width="10%">Plaats</th> */}
                <th width="20%" style={{ padding: 3 }}>
                  Naam
                </th>
                <th width="8%" style={{ textAlign: "right" }}>
                  Gesp
                </th>
                <th width="8%" style={{ textAlign: "right" }}>
                  MP
                </th>
                <th width="8%" style={{ textAlign: "right" }}>
                  Set +
                </th>
                <th width="8%" style={{ textAlign: "right" }}>
                  Set -
                </th>
                <th width="8%" style={{ textAlign: "right" }}>
                  Moy
                </th>
              </tr>
            </thead>
          );
      return kopregel;
    };

    const Voetregel = () => {
      const footer = [];
      this.state.dagdeel !== "dinsdagmiddag" ||
      this.state.dagdeel === "StandBSK2019"
        ? parseInt(HS, 10) === 0
          ? footer.push("")
          : footer.push(`Hoogste serie ${HS} door
        ${HSNaam}`)
        : footer.push("");
      return footer;
    };

    return (
      <div style={{ margin: 5 }}>
        <h3>Stand {this.state.dagdeel}</h3>
        <div style={{ maxWidth: 600, minWidth: 400 }}>
          {firebase.auth().currentUser.email !== "pbestaatniet@de-boer.net" && (
            <div>
              <div>Huidige poule: {this.state.poule}</div>

              {/* <button
                className={this.state.kleur ? "btn-unselected" : "btn-oranje"}
                onClick={() => {
                  this.setState({ kleur: !this.state.kleur });
                }}
              >
                {this.state.kleur ? "Zwart-wit versie" : "Kleur versie"}
              </button> */}
              <button
                className={
                  this.state.poule === "A" ? "btn-blue" : "btn-unselected"
                }
                onClick={() => {
                  this.setState({ poule: "A" });
                }}
              >
                A
              </button>
              <button
                className={
                  this.state.poule === "B" ? "btn-blue" : "btn-unselected"
                }
                onClick={() => {
                  this.setState({ poule: "B" });
                }}
              >
                B
              </button>
              <button
                className={
                  this.state.poule === "C" ? "btn-blue" : "btn-unselected"
                }
                onClick={() => {
                  this.setState({ poule: "C" });
                }}
              >
                C
              </button>
              <button
                className={
                  this.state.poule === "NKA" ? "btn-blue" : "btn-unselected"
                }
                onClick={() => {
                  this.setState({ poule: "NKA" });
                }}
              >
                NKA
              </button>
            </div>
          )}
          <table style={{ width: "100%" }}>
            {GenereerKop()}
            {this.state.dagdeel !== "StandBSK2019" && MaakStandOp()}
            <tr key="123">
              <td colSpan="7" style={{ padding: 3 }}>
                {Voetregel()}
              </td>
            </tr>
            {this.state.wedstrijden && (
              <tr key="1234">
                <td colspan="11">
                  <h4 style={{ color: "blue" }}>
                    Overzicht wedstrijden (laatste als eerste)
                  </h4>
                  <p>
                    <span style={{ color: "blue" }}>Blauw = Winnaar</span>,
                    <span style={{ color: "green" }}> Groen = Remise</span>
                  </p>
                  <table width="100%">
                    {/* <tbody> */}
                    <thead>
                      <tr key="12345" style={{}}>
                        <th style={{ width: 100, paddingBottom: 5 }}>
                          Spelers
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            width: "8%",
                            paddingBottom: 5
                          }}
                        >
                          Pnt
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            width: "8%",
                            paddingBottom: 5
                          }}
                        >
                          Set +
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            width: "8%",
                            paddingBottom: 5
                          }}
                        >
                          Set -
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            width: "8%",
                            paddingBottom: 5
                          }}
                        >
                          Moy
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "10%",
                            paddingBottom: 5
                          }}
                        >
                          Set 1
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "10%",
                            paddingBottom: 5
                          }}
                        >
                          {this.state.dagdeel === "Biatlon"
                            ? "3B brt"
                            : "Set 2"}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "10%",
                            paddingBottom: 5
                          }}
                        >
                          {this.state.dagdeel === "Biatlon"
                            ? "3B gem"
                            : "Set 3"}
                        </th>
                      </tr>
                    </thead>
                    {OverzichtGespeeldeWedstrijden(this.state.dagdeel)}
                    {/* </tbody> */}
                  </table>
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
    );
  }
}
export default Stand5Pins;
