import React, { Component } from "react";
import { View, Text } from "react-native";
import firebase from "firebase";
import "firebase/firestore";
const firestore = firebase.firestore();
//firestore.settings({ timestampsInSnapshots: true });

class AfgebrokenPartijen extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.match);
    this.state = {
      data: [],
      avond: this.props.avond,
      dagdeel: "",
      validUser: false,
      vereniging: "OnderOns"
    };
  }
  state = { data: [], dagdeel: "" };

  componentDidMount() {
    let validUser = false;
    // Ik heb allen toegang tot de afgebroken partijen.
    if (firebase.auth().currentUser.email === "p@de-boer.net") {
      validUser = true;
    }
    this.setState({ validUser });

    var afgebrokenpartijenwedstrijdenRef = firestore
      .collection(`/${this.state.vereniging}/Wedstrijden/AfgebrokenPartij/`)
      //.collection(`/${this.state.vereniging}/Wedstrijden/NK5Pins/`)
      //.collection(`/${this.state.vereniging}/Backup/BSK2019`)
      //.where("Beurten", "==", 0)
      .orderBy("Datum", "desc");
    // var afgebrokenpartijenwedstrijdenRef = firestore.collection(
    //   `/${this.state.vereniging}/Backup/Maan_av2/`
    // );
    //console.log(afgebrokenpartijenwedstrijdenRef);
    afgebrokenpartijenwedstrijdenRef.get().then(querySnapshot => {
      const partijen = [];
      querySnapshot.forEach(function(doc) {
        // Eerst de uitslag platmaken
        let tmpRec = [];
        tmpRec = {
          id: doc.id,
          datum:
            new Date(doc.data().Datum).toLocaleDateString() +
            " " +
            new Date(doc.data().Datum).toLocaleTimeString(),
          Speler1: doc.data().Speler1,
          Speler2: doc.data().Speler2,
          gegevens: doc.data()
        };

        partijen.push(tmpRec);
      });
      this.setState({ data: partijen });
    });
  }

  render() {
    const ToonWedstrijden = () => {
      let regel = [];
      this.state.data.map(function(d, idx) {
        regel.push(
          <tr key={idx}>
            <td style={{ padding: 3 }}>{d.datum}</td>
            <td style={{ padding: 3 }}> {d.Speler1}</td>
            <td style={{ padding: 3 }}>{d.Speler2}</td>
            <td style={{ padding: 3 }}>
              <Text
                style={{
                  color: "red",
                  borderStyle: "2px",
                  borderRadius: "10px",
                  backgroundColor: "gold"
                }}
                onPress={() => ShowDetails(d.gegevens)}
              >
                Details {d.datum.slice(0, 10)}
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text style={{ color: "red" }} onPress={() => Verwijder(d.id)}>
                Verwijder
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "red" }}
                onPress={() => Verplaats(d, "tmpMaan_av")}
              >
                -> tmpMaan_av
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "red" }}
                onPress={() => Verplaats(d, "Toernooi")}
              >
                -> Toernooi
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "green" }}
                onPress={() => Verplaats(d, "Dond_5P")}
              >
                -> Dond_5P
              </Text>
            </td>

            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "green" }}
                onPress={() => Verplaats(d, "NK5Pins")}
              >
                -> Nk5Pins
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "orange" }}
                onPress={() => Verplaats(d, "Maan_av")}
              >
                -> Maan_av
              </Text>
            </td>

            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "green" }}
                onPress={() => Verplaats(d, "Dins_av")}
              >
                -> Dins_av
              </Text>
            </td>

            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "green" }}
                onPress={() => Verplaats(d, "Woens_av")}
              >
                -> Woens_av
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "purple" }}
                onPress={() => Verplaats(d, "Dins_av_3B_groot")}
              >
                -> Dins 3B groot
              </Text>
            </td>
            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "purple" }}
                onPress={() => Verplaats(d, "Dins_av_3B_klein3")}
              >
                -> Dins 3B klein
              </Text>
            </td>

            <td style={{ padding: 3 }}>
              <Text
                style={{ color: "purple" }}
                onPress={() => Verplaats(d, "Woens_av_3B_groot")}
              >
                -> Woens 3B groot 5
              </Text>
            </td>
          </tr>
        );
      });

      return regel;
    };

    const GenereerKop = () => {
      if (this.state.data.length === 0) {
        return "Geen wedstrijden beschikbaar";
      }
    };

    const Voetregel = () => {
      const footer = [];

      return footer;
    };

    const Verwijder = id => {
      firebase
        .firestore()
        .collection(`/${this.state.vereniging}/Wedstrijden/AfgebrokenPartij/`)
        .doc(id)
        .delete();
      //.then(alert("Verwijderd"));
    };

    const Verplaats = (doc, dagdeel) => {
      // const vervangdocid = doc.id
      //   .replace("5-11", "6-11")
      //   .replace("Wil Schuit", "Wim Boone");
      //console.log(doc);
      firebase
        .firestore()
        .collection(`/${this.state.vereniging}/Wedstrijden/${dagdeel}/`)
        .doc(doc.id)
        //  .doc(vervangdocid)
        .set(doc.gegevens)
        // .then(Verwijder(doc.id))
        .then(alert("Document verplaatst"));
    };

    const ShowDetails = (doc, dagdeel) => {
      // const vervangdocid = doc.id
      //   .replace("5-11", "6-11")
      //   .replace("Wil Schuit", "Wim Boone");
      // console.log(doc);
      let data = JSON.stringify(doc)
        .replace(/[\[\]']+/g, "")
        .replace(/[\{\}']+/g, "");
      alert(JSON.stringify(data));
    };
    return (
      this.state.validUser && (
        <View>
          <h3>Afgebroken partijen:</h3>
          <div style={{ minWidth: 400 }}>
            <table
              border="1"
              cellpadding="0"
              cellspacing="0"
              style={{ padding: 3 }}
            >
              {GenereerKop()}
              <tbody>
                {ToonWedstrijden()}
                <tr>
                  <td colSpan="7" style={{ padding: 3, margin: 10 }}>
                    {Voetregel()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </View>
      )
    );
  }
}
export default AfgebrokenPartijen;
