import React, { Component } from 'react'
import firebase from 'firebase'
import 'firebase/firestore'
import * as alasql from 'alasql'
import './Stand.css'
import 'firebase/auth'

const firestore = firebase.firestore()
//firestore.settings({ timestampsInSnapshots: true });

class Stand extends Component {
  constructor (props) {
    super(props)
    // console.log("This.props.match", this.props.match);
    this.state = {
      data: [],
      avond: this.props.avond,
      persoonlijk: this.props.details === 'persoonlijk',
      team: this.props.details === 'team',
      wedstrijden: this.props.details === 'wedstrijden',
      dagdeel: '',
      typestand: '',
      wed: [],
      CompetitieRonde: 1
    }
  }
  state = { data: [], dagdeel: '' }

  componentDidMount () {
    let done = false
    if (
      window.location.href
        .split('//')[1]
        .split('/')[1]
        .indexOf('maandagavond') > -1
    ) {
      this.setState({ typestand: 'Maandag' })
      done = true
    }
    if (
      !done &&
      window.location.href
        .split('//')[1]
        .split('/')[1]
        .indexOf('woensdagavond') > -1
    ) {
      this.setState({ typestand: 'Woensdag' })
      done = true
    }
    if (
      !done &&
      window.location.href
        .split('//')[1]
        .split('/')[1]
        .indexOf('donderdagavond') > -1
    ) {
      this.setState({ typestand: 'MP' })
      done = true
    }
    if (!done) {
      this.setState({ typestand: 'BSK' })
      done = true
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      if (
        prevState.typestand !== this.state.typestand ||
        prevState.CompetitieRonde !== this.state.CompetitieRonde
      ) {
        this.getData()
      }
    }
  }

  getData = async () => {
    // var gespeeldewedstrijdenRef = firestore.collection(
    //   "/OnderOns/Wedstrijden/Dins_mid/"
    // );
    // var gespeeldewedstrijden = await gespeeldewedstrijdenRef.get();
    // this.setState(gespeeldewedstrijden.docs);
    //console.log(gespeeldewedstrijden);
    let dagdeel = ''
    //var urlParams = "";
    // urlParams = new URLSearchParams(window.location.search);

    var keys = this.props.avond

    if (keys === null) {
      keys = 'niets'
    }

    //console.log("keys: ", keys);
    switch (keys.toLowerCase()) {
      case 'standbsk2019':
        dagdeel = 'BSK2019'
        break
      case 'maandagavond':
        dagdeel = 'Maan_av'
        break
      case 'maandagavond2':
        dagdeel = 'Maan_av2'
        break
      case 'maandagavond3':
        dagdeel = 'Maan_av3'
        break
      case 'maandagavond4':
        dagdeel = 'Maan_av4'
        break
      case 'dinsdagmiddag':
        dagdeel = 'Dins_mid'
        break

      case 'dinsdagavond':
        dagdeel = 'Dins_av'
        break
      case 'dinsdagavond2':
        dagdeel = 'Dins_av2'
        break
      case 'dinsdagavond3':
        dagdeel = 'Dins_av3'
        break
      case 'dinsdagavond4':
        dagdeel = 'Dins_av4'
        break
      case 'dinsdagavond3bklein':
        dagdeel = 'Dins_av_3B_klein'
        break

      case 'dinsdagavond3bgroot':
        dagdeel = 'Dins_av_3B_groot'
        break

      case 'dinsdagavond3bklein2':
        dagdeel = 'Dins_av_3B_klein2'
        break

      case 'dinsdagavond3bgroot2':
        dagdeel = 'Dins_av_3B_groot2'
        break

      case 'dinsdagavond3bklein3':
        dagdeel = 'Dins_av_3B_klein3'
        break

      case 'dinsdagavond3bgroot3':
        dagdeel = 'Dins_av_3B_groot3'
        break

      case 'dinsdagavond3bklein4':
        dagdeel = 'Dins_av_3B_klein4'
        break

      case 'dinsdagavond3bgroot4':
        dagdeel = 'Dins_av_3B_groot4'
        break

      case 'woensdagavond':
        dagdeel = 'Woens_av'
        break

      case 'woensdagavond2':
        dagdeel = 'Woens_av2'
        break

      case 'woensdagavond3':
        dagdeel = 'Woens_av3'
        // console.log(dagdeel);
        break

      case 'woensdagavond4':
        dagdeel = 'Woens_av4'
        // console.log(dagdeel);
        break

      case 'woensdagavond3bgroot':
        dagdeel = 'Woens_av_3B_groot'
        break

      case 'woensdagavond3bgroot2':
        dagdeel = 'Woens_av_3B_groot2'
        break

      case 'woensdagavond3bgroot3':
        dagdeel = 'Woens_av_3B_groot3'
        break

      case 'woensdagavond3bgroot4':
        dagdeel = 'Woens_av_3B_groot4'
        break

      case 'woensdagavond3bgroot5':
        dagdeel = 'Woens_av_3B_groot5'
        break

      case 'standdonderdag5pins':
        dagdeel = 'Dond_5P'
        break

      case 'standdonderdag5pins2':
        dagdeel = 'Dond_5P_2'
        break

      default:
        dagdeel = 'niets'
        break
    }
    const eenweekgeleden = new Date(
      new Date() - 60 * 60 * 24 * 7 * 1000
    ).getTime()
    this.setState({ dagdeel: keys })
    // console.log(dagdeel);
    var gespeeldewedstrijdenRef = firestore.collection(
      `/OnderOns/Wedstrijden/${dagdeel}/`
    )
    var spelersdetails = firestore.collection(
      `/OnderOns/AlleSpelerslijsten/${dagdeel}/`
    )

    const UpdatePunten = rec => {
      //console.log(rec);

      // if (this.state.typestand === "Woensdag") {
      //   //rec.Punten = 0;
      //   rec.Punten = rec.OrgPunten;
      //   return rec;
      // }
      if (this.state.typestand === 'BSK') {
        //console.log(rec);
        let done = false
        if (rec.Winnaar === 'Remise') {
          rec.Punten += 1
        }
        //Extra punt voor minder dan 26 beurten
        if (rec.Beurten < 25 && rec.Winnaar === rec.Naam) {
          rec.Punten += 1
        }
        done = true

        if (!done) {
          if (rec.Naam.startsWith(rec.Winnaar)) {
            rec.Punten += 2
            //Extra punt voor minder dan 26 beurten
            if (rec.Beurten < 25) {
              rec.Punten += 1
            }
          }
        }
        done = true
        //("Returned", rec.Naam, rec.Punten);
        return rec
      }
      if (this.state.typestand === 'Maandag' || 'Woensdag') {
        //console.log(rec);
        let done = false
        let dubbelevoornaam = false
        const extrapunt =
          parseFloat(rec.Gemiddelde.replace(',', '.')) >
          parseFloat(rec.Gem.replace(',', '.'))
            ? 1
            : 0
        if (rec.Winnaar === 'Remise') {
          rec.Punten = 1 + extrapunt
          done = true
        }

        if (
          !done &&
          rec.Naam.startsWith(rec.Winnaar) &&
          rec.Tegenstander.startsWith(rec.Winnaar)
        ) {
          dubbelevoornaam = true
          // console.log("2 dezelfde namen");
          // console.log(rec.Naam, rec.Punten);
          if (rec.Temakencaramboles === rec.Caramboles) {
            rec.Punten = 2 + extrapunt
            done = true
            //console.log(rec);
            //console.log(rec.Naam, rec.Punten);
          } else {
            // Hier moet nog iets gebeuren met beurtenlimiet
            rec.Punten = 0 + extrapunt
            done = true
          }
          //console.log(rec.Naam, rec.Punten);
        }

        if (!done && dubbelevoornaam) {
        }

        if (!done && rec.Naam.startsWith(rec.Winnaar)) {
          rec.Punten = 2 + extrapunt
          done = true
        }
        if (!done) {
          rec.Punten = 0 + extrapunt
        }
        // if (rec.Naam.startsWith("Cor")) {
        //   console.log(rec);
        // }
        return rec
      }
      if (this.state.typestand === 'MP') {
        //rec.Punten = rec.OrgPunten;
        //In de opmaak van de stand wordt automatisch gesorteerd op MP% als this.state.typestand === "MP"
        return rec
      }
    }

    const GetMoyenne = spArr => {
      var moy = []
      moy = spelers.filter(function (el) {
        return el.Naam === spArr
      })
      // console.log("Moy", moy);
      let retwaarde = ''
      try {
        retwaarde = moy[0].Moyenne.toString().replace('.', ',')
      } catch (error) {
        retwaarde = ''
      }
      return retwaarde
    }
    const spelers = []

    const HaalDataOp = () => {
      spelersdetails
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(function (doc) {
            // Eerst de uitslag platmaken
            let tmpRec = []

            tmpRec = {
              Naam: doc.data().Naam + ' ' + doc.data().Achternaam,
              Moyenne: doc.data().Moyenne,
              id: doc.id
            }
            spelers.push(tmpRec)
          })
        })
        // .then(() => {
        //   // var moy = [];
        //   // moy = spelers.filter(function(el) {
        //   //   return el.Naam === "Andre Kater";
        //   // });
        //   // let a = GetMoyenne(moy);
        //   // console.log(a);
        // })
        .then(() => {
          gespeeldewedstrijdenRef
            .where('CompetitieRonde', '==', this.state.CompetitieRonde)
            //.limit(8)
            .get()
            .then(querySnapshot => {
              const stand = []
              const wedstrijddetails = []
              querySnapshot.forEach(function (doc) {
                //console.log(doc.data());
                // Eerst de uitslag platmaken
                let tmpRec = []
                let wedstrijdUitslag = []
                let tmpwed = []
                tmpwed = {
                  id: doc.id,
                  Datum: new Date(doc.data().Datum).toISOString().split('T')[0],
                  Tijd: new Date(doc.data().Datum).toISOString().split('T')[1],

                  // parseInt(
                  //   new Date(doc.data().Datum).getDay().toString() +
                  //     new Date(doc.data().Datum).getMonth().toString() +
                  //     new Date(doc.data().Datum).getFullYear().toString()
                  //),
                  Winnaar: doc.data().Winnaar,
                  Voornaam1: doc.data().Speler1,
                  Voornaam2: doc.data().Speler2,
                  Achternaam1: doc.data().Speler1Achternaam,
                  Achternaam2: doc.data().Speler2Achternaam,
                  Speler1:
                    doc.data().Speler1 +
                    ' ' +
                    doc.data().Speler1Achternaam.substring(0, 1),
                  Speler2:
                    doc.data().Speler2 +
                    ' ' +
                    doc.data().Speler2Achternaam.substring(0, 1),
                  Gem1: (doc.data().GemaaktSP1 / doc.data().Beurten).toFixed(2),
                  Gem2: (doc.data().GemaaktSP2 / doc.data().Beurten).toFixed(2),
                  Beurten: doc.data().Beurten,
                  HS1: doc.data().Speler1HoogsteSerie,
                  HS2: doc.data().Speler2HoogsteSerie,
                  TemakenCarambolesSpeler1: doc.data().TemakenCarambolesSpeler1,

                  TemakenCarambolesSpeler2: doc.data().TemakenCarambolesSpeler2,
                  GemaaktSP1: doc.data().GemaaktSP1,
                  GemaaktSP2: doc.data().GemaaktSP2,
                  Speler1PuntenBelgischsysteem: doc.data()
                    .Speler1PuntenBelgischsysteem,
                  Speler1Punten: doc.data().Speler1Punten,
                  Speler2PuntenBelgischsysteem: doc.data()
                    .Speler2PuntenBelgischsysteem,
                  Speler2Punten: doc.data().Speler2Punten,
                  Thuis: doc.data().Thuis,
                  Uit: doc.data().Uit,
                  Teams: parseInt(
                    Math.min(doc.data().Thuis, doc.data().Uit).toString() +
                      Math.max(doc.data().Thuis, doc.data().Uit).toString(),
                    10
                  )
                }
                wedstrijdUitslag = tmpwed
                tmpRec = {
                  id: doc.id,
                  Datum: doc.data().Datum,
                  Naam: doc.data().Speler1 + ' ' + doc.data().Speler1Achternaam,
                  Caramboles: doc.data().GemaaktSP1,
                  Tegenstander:
                    doc.data().Speler2 + ' ' + doc.data().Speler2Achternaam,
                  Temakencaramboles: doc.data().TemakenCarambolesSpeler1,
                  TegenstanderCaramboles: doc.data().GemaaktSP2,
                  TegenstanderTemakencaramboles: doc.data()
                    .TemakenCarambolesSpeler2,
                  Gespeeld: 1,
                  Beurten: doc.data().Beurten,
                  Gemiddelde: doc.data().GemiddeldeSpeler1,
                  Gem: parseFloat(
                    GetMoyenne(
                      doc.data().Speler1 + ' ' + doc.data().Speler1Achternaam
                    ).replace(',', '.')
                  ).toFixed(keys.indexOf('3b') > 0 ? 3 : 2),
                  OrgPunten: doc.data().Speler1Punten,
                  Punten:
                    keys.toLowerCase() === 'dinsdagmiddag' ||
                    keys.toLowerCase() === 'standbsk2019'
                      ? Math.floor(
                          (doc.data().GemaaktSP1 /
                            doc.data().TemakenCarambolesSpeler1) *
                            10
                        )
                      : doc.data().Speler1PuntenBelgischsysteem,
                  HoogsteSerie: doc.data().Speler1HoogsteSerie || 0,
                  Moyenne:
                    doc.data().GemaaktSP1 ===
                    doc.data().TemakenCarambolesSpeler1
                      ? parseFloat(
                          doc.data().GemiddeldeSpeler1.replace(',', '.')
                        ).toFixed(keys.indexOf('3b') > 0 ? 3 : 2)
                      : 0,
                  Team: doc.data().Thuis || 0,
                  Winnaar: doc.data().Winnaar
                }
                let eindRec = []
                eindRec = UpdatePunten(tmpRec, true)
                // console.log(eindRec);
                stand.push(eindRec)
                tmpRec = {
                  id: doc.id,
                  Naam: doc.data().Speler2 + ' ' + doc.data().Speler2Achternaam,
                  Caramboles: doc.data().GemaaktSP2,
                  Temakencaramboles: doc.data().TemakenCarambolesSpeler2,
                  TegenstanderCaramboles: doc.data().GemaaktSP1,
                  TegenstanderTemakencaramboles: doc.data()
                    .TemakenCarambolesSpeler1,
                  Tegenstander:
                    doc.data().Speler1 + ' ' + doc.data().Speler1Achternaam,
                  Gespeeld: 1,
                  Beurten: doc.data().Beurten,
                  Gem: parseFloat(
                    GetMoyenne(
                      doc.data().Speler2 + ' ' + doc.data().Speler2Achternaam
                    ).replace(',', '.')
                  ).toFixed(keys.indexOf('3b') > 0 ? 3 : 2),
                  Gemiddelde: doc.data().GemiddeldeSpeler2,
                  OrgPunten: doc.data().Speler2Punten,
                  Punten:
                    keys.toLowerCase() === 'dinsdagmiddag' ||
                    keys.toLowerCase() === 'standbsk2019'
                      ? Math.floor(
                          (doc.data().GemaaktSP2 /
                            doc.data().TemakenCarambolesSpeler2) *
                            10
                        )
                      : doc.data().Speler2PuntenBelgischsysteem,
                  HoogsteSerie: doc.data().Speler2HoogsteSerie || 0,
                  Moyenne:
                    doc.data().GemaaktSP2 ===
                    doc.data().TemakenCarambolesSpeler2
                      ? parseFloat(
                          doc.data().GemiddeldeSpeler2.replace(',', '.')
                        ).toFixed(keys.indexOf('3b') > 0 ? 3 : 2)
                      : 0,
                  Team: doc.data().Uit || 0,
                  Winnaar: doc.data().Winnaar
                }
                let eindRec2 = []
                eindRec2 = UpdatePunten(tmpRec, true)

                stand.push(eindRec2)
                // let updatedWedstrijdPunten = [];
                // updatedWedstrijdPunten = UpdatePunten(wedstrijdUitslag, true);

                wedstrijddetails.push(wedstrijdUitslag)
              })
              //console.log("Wedstrijddetails", wedstrijddetails);
              //!------ keysort toekennen aan de array
              stand.keySort = function (keys) {
                keys = keys || {}

                var obLen = function (obj) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++
                  }
                  return size
                }

                var obIx = function (obj, ix) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key
                      size++
                    }
                  }
                  return false
                }

                var keySort = function (a, b, d) {
                  d = d !== null ? d : 1
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0
                  return a > b ? 1 * d : -1 * d
                }

                var KL = obLen(keys)

                if (!KL) return this.sort(keySort)

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === 'desc' || keys[k] === -1
                      ? -1
                      : keys[k] === 'skip' || keys[k] === 0
                      ? 0
                      : 1
                }

                this.sort(function (a, b) {
                  var sorted = 0,
                    ix = 0

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix)
                    if (k) {
                      var dir = keys[k]
                      sorted = keySort(a[k], b[k], dir)
                      ix++
                    }
                  }
                  return sorted
                })
                return this
              }
              const sortorder = { Naam: 'asc' }
              stand.keySort(sortorder)

              var res = null
              var res2 = null
              //console.log(stand);

              // de team resultaten verzamelen
              if (
                keys.toLowerCase() === 'dinsdagmiddag' ||
                keys.toLowerCase() === 'standbsk2019'
              ) {
                res = alasql(
                  'Select Team as Naam, Count(Team) / 4 as Gespeeld  , Sum (Punten) as Punten , (  Sum(Punten) / (Count(Team) * 13)  * 100 ) as PercMP  from ? GROUP BY Team',
                  [stand]
                )
              } else {
                res = alasql(
                  //  "Select Naam , Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy , Sum (Punten) / (Count(Naam)) as MPPerc from ? GROUP BY Naam",
                  'Select Naam , Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy, Max(Gem) as Gem , Sum (Punten) / (Count(Naam)) as MPPerc , Sum(Caramboles) / (Count(Naam)  * Max(Temakencaramboles) ) * 100  as CarPerc from ? GROUP BY Naam',

                  [stand]
                )
              }

              //!------ keysort toekennen aan de array
              res.keySort = function (keys) {
                keys = keys || {}

                var obLen = function (obj) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++
                  }
                  return size
                }

                var obIx = function (obj, ix) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key
                      size++
                    }
                  }
                  return false
                }

                var keySort = function (a, b, d) {
                  d = d !== null ? d : 1
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0
                  return a > b ? 1 * d : -1 * d
                }

                var KL = obLen(keys)

                if (!KL) return this.sort(keySort)

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === 'desc' || keys[k] === -1
                      ? -1
                      : keys[k] === 'skip' || keys[k] === 0
                      ? 0
                      : 1
                }

                this.sort(function (a, b) {
                  var sorted = 0,
                    ix = 0

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix)
                    if (k) {
                      var dir = keys[k]
                      sorted = keySort(a[k], b[k], dir)
                      ix++
                    }
                  }
                  return sorted
                })
                return this
              }

              const sortorderDef =
                keys.toLowerCase() === 'dinsdagmiddag' ||
                keys.toLowerCase().startsWith('standdonderdag5pins') ||
                keys.toLowerCase() === 'standbsk2019' ||
                this.state.typestand === 'MP'
                  ? keys.toLowerCase() === 'dinsdagmiddag' ||
                    keys.toLowerCase() === 'standbsk2019'
                    ? {
                        // PercMP: "desc"  // is dinsdagmiddag ivm ongelijke partijen.
                        Punten: 'desc'
                      }
                    : {
                        MPPerc: 'desc',
                        Punten: 'desc',
                        Gespeeld: 'asc',
                        Beurten: 'asc'
                      }
                  : {
                      //MPPerc: "desc",
                      Punten: 'desc',
                      Gespeeld: 'asc',
                      CarPerc: 'desc',
                      Beurten: 'asc'
                    }
              res.keySort(sortorderDef)

              this.setState({ data: res })

              //Wedstrijd resultaten:
              if (
                keys.toLowerCase() === 'dinsdagmiddag' ||
                keys.toLowerCase() === 'standbsk2019'
              ) {
                // res = alasql(
                //   "Select Team as Naam, Count(Team) as Gespeeld , Sum (Punten) as Punten , (  Sum(Punten) / (Count(Team) * 10)  * 100 ) as PercMP  from ? GROUP BY Team",
                //   [stand]
                // );
                res2 = alasql(
                  'Select Team as Naam, Count(Team) / 4 as Gespeeld  , Sum (Punten) as Punten , (  Sum(Punten) / (Count(Team) * 13)  * 100 ) as PercMP  from ? GROUP BY Team',
                  [stand]
                )
              } else {
                res2 = alasql(
                  //  "Select Naam , Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy , Sum (Punten) / (Count(Naam)) as MPPerc from ? GROUP BY Naam",
                  'Select Naam , Count(Naam) as Gespeeld , Sum(Caramboles) as Caramboles , Sum (Beurten) as Beurten , Sum (Punten) as Punten , Max(HoogsteSerie) as HoogsteSerie , Max(Moyenne) as PMoy, Max(Gem) as Gem , Sum (Punten) / (Count(Naam)) as MPPerc , Sum(Caramboles) / (Count(Naam)  * Max(Temakencaramboles) ) * 100  as CarPerc from ? GROUP BY Naam',

                  [stand]
                )
              }

              //!------ keysort toekennen aan de array
              res2.keySort = function (keys) {
                keys = keys || {}

                var obLen = function (obj) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++
                  }
                  return size
                }

                var obIx = function (obj, ix) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key
                      size++
                    }
                  }
                  return false
                }

                var keySort = function (a, b, d) {
                  d = d !== null ? d : 1
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0
                  return a > b ? 1 * d : -1 * d
                }

                var KL = obLen(keys)

                if (!KL) return this.sort(keySort)

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === 'desc' || keys[k] === -1
                      ? -1
                      : keys[k] === 'skip' || keys[k] === 0
                      ? 0
                      : 1
                }

                this.sort(function (a, b) {
                  var sorted = 0,
                    ix = 0

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix)
                    if (k) {
                      var dir = keys[k]
                      sorted = keySort(a[k], b[k], dir)
                      ix++
                    }
                  }
                  return sorted
                })
                return this
              }
              wedstrijddetails.keySort = function (keys) {
                keys = keys || {}

                var obLen = function (obj) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++
                  }
                  return size
                }

                var obIx = function (obj, ix) {
                  var size = 0,
                    key
                  for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      if (size === ix) return key
                      size++
                    }
                  }
                  return false
                }

                var keySort = function (a, b, d) {
                  d = d !== null ? d : 1
                  // a = a.toLowerCase(); // this breaks numbers
                  // b = b.toLowerCase();
                  if (a === b) return 0
                  return a > b ? 1 * d : -1 * d
                }

                var KL = obLen(keys)

                if (!KL) return this.sort(keySort)

                for (var k in keys) {
                  // asc unless desc or skip
                  keys[k] =
                    keys[k] === 'desc' || keys[k] === -1
                      ? -1
                      : keys[k] === 'skip' || keys[k] === 0
                      ? 0
                      : 1
                }

                this.sort(function (a, b) {
                  var sorted = 0,
                    ix = 0

                  while (sorted === 0 && ix < KL) {
                    var k = obIx(keys, ix)
                    if (k) {
                      var dir = keys[k]
                      sorted = keySort(a[k], b[k], dir)
                      ix++
                    }
                  }
                  return sorted
                })
                return this
              }
              // const soDefWed = {
              //   Punten: "desc"
              // };
              // res2.keySort(soDefwed);
              wedstrijddetails.keySort({
                Datum: 'desc',
                Teams: 'asc',
                Tijd: 'asc'
              })
              this.setState({ wed: wedstrijddetails })
              // console.log("Wedstrijden:", wedstrijddetails);
            })
        })
    }

    HaalDataOp()
  }

  render () {
    let HS = 0
    let HSNaam = ''
    let Driebanden = this.state.dagdeel.indexOf('3b') > 0
    let Naam =
      this.state.dagdeel === 'dinsdagmiddag' ||
      this.state.dagdeel === 'StandBSK2019'
        ? 'Team '
        : ''
    //console.log(this.state.dagdeel);
    const TeamNamen = [
      'null',
      'ADO 1',
      'BCO',
      'Bob & Gerard',
      'OBIS 3',
      'ADO 1',
      'Kameraden',
      'BSK',
      'Rene & Gerrit',
      'T&T',
      'ADO 2',
      'ADO 3',
      'MariJel',
      'Vriezers',
      'Jari & Corry',
      'CePi'
    ]
    // console.log(this.state.dagdeel);

    const OverzichtGespeeldeWedstrijden = type => {
      // if ((type = "MPPerc")) {
      //   const { mapdata } = this.state.dataMP;
      // } else {
      //
      // }
      //alert("Maak stand op");

      // console.log("Hallo");
      //console.log(type);
      let style1 = [
        { backgroundColor: 'gold', padding: 10, fontWeight: 700 },
        { backgroundColor: 'silver', padding: 10, fontWeight: 700 },
        {
          backgroundColor: '#8C7853',
          color: 'white',
          padding: 10,
          fontWeight: 700
        }
      ]
      var i
      for (i = 0; i < this.state.wed.length; i++) {
        style1.push({ padding: 3, fontWeight: 500 })
      }
      // console.log(JSON.parse(style1[0]));
      let regel = []
      //regel.push("<tbody>");
      let dag = new Date('1-1-1900')
      let zelfdeteam = 1
      //console.log(this.state.dagdeel);
      this.state.dagdeel === 'dinsdagmiddag' ||
      this.state.dagdeel === 'StandBSK2019'
        ? this.state.wed.map(function (d, idx) {
            if (dag !== d.Datum) {
              regel.push(
                <tr key={idx}>
                  <td
                    colspan='3'
                    style={{ color: 'green', fontWeight: 'bold' }}
                  >
                    {TeamNamen[d.Thuis]} tegen {TeamNamen[d.Uit]}
                  </td>
                  <td colspan='2'>
                    <hr />
                  </td>
                  <td
                    colspan='2'
                    align='right'
                    style={{ color: 'green', fontWeight: 'bold' }}
                  >
                    {new Date(d.Datum).toLocaleDateString('nl-NL', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </td>
                </tr>
              )

              dag = d.Datum
              zelfdeteam = d.Teams
            } else {
              if (zelfdeteam !== d.Teams) {
                regel.push(
                  <tr key={idx}>
                    <td
                      colspan='3'
                      style={{ color: 'green', fontWeight: 'bold' }}
                    >
                      {d.thuisTeamNamen[d.Thuis]} tegen {TeamNamen[d.Uit]}
                    </td>
                    <td colspan='2'>
                      <hr />
                    </td>
                    <td
                      colspan='2'
                      align='right'
                      style={{ color: 'green', fontWeight: 'bold' }}
                    >
                      {new Date(d.Datum).toLocaleDateString('nl-NL', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </td>
                  </tr>
                )
                dag = d.Datum
                zelfdeteam = d.Teams
              }
            }
            regel.push(
              <tr key={idx}>
                {/* <td>{idx + 1}</td> */}
                <td
                  style={{
                    padding: 3,
                    //fontWeight: 500,
                    width: '30%',
                    fontSize: 14,
                    color:
                      d.Winnaar === d.Voornaam1
                        ? 'blue'
                        : d.Winnaar === 'Remise'
                        ? 'green'
                        : 'black'
                  }}
                >
                  {d.Voornaam1} ({d.GemaaktSP1}/{d.TemakenCarambolesSpeler1} )
                </td>
                <td align='right' style={{ width: '10%' }}>
                  {d.Gem1.toString().replace('.', ',')}
                </td>
                <td align='right' style={{ width: '5%', fontWeight: 'bold' }}>
                  {d.Speler1PuntenBelgischsysteem}
                </td>
                <td align='center' style={{ width: '10%', color: 'red' }}>
                  {d.Beurten}
                </td>
                <td
                  style={{
                    padding: 3,
                    // fontWeight: 500,
                    width: '30%',
                    fontSize: 14,
                    color:
                      d.Winnaar === d.Voornaam2
                        ? 'blue'
                        : d.Winnaar === 'Remise'
                        ? 'green'
                        : 'black'
                  }}
                >
                  {d.Voornaam2} ({d.GemaaktSP2}/{d.TemakenCarambolesSpeler2})
                </td>
                <td align='right' style={{ width: '10%' }}>
                  {d.Gem2.toString().replace('.', ',')}
                </td>
                <td align='right' style={{ width: '5%', fontWeight: 'bold' }}>
                  {d.Speler2PuntenBelgischsysteem}{' '}
                </td>
              </tr>
            )
          })
        : this.state.wed.map(function (d, idx) {
            if (dag !== d.Datum) {
              regel.push(
                <tr key={idx}>
                  <td
                    colspan='3'
                    style={{ color: 'green', fontWeight: 'bold' }}
                  >
                    Clubavond
                  </td>
                  <td colspan='1'>
                    <hr />
                  </td>
                  <td
                    colspan='3'
                    align='right'
                    style={{ color: 'green', fontWeight: 'bold' }}
                  >
                    {new Date(d.Datum).toLocaleDateString('nl-NL', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </td>
                </tr>
              )

              dag = d.Datum
              zelfdeteam = d.Teams
            } else {
              if (zelfdeteam !== d.Teams) {
                regel.push(
                  <tr key={idx}>
                    <td
                      colspan='3'
                      style={{ color: 'green', fontWeight: 'bold' }}
                    >
                      {d.thuisTeamNamen[d.Thuis]} tegen {TeamNamen[d.Uit]}
                    </td>
                    <td colspan='2'>
                      <hr />
                    </td>
                    <td
                      colspan='2'
                      align='right'
                      style={{ color: 'green', fontWeight: 'bold' }}
                    >
                      {new Date(d.Datum).toLocaleDateString('nl-NL', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </td>
                  </tr>
                )
                dag = d.Datum
                zelfdeteam = d.Teams
              }
            }
            regel.push(
              <tr key={idx}>
                {/* <td>{idx + 1}</td> */}
                <td
                  style={{
                    padding: 3,
                    //fontWeight: 500,
                    width: '30%',
                    fontSize: 14,
                    color:
                      d.Winnaar === d.Voornaam1
                        ? d.GemaaktSP1 !== d.TemakenCarambolesSpeler1
                          ? '#ff5500'
                          : 'blue'
                        : d.Winnaar === 'Remise'
                        ? 'green'
                        : 'black'
                  }}
                >
                  {d.Voornaam1} {d.Achternaam1.substring(0, 1)} ({d.GemaaktSP1}/
                  {d.TemakenCarambolesSpeler1} )
                </td>
                <td align='right' style={{ width: '10%' }}>
                  {d.Gem1.toString().replace('.', ',')}
                </td>
                <td align='right' style={{ width: '5%', fontWeight: 'bold' }}>
                  {type === 'maandagavond'
                    ? d.Speler1Punten
                    : d.Speler1PuntenBelgischsysteem}
                </td>
                <td
                  align='center'
                  style={{
                    width: '10%',
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                >
                  {d.Beurten}
                </td>
                <td
                  style={{
                    padding: 3,
                    // fontWeight: 500,
                    width: '30%',
                    fontSize: 14,
                    color:
                      d.Winnaar === d.Voornaam2
                        ? d.GemaaktSP2 !== d.TemakenCarambolesSpeler2
                          ? '#ff5500'
                          : 'blue'
                        : d.Winnaar === 'Remise'
                        ? 'green'
                        : 'black'
                  }}
                >
                  {d.Voornaam2} {d.Achternaam2.substring(0, 1)} ({d.GemaaktSP2}/
                  {d.TemakenCarambolesSpeler2})
                </td>
                <td align='right' style={{ width: '10%' }}>
                  {d.Gem2.toString().replace('.', ',')}
                </td>
                <td align='right' style={{ width: '5%', fontWeight: 'bold' }}>
                  {type === 'maandagavond'
                    ? d.Speler2Punten
                    : d.Speler2PuntenBelgischsysteem}
                </td>
              </tr>
            )
            //console.log(d);
          })
      //  regel.push("</tbody");
      return regel
    }

    const MaakStandOp = type => {
      // if ((type = "MPPerc")) {
      //   const { mapdata } = this.state.dataMP;
      // } else {
      //
      // }
      //alert("Maak stand op");
      let Teamstart = 0
      let TeamEnd = 0
      if (type === 'A') {
        Teamstart = 0
        TeamEnd = 7
      }
      if (type === 'B') {
        Teamstart = 7
        TeamEnd = 15
      }

      //console.log(type);
      let style1 = [
        { backgroundColor: 'gold', padding: 10, fontWeight: 700 },
        { backgroundColor: 'silver', padding: 10, fontWeight: 700 },
        {
          backgroundColor: '#8C7853',
          color: 'white',
          padding: 10,
          fontWeight: 700
        }
      ]
      var i
      for (i = 0; i < this.state.data.length; i++) {
        style1.push({ padding: 3, fontWeight: 500 })
      }
      // console.log(JSON.parse(style1[0]));
      let regel = []
      let pouleheadergezet = false
      this.state.dagdeel === 'dinsdagmiddag' ||
      this.state.dagdeel === 'StandBSK2019'
        ? this.state.data.map(function (d, idx) {
            // console.log(d);
            if (Number(d.Naam) > Teamstart && Number(d.Naam) <= TeamEnd) {
              if (!pouleheadergezet) {
                if (Teamstart === 0) {
                  regel.push(
                    <tr key={idx}>
                      {/* <td>{idx + 1}</td> */}
                      <td
                        colspan='5'
                        style={{
                          padding: 3,
                          fontSize: 18,
                          fontWeight: 700,
                          height: 40,
                          color: 'blue'
                        }}
                      >
                        {'Poule A'}
                      </td>
                    </tr>
                  )
                } else {
                  regel.push(
                    <tr key={idx}>
                      {/* <td>{idx + 1}</td> */}
                      <td
                        colspan='5'
                        style={{
                          padding: 3,
                          fontSize: 18,
                          fontWeight: 700,
                          height: 40,
                          color: 'blue'
                        }}
                      >
                        {'Poule B'}
                      </td>
                    </tr>
                  )
                }
                pouleheadergezet = true
              }
              // regel.push(
              //   <tr>
              //     <td>{d.PuntenBSK}</td>
              //   </tr>
              // );
              if (d.HoogsteSerie > HS) {
                HSNaam = d.Naam
                HS = d.HoogsteSerie
              }
              d.Gemiddelde = parseFloat(d.Caramboles / d.Beurten)
                .toFixed(Driebanden ? 3 : 2)
                .toString()
                .replace('.', ',')

              d.PMoy = d.PMoy.toFixed(Driebanden ? 3 : 2)
                .toString()
                .replace('.', ',')

              d.PercMP = d.PercMP.toFixed(2)
                .toString()
                .replace('.', ',')

              d.CarPerc = parseFloat(d.CarPerc)
                .toFixed(2)
                .toString()
                .replace('.', ',')

              const Punten = d.Punten

              regel.push(
                <tr key={idx}>
                  {/* <td>{idx + 1}</td> */}
                  <td style={{ padding: 3, fontWeight: 700, width: '40%' }}>
                    {' '}
                    {/* {Naam} */}
                    {TeamNamen[Number(d.Naam)]}
                    {/* ({d.Naam} ) */}
                    {/* {d.Naam.replace("van der", "vd")} */}
                  </td>
                  <td align='left' style={{ width: '20%' }}>
                    {d.Gespeeld}
                  </td>
                  <td align='left' style={{ width: '20%' }}>
                    {Punten}
                  </td>
                  <td align='left' style={{ width: '20%' }}>
                    {d.PercMP}
                  </td>
                </tr>
              )
            }
          })
        : this.state.data.map(function (d, idx) {
            if (d.HoogsteSerie > HS) {
              //console.log(d.HoogsteSerie, d.Naam);
              HSNaam = d.Naam
              HS = d.HoogsteSerie
            }
            //console.log(d.Beurten);
            if (d.Beurten === 0) {
              d.Gemiddelde = 0
            } else {
              d.Gemiddelde = parseFloat(d.Caramboles / d.Beurten)
                .toFixed(Driebanden ? 3 : 2)
                .toString()
                .replace('.', ',')
            }
            let PMoy = '0'
            if (d.PMoy) {
              PMoy = parseFloat(d.PMoy)
              if (d.PMoy) {
                PMoy = PMoy.toFixed(Driebanden ? 3 : 2)
                  .toString()
                  .replace('.', ',')
              }
            }
            //Standaard CarPerc afdrukken

            // console.log(d.CarPerc.toFixed(2));
            let CarPerc = ''
            if (d.CarPerc) {
              CarPerc = d.CarPerc.toFixed(1)
                .toString()
                .replace('.', ',')
                .replace(',0', '')
            }

            let MPPerc = ''
            if (d.MPPerc) {
              MPPerc = d.MPPerc.toFixed(2)
                .toString()
                .replace('.', ',')
                .replace(',00', '')
            }
            regel.push(
              <tr key={idx}>
                {/* <td align="right">{idx + 1}</td> */}

                <td style={style1[idx]}>
                  {' '}
                  {Naam}
                  {d.Naam
                    ? d.Naam.replace('van der', 'vd')
                    : 'Nog geen wedstrijden!!!'}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.Gespeeld ? d.Gespeeld : ''}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.Punten ? d.Punten : '0'}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.Caramboles ? d.Caramboles : ''}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.Beurten ? d.Beurten : ''}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.Gemiddelde ? d.Gemiddelde : ''}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.HoogsteSerie ? d.HoogsteSerie : ''}
                </td>
                <td align='right' style={style1[idx]}>
                  {PMoy}
                </td>
                <td align='right' style={style1[idx]}>
                  {/* {(type = "MPPerc" ? MPPerc : CarPerc)} */}
                  {CarPerc}
                </td>
                <td align='right' style={style1[idx]}>
                  {d.Gem ? d.Gem : ''}
                </td>
              </tr>
            )
          })
      //console.log(regel);
      return regel
    }

    const GenereerKop = () => {
      const kopregel = []
      this.state.dagdeel === 'dinsdagmiddag' ||
      this.state.dagdeel === 'StandBSK2019'
        ? kopregel.push(
            <thead key='1'>
              <tr>
                {/* <th width="10%">Plaats</th> */}
                <th width='40%' style={{ padding: 3 }}>
                  Naam
                </th>

                <th width='20%'>Gesp</th>
                <th width='20%'>Pnt</th>
                <th width='20%'>MP%</th>
              </tr>
            </thead>
          )
        : kopregel.push(
            <thead key='12'>
              <tr>
                {/* <th width="10%">Plaats</th> */}
                <th width='39%' style={{ padding: 3 }}>
                  Naam
                </th>
                <th width='8%' align='right'>
                  Gesp
                </th>
                <th width='8%' align='right'>
                  Pnt
                </th>
                <th width='8%' align='right'>
                  Car
                </th>
                <th width='8%' align='right'>
                  Brt
                </th>
                <th width='8%' align='right'>
                  Gem
                </th>
                <th width='5%' align='right'>
                  HS
                </th>
                <th width='8%' align='right'>
                  P.Moy
                </th>
                <th width='8%' align='right'>
                  Car %
                </th>
                <th width='8%' align='right'>
                  Sp gem
                </th>
              </tr>
            </thead>
          )
      return kopregel
    }

    const Voetregel = () => {
      const footer = []
      this.state.dagdeel !== 'dinsdagmiddag' ||
      this.state.dagdeel === 'StandBSK2019'
        ? parseInt(HS, 10) === 0
          ? footer.push('')
          : footer.push(`Hoogste serie ${HS} door
        ${HSNaam}`)
        : footer.push('')
      return footer
    }

    return (
      // <div>
      //   <h1>Stand wordt op dit moment opnieuw geprogrammeerd.</h1>
      //   <p>
      //     Uiterlijk eind september zullen de uitslagen zichtbaar zijn. De
      //     wedstrijden worden gereviewed en de punten zullen worden opnieuwd
      //     correct worden berekend en opgeslagen.
      //   </p>
      // </div>
      <div style={{ margin: 5 }}>
        <h3>Stand {this.state.dagdeel}</h3>
        <div style={{ maxWidth: 700, minWidth: 400 }}>
          {firebase.auth().currentUser.email === 'p@de-boer.net' && (
            <div>
              <div>Standberekening: {this.state.typestand}</div>
              <button
                className={
                  this.state.typestand === 'BSK'
                    ? 'btn-oranje'
                    : 'btn-unselected'
                }
                onClick={() => {
                  this.setState({ typestand: 'BSK' })
                }}
              >
                Belgisch systeem
              </button>
              <button
                className={
                  this.state.typestand === 'Woensdag'
                    ? 'btn-groen'
                    : 'btn-unselected'
                }
                onClick={() => {
                  this.setState({ typestand: 'Woensdag' })
                }}
              >
                Woensdag
              </button>
              <button
                className={
                  this.state.typestand === 'Maandag'
                    ? 'btn-blue'
                    : 'btn-unselected'
                }
                onClick={() => {
                  this.setState({ typestand: 'Maandag' })
                }}
              >
                Maandag
              </button>
              <button
                className={
                  this.state.typestand === 'MP'
                    ? 'btn-oranje'
                    : 'btn-unselected'
                }
                onClick={() => {
                  this.setState({ typestand: 'MP' })
                }}
              >
                MP%
              </button>
              {/* <button
                style={{
                  padding: 7,
                  marginLeft: 20,
                  borderRadius: 10,
                  borderColor: "black",
                  //border: 3,
                  backgroundColor:
                    this.state.CompetitieRonde === 1 ? "green" : "blue",
                  fontSize: this.state.CompetitieRonde === 1 ? 24 : 12,
                  color: "white"
                }}
                onClick={() => {
                  this.setState({ CompetitieRonde: 1 });
                }}
              >
                Periode 1
              </button>
              <button
                style={{
                  padding: 7,
                  marginLeft: 20,
                  borderRadius: 10,
                  borderColor: "black",
                  fontSize: this.state.CompetitieRonde === 2 ? 24 : 12,
                  backgroundColor:
                    this.state.CompetitieRonde === 2 ? "green" : "blue",
                  color: "white"
                }}
                onClick={() => {
                  this.setState({ CompetitieRonde: 2 });
                }}
              >
                Periode 2
              </button>*/}
            </div>
          )}
          <div>
            <button
              style={{
                padding: 7,
                marginLeft: 20,
                borderRadius: 10,
                borderColor: 'black',
                //border: 3,
                backgroundColor:
                  this.state.CompetitieRonde === 1 ? 'green' : 'blue',
                fontSize: this.state.CompetitieRonde === 1 ? 24 : 12,
                color: 'white'
              }}
              onClick={() => {
                this.setState({ CompetitieRonde: 1 })
              }}
            >
              Periode 1
            </button>
            <button
              style={{
                padding: 7,
                marginLeft: 20,
                borderRadius: 10,
                borderColor: 'black',
                fontSize: this.state.CompetitieRonde === 2 ? 24 : 12,
                backgroundColor:
                  this.state.CompetitieRonde === 2 ? 'green' : 'blue',
                color: 'white'
              }}
              onClick={() => {
                this.setState({ CompetitieRonde: 2 })
              }}
            >
              Periode 2
            </button>
            <button
              style={{
                padding: 7,
                marginLeft: 20,
                borderRadius: 10,
                borderColor: 'black',
                fontSize: this.state.CompetitieRonde === 3 ? 24 : 12,
                backgroundColor:
                  this.state.CompetitieRonde === 3 ? 'green' : 'blue',
                color: 'white'
              }}
              onClick={() => {
                this.setState({ CompetitieRonde: 3 })
              }}
            >
              Periode 3
            </button>
            <button
              style={{
                padding: 7,
                marginLeft: 20,
                borderRadius: 10,
                borderColor: 'black',
                fontSize: this.state.CompetitieRonde === 4 ? 24 : 12,
                backgroundColor:
                  this.state.CompetitieRonde === 4 ? 'green' : 'blue',
                color: 'white'
              }}
              onClick={() => {
                this.setState({ CompetitieRonde: 4 })
              }}
            >
              Periode 4
            </button>
            <button
              style={{
                padding: 7,
                marginLeft: 20,
                borderRadius: 10,
                borderColor: 'black',
                fontSize: this.state.CompetitieRonde === 5 ? 24 : 12,
                backgroundColor:
                  this.state.CompetitieRonde === 5 ? 'green' : 'blue',
                color: 'white'
              }}
              onClick={() => {
                this.setState({ CompetitieRonde: 5 })
              }}
            >
              Periode 5
            </button>
            <button
              style={{
                padding: 7,
                marginLeft: 20,
                borderRadius: 10,
                borderColor: 'black',
                fontSize: this.state.CompetitieRonde === 6 ? 24 : 12,
                backgroundColor:
                  this.state.CompetitieRonde === 6 ? 'green' : 'blue',
                color: 'white'
              }}
              onClick={() => {
                this.setState({ CompetitieRonde: 6 })
              }}
            >
              Vrije partijen
            </button>
          </div>
          <table style={{ width: '100%' }}>
            {GenereerKop()}
            {this.state.dagdeel === 'StandBSK2019' && MaakStandOp('A')}
            {this.state.dagdeel === 'StandBSK2019' && MaakStandOp('B')}
            {this.state.dagdeel !== 'StandBSK2019' && MaakStandOp()}
            <tr key='123'>
              <td colSpan='7' style={{ padding: 3 }}>
                {Voetregel()}
              </td>
            </tr>
            {this.state.wedstrijden && (
              <tr key='1234'>
                <td colspan='7'>
                  <h4 style={{ color: 'blue' }}>
                    Overzicht wedstrijden (laatste als eerste)
                  </h4>
                  <p>
                    <span style={{ color: 'blue' }}>Blauw = Winnaar</span>,
                    <span style={{ color: 'green' }}> Groen = Remise</span>,{' '}
                    <span style={{ color: '#ff5500' }}>
                      Oranje = gewonnen met beurtenlimiet
                    </span>
                  </p>
                  <table width='100%'>
                    {/* <tbody> */}
                    <thead>
                      <tr key='12345'>
                        <th>Speler1</th>
                        <th align='right'>Gem</th>
                        <th align='right'>Pnt</th>
                        <th align='centre'>Brt</th>
                        <th>Speler2</th>
                        <th align='right'>Gem</th>
                        <th align='right'>Pnt</th>
                      </tr>
                    </thead>
                    {OverzichtGespeeldeWedstrijden(this.state.dagdeel)}
                    {/* </tbody> */}
                  </table>
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
    )
  }
}
export default Stand
