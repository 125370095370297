import React, { Component } from "react";
//import { render } from "react-native-web";
export default class VijfPinsOrg extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          marginTop: "-15px",
          marginLeft: "5px"
          //   width: "950px",
          //   height: "460px",
          //   padding: "5px",
          //   border: "1px solid gold",
          //   borderRadius: "10px"
        }}
      >
        <div
          align="center"
          style={{
            display: "flex",
            flex: 1,
            // marginTop: '-15px',
            //marginLeft: "5px",
            margin: 5,
            width: "45%",
            height: "460px",
            padding: "5px",
            border: "2px solid blue",
            borderRadius: "15px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "center"
            }}
          >
            <h4>Speler 1</h4>
          </div>
        </div>
        <div
          align="center"
          style={{
            flex: 1,
            display: "flex",
            //marginTop: "-15px",
            //marginLeft: "5px",
            margin: 5,
            width: "45%",
            height: "460px",
            padding: "5px",
            border: "2px solid blue",
            borderRadius: "15px"
          }}
        >
          Speler2
        </div>
      </div>
    );
  }
}
