import React, { Component } from "react";
import Stand from "./Stand";

export default class StandloaderWA3bgroot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Stand avond="woensdagavond3bgroot" details="wedstrijden" />;
  }
}
