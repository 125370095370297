import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Uitleg from "./components/Uitleg";
import Navbar from "./components/CustomNavbar";
import { Paper } from "@material-ui/core";
import { observer } from "mobx-react";
import ScoreboardLoadertafels14 from "./components/ScoreboardLoadertafels14";
import ScoreboardLoadertafels58 from "./components/ScoreboardLoadertafels58";
import ScoreboardLoadertafels78 from "./components/ScoreboardLoadertafels78";
import ScoreboardLoader from "./components/ScoreboardLoader";
import ScoreboardLoader2 from "./components/ScoreboardLoader2";
import ScoreboardLoader3 from "./components/ScoreboardLoader3";
import ScoreboardLoader4 from "./components/ScoreboardLoader4";
import ScoreboardLoader5 from "./components/ScoreboardLoader5";
import ScoreboardLoader6 from "./components/ScoreboardLoader6";
import ScoreboardLoader7 from "./components/ScoreboardLoader7";
import ScoreboardLoader8 from "./components/ScoreboardLoader8";
import ScoreboardLoaderThuis from "./components/ScoreboardLoaderThuis";
import StandloaderMA from "./components/StandloaderMA";
// import StandloaderMA2 from "./components/StandloaderMA2";
// import StandloaderMA3 from "./components/StandloaderMA3";
// import StandloaderMA4 from "./components/StandloaderMA4";
import StandloaderDA from "./components/StandloaderDA";

// import StandloaderDA2 from "./components/StandloaderDA2";
// import StandloaderDA3 from "./components/StandloaderDA3";
// import StandloaderDA4 from "./components/StandloaderDA4";
import StandloaderDM from "./components/StandloaderDM";
import StandloaderDA5P from "./components/StandloaderDA5P";
import Standloader5Pins from "./components/Standloader5Pins";
import StandloaderBiatlon from "./components/StandloaderBiatlon";
// import StandloaderDA5P2 from "./components/StandloaderDA5P2";
// import StandloaderBSK2019 from "./components/StandloaderBSK2019";
import StandloaderDA3bklein from "./components/StandloaderDA3bklein";
import StandloaderDA3bgroot from "./components/StandloaderDA3bgroot";
// import StandloaderDA3bklein2 from "./components/StandloaderDA3bklein2";
// import StandloaderDA3bgroot2 from "./components/StandloaderDA3bgroot2";
// import StandloaderDA3bklein3 from "./components/StandloaderDA3bklein3";
// import StandloaderDA3bgroot3 from "./components/StandloaderDA3bgroot3";
// import StandloaderDA3bklein4 from "./components/StandloaderDA3bklein4";
// import StandloaderDA3bgroot4 from "./components/StandloaderDA3bgroot4";
import StandloaderWA from "./components/StandloaderWA";
// import StandloaderWA2 from "./components/StandloaderWA2";
// import StandloaderWA3 from "./components/StandloaderWA3";
// import StandloaderWA4 from "./components/StandloaderWA4";
import StandloaderWA3bgroot from "./components/StandloaderWA3bgroot";
// import StandloaderWA3bgroot2 from "./components/StandloaderWA3bgroot2";
// import StandloaderWA3bgroot3 from "./components/StandloaderWA3bgroot3";
// import StandloaderWA3bgroot4 from "./components/StandloaderWA3bgroot4";
// import StandloaderWA3bgroot5 from "./components/StandloaderWA3bgroot5";
import MatrixloaderMA from "./components/MatrixloaderMA";
// import MatrixloaderMA2 from "./components/MatrixloaderMA2";
// import MatrixloaderMA3 from "./components/MatrixloaderMA3";
// import MatrixloaderMA4 from "./components/MatrixloaderMA4";
import MatrixloaderDA from "./components/MatrixloaderDA";
import MatrixloaderC70 from "./components/MatrixloaderC70";
// import MatrixloaderDA2 from "./components/MatrixloaderDA2";
// import MatrixloaderDA3 from "./components/MatrixloaderDA3";
// import MatrixloaderDA4 from "./components/MatrixloaderDA4";
import MatrixloaderDM from "./components/MatrixloaderDM";
import MatrixloaderDA3bklein from "./components/MatrixloaderDA3bklein";
import MatrixloaderDA3bgroot from "./components/MatrixloaderDA3bgroot";
// import MatrixloaderDA3bklein2 from "./components/MatrixloaderDA3bklein2";
// import MatrixloaderDA3bgroot2 from "./components/MatrixloaderDA3bgroot2";
// import MatrixloaderDA3bklein3 from "./components/MatrixloaderDA3bklein3";
// import MatrixloaderDA3bgroot3 from "./components/MatrixloaderDA3bgroot3";
// import MatrixloaderDA3bklein4 from "./components/MatrixloaderDA3bklein4";
// import MatrixloaderDA3bgroot4 from "./components/MatrixloaderDA3bgroot4";
import MatrixloaderWA from "./components/MatrixloaderWA";
// import MatrixloaderWA2 from "./components/MatrixloaderWA2";
// import MatrixloaderWA3 from "./components/MatrixloaderWA3";
// import MatrixloaderWA4 from "./components/MatrixloaderWA4";
import MatrixloaderWA3bgroot from "./components/MatrixloaderWA3bgroot";
// import MatrixloaderWA3bgroot2 from "./components/MatrixloaderWA3bgroot2";
// import MatrixloaderWA3bgroot3 from "./components/MatrixloaderWA3bgroot3";
// import MatrixloaderWA3bgroot4 from "./components/MatrixloaderWA3bgroot4";
// import MatrixloaderWA3bgroot5 from "./components/MatrixloaderWA3bgroot5";
import MatrixloaderDA5Pins from "./components/MatrixloaderDA5Pins";
//import MatrixloaderDA5Pins2 from "./components/MatrixloaderDA5Pins2";
import AfgebrokenPartijen from "./components/AfgebrokenPartijen";
import Reparatie from "./components/Reparatie";
import InvoerPartij from "./components/InvoerPartij.jsx";
import Overzicht from "./components/Overzicht";
import Stand from "./components/Stand";
import Matrix from "./components/Matrix";
import VijfPins from "./components/VijfPins.jsx";

//import "firebase/auth";
//import { firebase, wedstrijden } from "./models/firestore";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import "firebase/auth";
import firebase from "firebase";
import * as firebaseui from "firebaseui";

import styles from "./AppStyles.css"; // This uses CSS modules.
import "./firebase-styling.global.css"; // Import globally.

const App = observer(
  class App extends Component {
    uiConfig = {
      signInFlow: "popup",
      //CredentialHelper: [firebase.auth.CredentialHelper.NONE],
      signInOptions: [
        //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false
      },
      credentialHelper: firebaseui.auth.CredentialHelper.NONE
    };

    state = {
      isSignedIn: undefined
    };

    /**
     * @inheritDoc
     */
    componentDidMount() {
      this.registerAuthObserver = firebase.auth().onAuthStateChanged(user => {
        this.setState({
          isSignedIn: !!user
          //
        });
      });
    }
    /**
     * @inheritDoc
     */
    componentWillUnmount() {
      this.unregisterAuthObserver();
    }

    // vrijwilligers.docs.map(doc => {
    //   alert(doc.data.email);
    // });

    render() {
      if (this.state.isSignedIn) {
        firebase.auth().useDeviceLanguage();
        // let gebruikersNaam = "";
        // let gebruikersrollen = "";
        // const { disabled } = this.state;
        //let children = undefined;
        //const { classes, email } = this.props;
        //const { docs, query } = wedstrijden;

        // let gebruiker = undefined;
        // gebruiker = vrijwilligers.docs.findIndex(email => {
        //   email === firebase.auth().currentUser.email;
        // });
        // if (gebruiker) {
        //   alert(gebruiker);
        // }
        // vrijwilligers.query = ref =>
        //   ref.where("email", "==", firebase.auth().currentUser.email);

        // if (vrijwilligers.docs.data) {
        //   alert(vrijwilligers.doc.data.email);
        // }

        //Moet hier nog een promise omheen ????
        // function VindIngelogdeGebruiker(element) {
        //   if (element.data.email) {
        //     // alert(
        //     //   "Gevonden" +
        //     //     element.data.email +
        //     //     "ingelogd als email" +
        //     //     firebase.auth().currentUser.email
        //     // );
        //     if (
        //       element.data.email.indexOf(firebase.auth().currentUser.email) > -1
        //     ) {
        //       //alert("Succesvol gebruiker gevonden");
        //       //console.log(firebase.auth().currentUser);

        //       gebruikersNaam = element.data.displayNaam;
        //       gebruikersrollen = element.data.rollen;

        //       //TODO: Uitzoeken hoe je een disabled user kan vinden en dan uitloggen.
        //       // if (firebase.auth.currentUser.disabled) {
        //       //   firebase.auth().signOut();
        //       // }
        //       return element.data.email === email;
        //     }
        //   }
        // }
        //docs.findIndex(VindIngelogdeGebruiker);
        //Gebruikersnaam en rollen ophalen
        // async function isGebuikerValide() {
        //   //await alert("Stop ier");
        //   await docs.findIndex(VindIngelogdeGebruiker);
        //   if (gebruikersNaam === "") {
        //     alert("ga nu uitloggen");
        //     firebase.auth().signOut();
        //   }
        // }

        // isGebuikerValide();
        //todos.query = (ref) => ref.where('finished', '==', false).orderBy('finished', 'asc').limit(20);

        return (
          <Router>
            {/* <Switch> */}
            {/* <Route path="/login" component={Login} exact /> */}
            {/* <Redirect from="/logout" to="/login" /> */}
            {/* <AuthenticatedComponent> */}
            <div>
              <Navbar gebruikersNaam={firebase.auth().currentUser.email} />
              {/* <Route exact path="/" component={Home} />
            <Route path="/medewerkers" component={Medewerkers} /> */}
              <Route exact path="/" component={Uitleg} name="Uitleg" />
              <Route path="/Uitleg" component={Uitleg} name="Uitleg" />
              <Route
                path="/AfgebrokenPartijen"
                component={AfgebrokenPartijen}
                name="AfgebrokenPartijen"
              />
              <Route path="/Reparatie" component={Reparatie} name="Reparatie" />
              <Route
                path="/InvoerPartij"
                component={InvoerPartij}
                name="InvoerPartij"
              />
              <Route
                path="/Scoreboardtafels14"
                component={ScoreboardLoadertafels14}
                name="Scoreboardtafels14"
              />
              <Route
                path="/Scoreboardtafels58"
                component={ScoreboardLoadertafels58}
                name="Scoreboardtafels58"
              />
              <Route
                path="/Scoreboardtafels78"
                component={ScoreboardLoadertafels78}
                name="Scoreboardtafels78"
              />
              <Route
                path="/Scoreboard"
                component={ScoreboardLoader}
                name="Scoreboard"
              />
              <Route
                path="/Scoreboard2"
                component={ScoreboardLoader2}
                name="Scoreboard2"
              />
              <Route
                path="/Scoreboard3"
                component={ScoreboardLoader3}
                name="Scoreboard3"
              />
              <Route
                path="/Scoreboard4"
                component={ScoreboardLoader4}
                name="Scoreboard4"
              />
              <Route
                path="/Scoreboard5"
                component={ScoreboardLoader5}
                name="Scoreboard5"
              />
              <Route
                path="/Scoreboard6"
                component={ScoreboardLoader6}
                name="Scoreboard6"
              />
              <Route
                path="/Scoreboard7"
                component={ScoreboardLoader7}
                name="Scoreboard7"
              />
              <Route
                path="/Scoreboard8"
                component={ScoreboardLoader8}
                name="Scoreboard8"
              />
              <Route
                path="/ScoreboardThuis"
                component={ScoreboardLoaderThuis}
                name="ScoreboardThuis"
              />
              <Route
                path="/Standmaandagavond"
                component={StandloaderMA}
                name="StandloaderMA"
              />
              {/* <Route
                path="/Standmaandagavond2"
                component={StandloaderMA2}
                name="StandloaderMA2"
              />
              <Route
                path="/Standmaandagavond3"
                component={StandloaderMA3}
                name="StandloaderMA3"
              />
              <Route
                path="/Standmaandagavond4"
                component={StandloaderMA4}
                name="StandloaderMA4"
              /> */}
              <Route
                path="/Standdinsdagavond"
                component={StandloaderDA}
                name="StandloaderDA"
              />
              {/* <Route
                path="/Standdinsdagavond2"
                component={StandloaderDA2}
                name="StandloaderDA2"
              />
              <Route
                path="/Standdinsdagavond3"
                component={StandloaderDA3}
                name="StandloaderDA3"
              />
              <Route
                path="/Standdinsdagavond4"
                component={StandloaderDA4}
                name="StandloaderDA4"
              /> */}
              <Route
                path="/Standdinsdagmiddag"
                component={StandloaderDM}
                name="StandloaderDM"
              />
              <Route
                path="/Standwoensdagavond"
                component={StandloaderWA}
                name="StandloaderWA"
              />
              {/* <Route
                path="/Standwoensdagavond2"
                component={StandloaderWA2}
                name="StandloaderWA2"
              />
              <Route
                path="/Standwoensdagavond3"
                component={StandloaderWA3}
                name="StandloaderWA3"
              />
              <Route
                path="/Standwoensdagavond4"
                component={StandloaderWA4}
                name="StandloaderWA4"
              /> */}
              <Route
                path="/Standdinsdagavond3bklein"
                component={StandloaderDA3bklein}
                name="StandloaderDA3bklein"
              />
              <Route
                path="/Standdinsdagavond3bgroot"
                component={StandloaderDA3bgroot}
                name="StandloaderDA3bgroot"
              />
              {/* <Route
                path="/Standdinsdagavond3bklein2"
                component={StandloaderDA3bklein2}
                name="StandloaderDA3bklein2"
              />
              <Route
                path="/Standdinsdagavond3bgroot2"
                component={StandloaderDA3bgroot2}
                name="StandloaderDA3bgroot2"
              />
              <Route
                path="/Standdinsdagavond3bklein3"
                component={StandloaderDA3bklein3}
                name="StandloaderDA3bklein3"
              />
              <Route
                path="/Standdinsdagavond3bgroot3"
                component={StandloaderDA3bgroot3}
                name="StandloaderDA3bgroot3"
              />
              <Route
                path="/Standdinsdagavond3bklein4"
                component={StandloaderDA3bklein4}
                name="StandloaderDA3bklein4"
              />
              <Route
                path="/Standdinsdagavond3bgroot4"
                component={StandloaderDA3bgroot4}
                name="StandloaderDA3bgroot4"
              /> */}
              <Route
                path="/Standwoensdagavond3bgroot"
                component={StandloaderWA3bgroot}
                name="StandloaderWA3bgroot"
              />
              {/* <Route
                path="/Standwoensdagavond3bgroot2"
                component={StandloaderWA3bgroot2}
                name="StandloaderWA3bgroot2"
              />
              <Route
                path="/Standwoensdagavond3bgroot3"
                component={StandloaderWA3bgroot3}
                name="StandloaderWA3bgroot3"
              />
              <Route
                path="/Standwoensdagavond3bgroot4"
                component={StandloaderWA3bgroot4}
                name="StandloaderWA3bgroot4"
              />
              <Route
                path="/Standwoensdagavond3bgroot5"
                component={StandloaderWA3bgroot5}
                name="StandloaderWA3bgroot5"
              /> */}
              <Route
                path="/Standdonderdagavond5Pins"
                component={StandloaderDA5P}
                name="StandloaderDA5P"
              />
              <Route
                path="/Stand5Pins"
                component={Standloader5Pins}
                name="Standloader5Pins"
              />
              <Route
                path="/StandBiatlon"
                component={StandloaderBiatlon}
                name="StandloaderBiatlon"
              />
              {/* <Route
                path="/Standdonderdagavond5Pins2"
                component={StandloaderDA5P2}
                name="StandloaderDA5P2"
              />
              <Route
                path="/StandBSK2019"
                component={StandloaderBSK2019}
                name="StandloaderBSK2019"
              /> */}
              <Route
                path="/Matrixmaandagavond"
                component={MatrixloaderMA}
                name="MatrixloaderMA"
              />
              {/* <Route
                path="/Matrixmaandagavond2"
                component={MatrixloaderMA2}
                name="MatrixloaderMA2"
              />
              <Route
                path="/Matrixmaandagavond3"
                component={MatrixloaderMA3}
                name="MatrixloaderMA3"
              />
              <Route
                path="/Matrixmaandagavond4"
                component={MatrixloaderMA4}
                name="MatrixloaderMA4"
              /> */}
              <Route
                path="/Matrixdinsdagavond"
                component={MatrixloaderDA}
                name="MatrixloaderDA"
              />
              <Route
                path="/MatrixClub70"
                component={MatrixloaderC70}
                name="MatrixloaderC70"
              />
              {/* <Route
                path="/Matrixdinsdagavond2"
                component={MatrixloaderDA2}
                name="MatrixloaderDA2"
              />
              <Route
                path="/Matrixdinsdagavond3"
                component={MatrixloaderDA3}
                name="MatrixloaderDA3"
              />
              <Route
                path="/Matrixdinsdagavond4"
                component={MatrixloaderDA4}
                name="MatrixloaderDA4"
              /> */}
              <Route
                path="/Matrixdinsdagmiddag"
                component={MatrixloaderDM}
                name="MatrixloaderDM"
              />
              <Route
                path="/Matrixwoensdagavond"
                component={MatrixloaderWA}
                name="MatrixloaderWA"
              />
              {/* <Route
                path="/Matrixwoensdagavond2"
                component={MatrixloaderWA2}
                name="MatrixloaderWA2"
              />
              <Route
                path="/Matrixwoensdagavond3"
                component={MatrixloaderWA3}
                name="MatrixloaderWA3"
              />
              <Route
                path="/Matrixwoensdagavond4"
                component={MatrixloaderWA4}
                name="MatrixloaderWA4"
              /> */}
              <Route
                path="/Matrixdinsdagavond3bklein"
                component={MatrixloaderDA3bklein}
                name="MatrixloaderDA3bklein"
              />
              <Route
                path="/Matrixdinsdagavond3bgroot"
                component={MatrixloaderDA3bgroot}
                name="MatrixloaderDA3bgroot"
              />
              {/* <Route
                path="/Matrixdinsdagavond3bklein2"
                component={MatrixloaderDA3bklein2}
                name="MatrixloaderDA3bklein2"
              />
              <Route
                path="/Matrixdinsdagavond3bgroot2"
                component={MatrixloaderDA3bgroot2}
                name="MatrixloaderDA3bgroot2"
              />
              <Route
                path="/Matrixdinsdagavond3bklein3"
                component={MatrixloaderDA3bklein3}
                name="MatrixloaderDA3bklein3"
              />
              <Route
                path="/Matrixdinsdagavond3bgroot3"
                component={MatrixloaderDA3bgroot3}
                name="MatrixloaderDA3bgroot3"
              />
              <Route
                path="/Matrixdinsdagavond3bklein4"
                component={MatrixloaderDA3bklein4}
                name="MatrixloaderDA3bklein4"
              />
              <Route
                path="/Matrixdinsdagavond3bgroot4"
                component={MatrixloaderDA3bgroot4}
                name="MatrixloaderDA3bgroot4"
              /> */}
              <Route
                path="/Matrixwoensdagavond3bgroot"
                component={MatrixloaderWA3bgroot}
                name="MatrixloaderWA3bgroot"
              />
              {/* <Route
                path="/Matrixwoensdagavond3bgroot2"
                component={MatrixloaderWA3bgroot2}
                name="MatrixloaderWA3bgroot2"
              />
              <Route
                path="/Matrixwoensdagavond3bgroot3"
                component={MatrixloaderWA3bgroot3}
                name="MatrixloaderWA3bgroot3"
              />
              <Route
                path="/Matrixwoensdagavond3bgroot4"
                component={MatrixloaderWA3bgroot4}
                name="MatrixloaderWA3bgroot4"
              />
              <Route
                path="/Matrixwoensdagavond3bgroot5"
                component={MatrixloaderWA3bgroot5}
                name="MatrixloaderWA3bgroot5"
              /> */}
              <Route
                path="/Matrixdonderdagavond5Pins"
                component={MatrixloaderDA5Pins}
                name="MatrixloaderDA5Pins"
              />
              {/* <Route
                path="/Matrixdonderdagavond5Pins2"
                component={MatrixloaderDA5Pins2}
                name="MatrixloaderDA5Pins2"
              /> */}
              <Route path="/Overzicht" component={Overzicht} name="Overzicht" />
              <Route path="/Stand" component={Stand} name="Stand" />
              <Route path="/Matrix" component={Matrix} name="Matrix" />
              <Route path="/VijfPins" component={VijfPins} name="VijfPins" />
              {/* <Redirect from="*" to="Scoreboard" /> */}
            </div>
          </Router>
        );
      } else {
        return (
          <div>
            <div>
              <Paper>
                <div
                  className={styles.container}
                  style={{ paddingTop: "50px" }}
                >
                  {window.location.href.split("//")[1].split("/")[0] ===
                    "localhost:3000" &&
                    window.location.href
                      .split("//")[1]
                      .split("/")[0]
                      .replace("00", "oo")
                      .toLowerCase() !== "live.oo67.nl" && (
                      <div align="center">
                        <h1> Scoreboard Onder Ons </h1>
                      </div>
                    )}
                  <p>&nbsp;</p>
                  <div align="center" />
                  <p>&nbsp;</p>
                  {/* {window.location.href
                      .split("//")[1]
                      .split("/")[0]
                      .replace("00", "oo")
                      .toLowerCase() === "live.oo67.nl" && (
                    <div>
                      <ScoreboardLoadertafels14 />
                      <ScoreboardLoadertafels58 />
                    </div>
                  )} */}
                  {// window.location.href.split("//")[1].split("/")[0] !==
                  // "localhost:3000" &&
                  // window.location.href
                  //   .split("//")[1]
                  //   .split("/")[0]
                  //   .replace("00", "oo")
                  //   .toLowerCase() !== "live.oo67.nl" &&
                  this.state.isSignedIn !== undefined &&
                    !this.state.isSignedIn && (
                      <div>
                        <StyledFirebaseAuth
                          className={styles.firebaseUi}
                          uiConfig={this.uiConfig}
                          firebaseAuth={firebase.auth()}
                        />
                      </div>
                    )}
                </div>
                <div>
                  <h6 align="center" style={{ marginTop: "100px" }}>
                    &copy; Pierre de Boer voor Onder Ons 2019
                    {/* {" "}
                    {new Date().getFullYear()} */}
                  </h6>
                </div>
              </Paper>
            </div>
          </div>
        );
      }
    }
  }
);

export default App;
