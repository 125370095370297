import React, { Component } from "react";
import ScoreboardInvoer from "./ScoreboardInvoer";

export default class ScoreboardLoader5 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <ScoreboardInvoer tafel="Tafel5" vereniging="OnderOns" />;
  }
}
