import React, { Component } from "react";
import Stand5Pins from "./Stand5Pins";

export default class StandloaderBSK2019 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Stand5Pins avond="NK5Pins" details="wedstrijden" />;
  }
}
