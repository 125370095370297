import React, { Component } from "react";
import ScoreboardInvoertafels from "./ScoreboardInvoertafels";

export default class Scoreboardloadertafels58 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ScoreboardInvoertafels tafel="1-4" vereniging="OnderOns" />
        <ScoreboardInvoertafels tafel="5-8" vereniging="OnderOns" />
      </div>
    );
  }
}
