import React, { Component } from 'react';
import { View, Text } from 'react-native';
import firebase from 'firebase';
import 'firebase/firestore';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import matchSorter from 'match-sorter';

import { observer } from 'mobx-react';
const firestore = firebase.firestore();
//firestore.settings({ timestampsInSnapshots: true });

const InvoerPartij = observer(
  class InvoerPartij extends Component {
    constructor(props) {
      super(props);
      console.log(this.props.match);
      this.state = {
        data: [],
        avond: this.props.avond,
        dagdeel: '',
        validUser: false,
        vereniging: 'OnderOns'
      };
      this.renderEditable = this.renderEditable.bind(this);
    }
    state = { data: [], dagdeel: '' };

    componentDidMount() {
      let validUser = false;
      // Ik heb allen toegang tot de afgebroken partijen.
      if (firebase.auth().currentUser.email === 'p@de-boer.net') {
        validUser = true;
      }
      this.setState({ validUser });
      let partijen = [];
      const Thuis = 1;
      const Uit = 3;
      //const Weeknummer = 39;

      var reparatieRef = firestore
        .collection(`/${this.state.vereniging}/Wedstrijden/Dins_av/`)
        .limit(5)
        //.where("Thuis", "==", Thuis)
        //.where("Uit", "==", Uit);
        .orderBy('Datum', 'desc');
      //.where("Weeknummer", "==", "42");
      //.orderBy('Weeknummer', 'asc');
      //.where("Winnaar", "==", "Remise");
      //.orderBy("Datum");
      //.where('Datum', '>', 1539686085152);

      // .where("Datum", ">", 1538476619427)
      //.where('Datum', '>', 1539081183728);
      //.where("Uit", ">", 0);
      //.where('Beurten', '==', 0);
      //.where("Speler1", "==", "Jan");
      //console.log(reparatieRef);
      reparatieRef.get().then(querySnapshot => {
        querySnapshot.forEach(function(doc) {
          // Eerst de uitslag platmaken
          let tmpRec = [];
          tmpRec = {
            id: doc.id,
            Datum: doc.data().Datum,
            Beurten: doc.data().Beurten,
            GemaaktSP1: doc.data().GemaaktSP1,
            GemaaktSP2: doc.data().GemaaktSP2,
            TemakenCarambolesSpeler1: doc.data().TemakenCarambolesSpeler1,
            TemakenCarambolesSpeler2: doc.data().TemakenCarambolesSpeler2,
            GemiddeldeSpeler1: '',
            GemiddeldeSpeler2: '',
            Speler1: doc.data().Speler1,
            Speler1Achternaam: doc.data().Speler1Achternaam,
            Speler1HoogsteSerie: doc.data().Speler1HoogsteSerie,
            Speler2: doc.data().Speler2,
            Speler2Achternaam: doc.data().Speler2Achternaam,
            Speler2HoogsteSerie: doc.data().Speler2HoogsteSerie,
            Thuis: doc.data().Thuis,
            Uit: doc.data().Uit,
            Winnaar: doc.data().Winnaar,
            Weeknummer: parseInt(doc.data().Weeknummer)
          };
          partijen.sort(function(x, y) {
            if (x.Thuis > y.Thuis) {
              return 1;
            } else {
              return -1;
            }
          });
          partijen.push(tmpRec);
          //Weeknummer update
          //   firestore
          //     .collection(`/OnderOns/Wedstrijden/Dins_mid/`)
          //     .doc(tmpRec.id)
          //     .update({
          //       Weeknummer: 41
          //     });

          //   Caramboles zetten
          //     if (tmpRec.CarambolesSpeler1 !== undefined) {
          //       firestore
          //         .collection(`/OnderOns/Wedstrijden/Dins_mid/`)
          //         .doc(tmpRec.id)
          //         .update({
          //           TemakenCarambolesSpeler1: tmpRec.CarambolesSpeler1,
          //           TemakenCarambolesSpeler2: tmpRec.CarambolesSpeler2
          //         });
          //     } else {
          //       console.log(tmpRec);
          //     }
        });
        this.setState({ data: partijen });
        console.log(partijen.length);
      });
      // Nu de terugpartij eraan plakken
      //   reparatieRef = firestore
      //     .collection(`/${this.state.vereniging}/Wedstrijden/Dins_mid/`)
      //     //.limit(10)
      //     .where('Weeknummer', '==', '36');
      //   // .where('Thuis', '==', Uit)
      //   // .where('Uit', '==', Thuis);
      //   //.where('Beurten', '==', 0);
      //   //.where("Speler1", "==", "Jan");
      //   //console.log(reparatieRef);
      //   reparatieRef.get().then(querySnapshot => {
      //     partijen = this.state.data;
      //     querySnapshot.forEach(function(doc) {
      //       // Eerst de uitslag platmaken
      //       let tmpRec = [];
      //       tmpRec = {
      //         id: doc.id,
      //         Datum: doc.data().Datum,
      //         Beurten: doc.data().Beurten,
      //         GemaaktSP1: doc.data().GemaaktSP1,
      //         GemaaktSP2: doc.data().GemaaktSP2,
      //         TemakenCarambolesSpeler1: doc.data().TemakenCarambolesSpeler1,
      //         TemakenCarambolesSpeler2: doc.data().TemakenCarambolesSpeler2,
      //         GemiddeldeSpeler1: '',
      //         GemiddeldeSpeler2: '',
      //         Speler1: doc.data().Speler1,
      //         Speler1Achternaam: doc.data().Speler1Achternaam,
      //         Speler1HoogsteSerie: doc.data().Speler1HoogsteSerie,
      //         Speler2: doc.data().Speler2,
      //         Speler2Achternaam: doc.data().Speler2Achternaam,
      //         Speler2HoogsteSerie: doc.data().Speler2HoogsteSerie,
      //         Thuis: doc.data().Thuis,
      //         Uit: doc.data().Uit,
      //         Winnaar: doc.data().Winnaar,
      //         Weeknummer: doc.data().Weeknummer
      //       };

      //       partijen.push(tmpRec);
      //     });
      //     this.setState({ data: partijen });
      //     // console.log(partijen.length);
      //   });
    }

    renderEditable(cellInfo) {
      return (
        <div
          style={{ backgroundColor: '#fafafa' }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const data = [...this.state.data];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ data });
            try {
              let content = data[cellInfo.index];
              //console.log(content);
              content.GemaaktSP1 = parseInt(content.GemaaktSP1, 10);
              content.GemaaktSP2 = parseInt(content.GemaaktSP2, 10);
              content.Beurten = parseInt(content.Beurten, 10);
              content.Speler1HoogsteSerie = parseInt(content.Speler1HoogsteSerie, 10);
              content.Speler2HoogsteSerie = parseInt(content.Speler2HoogsteSerie, 10);

              //let content = e.target.innerHTML;
              firestore
                .collection(`/${this.state.vereniging}/Wedstrijden/Dins_mid/`)
                .doc(data[cellInfo.index].id)
                .update(content);
              // console.log(data[cellInfo.index].id);
            } catch (err) {
              alert('Er is iets misgegaan met updaten ' + err.message);
              console.log(err.message);
            }
          }}
          dangerouslySetInnerHTML={{
            __html: this.state.data[cellInfo.index][cellInfo.column.id]
          }}
        />
      );
    }

    render() {
      const ToonWedstrijden = () => {
        let regel = [];
        this.state.data.map(function(d, idx) {
          regel.push(
            <tr key={idx}>
              <td style={{ padding: 3 }}>{d.datum}</td>
              <td style={{ padding: 3 }}> {d.Speler1}</td>
              <td style={{ padding: 3 }}>{d.Speler2}</td>
              <td style={{ padding: 3 }}>{d.id}</td>
              <td style={{ padding: 3 }}>
                <Text style={{ color: 'orange' }} onPress={() => Verplaats(d, 'Dins_mid')}>
                  -> Dinsmid
                </Text>
              </td>
            </tr>
          );
          //Verplaats(d, "Dins_mid");
        });

        return regel;
      };

      const GenereerKop = () => {
        if (this.state.data.length === 0) {
          return 'Geen wedstrijden beschikbaar';
        }
      };

      const Voetregel = () => {
        const footer = [];

        return footer;
      };

      const Verwijder = id => {
        firebase
          .firestore()
          .collection(`/${this.state.vereniging}/Wachtrij/Dins_mid/`)
          .doc(id)
          .delete();
        //.then(alert("Verwijderd"));
      };

      const Verplaats = (doc, dagdeel) => {
        //console.log(doc);
        firebase
          .firestore()
          .collection(`/${this.state.vereniging}/Wedstrijden/Dins_mid/`)
          .doc(doc.id)
          .set(doc);
        //.then(Verwijder(doc.id));
      };

      //   const children = this.state.data.map(function(wedstrijd) {
      //     console.log(wedstrijd);
      //     return {
      //       ID: wedstrijd.id,
      //       voornaam: wedstrijd.Speler1,
      //       achternaam: wedstrijd.Speler1Achternaam,
      //       Beurten: wedstrijd.Beurten,
      //       Car1: wedstrijd.CarambolesSpeler1,
      //       Car1gemaakt: wedstrijd.GemaaktSP1,
      //       Car2: wedstrijd.CarambolesSpeler2,
      //       HS1: wedstrijd.Speler1HoogsteSerie,
      //       HS2: wedstrijd.Speler2HoogsteSerie,
      //       voornaam2: wedstrijd.Speler2,
      //       achternaam2: wedstrijd.Speler2Achternaam
      //     };
      //     // this.setState({children})
      //   });
      const { data } = this.state;
      return (
        this.state.validUser && (
          <View>
            {/* <h3>Overzicht</h3> */}
            <div style={{ minWidth: 400 }}>
              <ReactTable
                data={data}
                previousText="Vorige"
                nextText="Volgende"
                loadingText="Laden..."
                noDataText="Geen rijen gevonden"
                pageText="Pagina"
                ofText="van"
                rowsText="rijen"
                filterable
                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                columns={[
                  {
                    Header: 'ID',
                    columns: [
                      {
                        Header: 'ID',
                        accessor: 'id',
                        width: 50,
                        filterable: false,
                        show: false
                      },
                      {
                        accessor: 'Weeknummer',
                        width: 50,
                        filterable: false,
                        Cell: this.renderEditable,
                        show: true
                      }
                    ]
                  },
                  {
                    Header: 'Speler1',
                    columns: [
                      {
                        Header: 'T',
                        accessor: 'Thuis',
                        Cell: this.renderEditable,
                        width: 50,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['Thuis']
                          }),
                        filterAll: true
                      },
                      {
                        Header: 'Voornaam',
                        accessor: 'Speler1',
                        Cell: this.renderEditable,
                        width: 100,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['Speler1']
                          }),
                        filterAll: true
                      },
                      {
                        Header: 'Achternaam',
                        accessor: 'Speler1Achternaam',
                        Cell: this.renderEditable,
                        width: 150,

                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['Speler1Achternaam']
                          }),
                        filterAll: true
                      },
                      {
                        Header: 'Caramboles',
                        accessor: 'TemakenCarambolesSpeler1',
                        Cell: this.renderEditable,
                        width: 50
                      },
                      {
                        Header: 'Car Sp1 gemaakt',
                        accessor: 'GemaaktSP1',
                        Cell: this.renderEditable,
                        width: 80,
                        style: { backgroundColor: 'steelblue', color: 'blue' }
                      },
                      {
                        Header: 'HS1',
                        accessor: 'Speler1HoogsteSerie',
                        Cell: this.renderEditable,
                        width: 50,
                        style: { backgroundColor: 'steelblue' }
                      }
                    ]
                  },
                  {
                    Header: 'Beurten',
                    columns: [
                      {
                        Header: 'Beurten',
                        accessor: 'Beurten',
                        Cell: this.renderEditable,
                        width: 50,
                        style: { backgroundColor: 'green' }
                      }
                    ]
                  },
                  {
                    Header: 'Speler2',
                    columns: [
                      {
                        Header: 'Car Sp2 gemaakt',
                        accessor: 'GemaaktSP2',
                        Cell: this.renderEditable,
                        width: 80,
                        style: { backgroundColor: 'steelblue' }
                      },
                      {
                        Header: 'HS2',
                        accessor: 'Speler2HoogsteSerie',
                        Cell: this.renderEditable,
                        width: 50,
                        style: { backgroundColor: 'steelblue' }
                      },
                      {
                        Header: 'Caramboles',
                        accessor: 'TemakenCarambolesSpeler2',
                        Cell: this.renderEditable,
                        width: 50
                      },
                      {
                        Header: 'T',
                        accessor: 'Uit',
                        Cell: this.renderEditable,
                        width: 50,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['Uit']
                          }),
                        filterAll: true
                      },
                      {
                        Header: 'Voornaam',
                        accessor: 'Speler2',
                        Cell: this.renderEditable,
                        width: 100,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['Speler2']
                          }),
                        filterAll: true
                      },
                      {
                        Header: 'Achternaam',
                        accessor: 'Speler2Achternaam',
                        Cell: this.renderEditable,
                        width: 150,

                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ['Speler2Achternaam']
                          }),
                        filterAll: true
                      }
                      //   {
                      //     Header: 'Winnaar',
                      //     accessor: 'Winnaar',
                      //     Cell: this.renderEditable,
                      //     width: 150
                      //   }
                    ]
                  }
                  // {
                  //   Header: 'Diversen',
                  //   columns: [
                  //     {
                  //       Header: 'Telefoon',
                  //       accessor: 'telefoon',
                  //       width: 100,
                  //       filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['telefoon'] }),
                  //       filterAll: true
                  //     },
                  //     {
                  //       Header: 'Geslacht',
                  //       accessor: 'geslacht',
                  //       width: 100,
                  //       filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['geslacht'] }),
                  //       filterAll: true
                  //     }
                  //   ]
                  // }
                ]}
                defaultSorted={[
                  {
                    id: 'achternaam',
                    desc: false
                  }
                ]}
                defaultPageSize={36}
                className="-striped -hightlight"
              />
              {/* <table border="1" cellpadding="0" cellspacing="0" style={{ padding: 3 }}>
              {GenereerKop()}
              <tbody>
                {ToonWedstrijden()}
                <tr>
                  <td colSpan="7" style={{ padding: 3, margin: 10 }}>
                    {Voetregel()}
                  </td>
                </tr>
              </tbody>
            </table> */}
            </div>
          </View>
        )
      );
    }
  }
);
export default InvoerPartij;
