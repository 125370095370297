import React, { Component } from "react";
import { Navbar, Nav, NavItem, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { firebase } from "../models/firestore";
import "./CustomNavbar.css";

export default class CustomNavbar extends Component {
  render() {
    return (
      <Navbar default collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">Scoreboard v1.90</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <DropdownButton
            title="Kies een tafel"
            id="tafel"
            style={{ marginTop: "6px" }}
          >
            <NavItem
              eventKey={1}
              componentClass={Link}
              href="/Scoreboardtafels14"
              to="/Scoreboardtafels14"
            >
              Tafel 1-4
            </NavItem>
            <NavItem
              eventKey={1}
              componentClass={Link}
              href="/Scoreboardtafels58"
              to="/Scoreboardtafels58"
            >
              Alle tafels
            </NavItem>
            {/* <NavItem
              eventKey={1}
              componentClass={Link}
              href="/Scoreboardtafels78"
              to="/Scoreboardtafels78"
            >
              Matchtafels
            </NavItem> */}
            <NavItem
              eventKey={1}
              componentClass={Link}
              href="/Scoreboard"
              to="/Scoreboard"
            >
              Tafel 1
            </NavItem>
            <NavItem
              eventKey={2}
              componentClass={Link}
              href="/Scoreboard2"
              to="/Scoreboard2"
            >
              Tafel 2
            </NavItem>
            <NavItem
              eventKey={3}
              componentClass={Link}
              href="/Scoreboard3"
              to="/Scoreboard3"
            >
              Tafel 3
            </NavItem>
            <NavItem
              eventKey={4}
              componentClass={Link}
              href="/Scoreboard4"
              to="/Scoreboard4"
            >
              Tafel 4
            </NavItem>
            <NavItem
              eventKey={5}
              componentClass={Link}
              href="/Scoreboard5"
              to="/Scoreboard5"
            >
              Tafel 5
            </NavItem>

            <NavItem
              eventKey={6}
              componentClass={Link}
              href="/Scoreboard6"
              to="/Scoreboard6"
            >
              Tafel 6
            </NavItem>
            <NavItem
              eventKey={7}
              componentClass={Link}
              href="/Scoreboard7"
              to="/Scoreboard7"
            >
              Tafel 7
            </NavItem>
            <NavItem
              eventKey={8}
              componentClass={Link}
              href="/Scoreboard8"
              to="/Scoreboard8"
            >
              Tafel 8
            </NavItem>
            <NavItem
              eventKey={9}
              componentClass={Link}
              href="/ScoreboardThuis"
              to="/ScoreboardThuis"
            >
              TH
            </NavItem>
          </DropdownButton>
          {firebase.auth().currentUser.email !== "tablet@oo67.nl" ? (
            <DropdownButton
              title="Standen"
              id="Standen"
              style={{ marginTop: "6px" }}
            >
              <NavItem>
                <h5
                  style={{
                    backgroundColor: "Blue",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  --Actueel --
                </h5>
              </NavItem>
              <NavItem
                eventKey={112}
                componentClass={Link}
                href="/Standmaandagavond"
                to="/Standmaandagavond"
              >
                Stand maandagavond
              </NavItem>
              <NavItem
                eventKey={313}
                componentClass={Link}
                href="/Standdinsdagavond"
                to="/Standdinsdagavond"
              >
                Stand dinsdagavond
              </NavItem>
              <NavItem
                eventKey={145}
                componentClass={Link}
                href="/Standwoensdagavond"
                to="/Standwoensdagavond"
              >
                Stand woensdagavond
              </NavItem>
              <NavItem
                eventKey={145}
                componentClass={Link}
                href="/Standdonderdagavond5Pins"
                to="/Standdonderdagavond5Pins"
              >
                Stand donderdagavond 5 Pins
              </NavItem>
              <NavItem
                eventKey={145}
                componentClass={Link}
                href="/Stand5Pins"
                to="/Stand5Pins"
              >
                Stand 5Pins
              </NavItem>
              <NavItem
                eventKey={1452}
                componentClass={Link}
                href="/StandBiatlon"
                to="/StandBiatlon"
              >
                Stand Biatlon
              </NavItem>
              {/* <NavItem
                eventKey={145}
                componentClass={Link}
                href="/StandBSK2019"
                to="/StandBSK2019"
              >
                Stand BSK 2019
              </NavItem>
              <NavItem>
                <h5 style={{ color: "green", fontWeight: "bold" }}>
                  -- Vorige periodes --
                </h5>
              </NavItem>
              <NavItem
                eventKey={11}
                componentClass={Link}
                href="/Standmaandagavond"
                to="/Standmaandagavond"
              >
                Stand maandagavond
              </NavItem>
              <NavItem
                eventKey={112}
                componentClass={Link}
                href="/Standmaandagavond2"
                to="/Standmaandagavond2"
              >
                Stand maandagavond 2
              </NavItem>

              <NavItem
                eventKey={112}
                componentClass={Link}
                href="/Standmaandagavond3"
                to="/Standmaandagavond3"
              >
                Stand maandagavond 3
              </NavItem>

              <NavItem
                eventKey={11}
                componentClass={Link}
                href="/Standdinsdagmiddag"
                to="/Standdinsdagmiddag"
              >
                Stand dinsdagmiddag
              </NavItem>
              <NavItem
                eventKey={11}
                componentClass={Link}
                href="/Standdinsdagavond"
                to="/Standdinsdagavond"
              >
                Stand dinsdagavond
              </NavItem>
              <NavItem
                eventKey={113}
                componentClass={Link}
                href="/Standdinsdagavond2"
                to="/Standdinsdagavond2"
              >
                Stand dinsdagavond 2
              </NavItem>
              <NavItem
                eventKey={313}
                componentClass={Link}
                href="/Standdinsdagavond3"
                to="/Standdinsdagavond3"
              >
                Stand dinsdagavond 3
              </NavItem>
              <NavItem
                eventKey={114}
                componentClass={Link}
                href="/Standwoensdagavond"
                to="/Standwoensdagavond"
              >
                Stand woensdagavond
              </NavItem>
              <NavItem
                eventKey={115}
                componentClass={Link}
                href="/Standwoensdagavond2"
                to="/Standwoensdagavond2"
              >
                Stand woensdagavond 2
              </NavItem>
              <NavItem
                eventKey={145}
                componentClass={Link}
                href="/Standwoensdagavond3"
                to="/Standwoensdagavond3"
              >
                Stand woensdagavond 3
              </NavItem>
              <NavItem
                eventKey={145}
                componentClass={Link}
                href="/Standdonderdagavond5Pins"
                to="/Standdonderdagavond5Pins"
              >
                Stand donderdagavond 5 Pins
              </NavItem> */}

              <NavItem>
                <h5
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  -- 3 Banden --
                </h5>
              </NavItem>
              <NavItem>
                <h5 style={{ color: "green", fontWeight: "bold" }}>
                  -- Actueel --
                </h5>
              </NavItem>
              <NavItem
                eventKey={118}
                componentClass={Link}
                href="/Standdinsdagavond3bklein"
                to="/Standdinsdagavond3bklein"
              >
                Stand dinsdagavond 3banden klein
              </NavItem>
              <NavItem
                eventKey={119}
                componentClass={Link}
                href="/Standdinsdagavond3bgroot"
                to="/Standdinsdagavond3bgroot"
              >
                Stand dinsdagavond 3banden groot
              </NavItem>
              <NavItem
                eventKey={1211}
                componentClass={Link}
                href="/Standwoensdagavond3bgroot"
                to="/Standwoensdagavond3bgroot"
              >
                Stand woensdagavond 3b groot
              </NavItem>
              {/* <NavItem>-- Vorige periodes --</NavItem>
              <NavItem
                eventKey={116}
                componentClass={Link}
                href="/Standdinsdagavond3bklein"
                to="/Standdinsdagavond3bklein"
              >
                Stand dinsdagavond 3banden klein
              </NavItem>
              <NavItem
                eventKey={117}
                componentClass={Link}
                href="/Standdinsdagavond3bgroot"
                to="/Standdinsdagavond3bgroot"
              >
                Stand dinsdagavond 3banden groot
              </NavItem>
              <NavItem
                eventKey={118}
                componentClass={Link}
                href="/Standdinsdagavond3bklein2"
                to="/Standdinsdagavond3bklein2"
              >
                Stand dinsdagavond 3banden klein 2
              </NavItem>
              <NavItem
                eventKey={119}
                componentClass={Link}
                href="/Standdinsdagavond3bgroot2"
                to="/Standdinsdagavond3bgroot2"
              >
                Stand dinsdagavond 3banden groot 2
              </NavItem>
              <NavItem
                eventKey={118}
                componentClass={Link}
                href="/Standdinsdagavond3bklein3"
                to="/Standdinsdagavond3bklein3"
              >
                Stand dinsdagavond 3banden klein 3
              </NavItem>
              <NavItem
                eventKey={119}
                componentClass={Link}
                href="/Standdinsdagavond3bgroot3"
                to="/Standdinsdagavond3bgroot3"
              >
                Stand dinsdagavond 3banden groot 3
              </NavItem>

              <NavItem
                eventKey={120}
                componentClass={Link}
                href="/Standwoensdagavond3bgroot"
                to="/Standwoensdagavond3bgroot"
              >
                Stand woensdagavond 3b groot
              </NavItem>
              <NavItem
                eventKey={121}
                componentClass={Link}
                href="/Standwoensdagavond3bgroot2"
                to="/Standwoensdagavond3bgroot2"
              >
                Stand woensdagavond 3b groot (2)
              </NavItem>
              <NavItem
                eventKey={1211}
                componentClass={Link}
                href="/Standwoensdagavond3bgroot3"
                to="/Standwoensdagavond3bgroot3"
              >
                Stand woensdagavond 3b groot (3)
              </NavItem>
              <NavItem
                eventKey={1211}
                componentClass={Link}
                href="/Standwoensdagavond3bgroot4"
                to="/Standwoensdagavond3bgroot4"
              >
                Stand woensdagavond 3b groot (4)
              </NavItem> */}
            </DropdownButton>
          ) : null}
          {firebase.auth().currentUser.email !== "tablet@oo67.nl" ? (
            <DropdownButton
              title="Matrix"
              id="Matrix"
              style={{ marginTop: "6px" }}
            >
              <NavItem
                eventKey={122}
                componentClass={Link}
                href="/Matrixmaandagavond"
                to="/Matrixmaandagavond"
              >
                Matrix maandagavond
              </NavItem>
              {/* <NavItem
                eventKey={123}
                componentClass={Link}
                href="/Matrixmaandagavond2"
                to="/Matrixmaandagavond2"
              >
                Matrix maandagavond 2
              </NavItem>
              <NavItem
                eventKey={123}
                componentClass={Link}
                href="/Matrixmaandagavond3"
                to="/Matrixmaandagavond3"
              >
                Matrix maandagavond 3
              </NavItem>
              <NavItem
                eventKey={123}
                componentClass={Link}
                href="/Matrixmaandagavond4"
                to="/Matrixmaandagavond4"
              >
                Matrix maandagavond 4
              </NavItem> */}
              {/* <NavItem
                eventKey={11}
                componentClass={Link}
                href="/Matrixdinsdagmiddag"
                to="/Matrixdinsdagmiddag"
              >
                Matrix dinsdagmiddag
              </NavItem> */}
              <NavItem
                eventKey={124}
                componentClass={Link}
                href="/Matrixdinsdagavond"
                to="/Matrixdinsdagavond"
              >
                Matrix dinsdagavond
              </NavItem>
              {/* 
              <NavItem
                eventKey={125}
                componentClass={Link}
                href="/Matrixdinsdagavond2"
                to="/Matrixdinsdagavond2"
              >
                Matrix dinsdagavond 2
              </NavItem>
              <NavItem
                eventKey={127}
                componentClass={Link}
                href="/Matrixdinsdagavond3"
                to="/Matrixdinsdagavond3"
              >
                Matrix dinsdagavond 3
              </NavItem>
              <NavItem
                eventKey={127}
                componentClass={Link}
                href="/Matrixdinsdagavond4"
                to="/Matrixdinsdagavond4"
              >
                Matrix dinsdagavond 4
              </NavItem> */}
              <NavItem
                eventKey={126}
                componentClass={Link}
                href="/Matrixwoensdagavond"
                to="/Matrixwoensdagavond"
              >
                Matrix woensdagavond
              </NavItem>
              <NavItem
                eventKey={127}
                componentClass={Link}
                href="/Matrixclub70"
                to="/Matrixclub70"
              >
                Matrix Club '70
              </NavItem>
              {/* <NavItem
                eventKey={127}
                componentClass={Link}
                href="/Matrixwoensdagavond2"
                to="/Matrixwoensdagavond2"
              >
                Matrix woensdagavond 2
              </NavItem>
              <NavItem
                eventKey={167}
                componentClass={Link}
                href="/Matrixwoensdagavond3"
                to="/Matrixwoensdagavond3"
              >
                Matrix woensdagavond 3
              </NavItem>
              <NavItem
                eventKey={167}
                componentClass={Link}
                href="/Matrixwoensdagavond4"
                to="/Matrixwoensdagavond4"
              >
                Matrix woensdagavond 4
              </NavItem> */}
              <NavItem>-- 3 Banden --</NavItem>
              <NavItem
                eventKey={10}
                componentClass={Link}
                href="/Matrixdinsdagavond3bklein"
                to="/Matrixdinsdagavond3bklein"
              >
                Matrix dinsdagavond 3banden klein
              </NavItem>
              <NavItem
                eventKey={12}
                componentClass={Link}
                href="/Matrixdinsdagavond3bgroot"
                to="/Matrixdinsdagavond3bgroot"
              >
                Matrix dinsdagavond 3banden groot
              </NavItem>
              {/* <NavItem
                eventKey={10}
                componentClass={Link}
                href="/Matrixdinsdagavond3bklein2"
                to="/Matrixdinsdagavond3bklein2"
              >
                Matrix dinsdagavond 3banden klein 2
              </NavItem>
              <NavItem
                eventKey={12}
                componentClass={Link}
                href="/Matrixdinsdagavond3bgroot2"
                to="/Matrixdinsdagavond3bgroot2"
              >
                Matrix dinsdagavond 3banden groot 2
              </NavItem>
              <NavItem
                eventKey={10}
                componentClass={Link}
                href="/Matrixdinsdagavond3bklein3"
                to="/Matrixdinsdagavond3bklein3"
              >
                Matrix dinsdagavond 3banden klein 3
              </NavItem>
              <NavItem
                eventKey={12}
                componentClass={Link}
                href="/Matrixdinsdagavond3bgroot3"
                to="/Matrixdinsdagavond3bgroot3"
              >
                Matrix dinsdagavond 3banden groot 3
              </NavItem>
              <NavItem
                eventKey={10}
                componentClass={Link}
                href="/Matrixdinsdagavond3bklein4"
                to="/Matrixdinsdagavond3bklein4"
              >
                Matrix dinsdagavond 3banden klein 4
              </NavItem>
              <NavItem
                eventKey={12}
                componentClass={Link}
                href="/Matrixdinsdagavond3bgroot4"
                to="/Matrixdinsdagavond3bgroot4"
              >
                Matrix dinsdagavond 3banden groot 4
              </NavItem> */}
              <NavItem
                eventKey={13}
                componentClass={Link}
                href="/Matrixwoensdagavond3bgroot"
                to="/Matrixwoensdagavond3bgroot"
              >
                Matrix woensdagavond 3b groot
              </NavItem>
              {/* <NavItem
                eventKey={13}
                componentClass={Link}
                href="/Matrixwoensdagavond3bgroot2"
                to="/Matrixwoensdagavond3bgroot2"
              >
                Matrix woensdagavond 3b groot 2
              </NavItem>
              <NavItem
                eventKey={131}
                componentClass={Link}
                href="/Matrixwoensdagavond3bgroot3"
                to="/Matrixwoensdagavond3bgroot3"
              >
                Matrix woensdagavond 3b groot 3
              </NavItem>
              <NavItem
                eventKey={131}
                componentClass={Link}
                href="/Matrixwoensdagavond3bgroot4"
                to="/Matrixwoensdagavond3bgroot4"
              >
                Matrix woensdagavond 3b groot 4
              </NavItem>
              <NavItem
                eventKey={131}
                componentClass={Link}
                href="/Matrixwoensdagavond3bgroot5"
                to="/Matrixwoensdagavond3bgroot5"
              >
                Matrix woensdagavond 3b groot 5
              </NavItem> */}
              <NavItem>-- 5 Pins --</NavItem>
              <NavItem
                eventKey={131}
                componentClass={Link}
                href="/Matrixdonderdagavond5Pins"
                to="/Matrixdonderdagavond5Pins"
              >
                Matrix Donderdagavond 5Pins
              </NavItem>
              {/* <NavItem
                eventKey={131}
                componentClass={Link}
                href="/Matrixdonderdagavond5Pins2"
                to="/Matrixdonderdagavond5Pins2"
              >
                Matrix Donderdagavond 5Pins2
              </NavItem> */}
            </DropdownButton>
          ) : null}

          <Nav pullRight>
            <NavItem
              eventKey={10}
              componentClass={Link}
              href="/Uitleg"
              to="/Uitleg"
            >
              Help / uitleg
            </NavItem>
            <NavItem
              eventKey={10}
              componentClass={Link}
              href="/VijfPins"
              to="/VijfPins"
            >
              5 Pins
            </NavItem>

            <NavItem
              eventKey={30}
              componentClass={Link}
              href="/AfgebrokenPartijen"
              to="/AfgebrokenPartijen"
            >
              {firebase.auth().currentUser.email === "p@de-boer.net"
                ? "AfgebrokenPartijen"
                : ""}
            </NavItem>
            <NavItem
              eventKey={12}
              componentClass={Link}
              href="/invoer.html"
              to="/Invoer"
            >
              Invoer
            </NavItem>

            {/* <NavItem
              eventKey={12}
              componentClass={Link}
              href="/Overzicht"
              to="/Overzicht"
            >
              Overzicht
            </NavItem> */}
            {/* <NavItem
              eventKey={2}
              componentClass={Link}
              href="/klanten"
              to="/klanten"
            >
              Klanten
            </NavItem>
            <NavItem
              eventKey={3}
              componentClass={Link}
              href="/vrijwilligers"
              to="/vrijwilligers"
            >
              Vrijwilligers
            </NavItem> */}
            <Navbar.Text>
              <Navbar.Link onClick={() => firebase.auth().signOut()}>
                Uitloggen: {this.props.gebruikersNaam}
              </Navbar.Link>
            </Navbar.Text>
            {/* <NavItem eventKey={3} componentClass={Link} href="/news" to="/news">
              News
            </NavItem> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
