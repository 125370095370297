//import * as Firebase from "firebase";
import firebase from "firebase/app";
import "@firebase/database";

let HAS_INITIALIZED = false;

const initFirebase = () => {
  if (!HAS_INITIALIZED) {
    const config = {
      apiKey: "AIzaSyB30bvijZULbR5_Vvta54eRzzOW_VxJbKo",
      authDomain: "scoreboard-35372.firebaseapp.com",
      databaseURL: "https://scoreboard-35372.firebaseio.com",
      projectId: "scoreboard-35372",
      storageBucket: "scoreboard-35372.appspot.com",
      messagingSenderId: "436218732725"
    };
    // Firebase.database.enableLogging(true)
    firebase.initializeApp(config);
    HAS_INITIALIZED = true;
  }
};

export const getDatabase = () => {
  initFirebase();
  return firebase.database();
};

export const getStorage = () => {
  initFirebase();
  return firebase.storage();
};

export const getFirebase = () => {
  initFirebase();
  //return Firebase.firestore();
};

export { firebase };

// import * as Firebase from "firebase";
// import firebase from "@firebase/app";
// import "@firebase/firestore";
// import { initFirestorter, Collection } from "firestorter";
// const config = {
//   apiKey: "AIzaSyB30bvijZULbR5_Vvta54eRzzOW_VxJbKo",
//   authDomain: "scoreboard-35372.firebaseapp.com",
//   databaseURL: "https://scoreboard-35372.firebaseio.com",
//   projectId: "scoreboard-35372",
//   storageBucket: "scoreboard-35372.appspot.com",
//   messagingSenderId: "436218732725"
// };
// firebase.initializeApp(config);
// let firestore;
// firestore = firebase.firestore();
// firestore.settings({ timestampsInSnapshots: true });
// initFirestorter({ firebase: firebase });
// const wedstrijden = new Collection("wedstrijden");
// //const klanten = new Collection("klanten");
// //const gebruikers = new Collection("gebruikers");

// export { firebase, wedstrijden };

// export const getDatabase = () => {
//   //initFirebase();
//   return Firebase.database();
// };
// //https://firebase.google.com/docs/firestore/solutions/arrays

// // // Sample document in the 'posts' collection
// // {
// //   title: "My great post",
// //   categories: {
// //       "technology": true,
// //       "opinion": true,
// //       "cats": true
// //   }
// // }

// // Now it's easy to query for all blog posts within a single category:

// // WEB
// // // Find all documents in the 'posts' collection that are
// // // in the 'cats' category.
// // db.collection('posts')
// //   .where('categories.cats', '==', true)
// //   .get()
// //   .then(() => {
// //       // ...
// //   });

// // )
