import React, { Component } from 'react';
//import { Text } from "react-native";
//import * as Firebase from "firebase";
import 'firebase/auth';
import { getDatabase } from '../models/firestore';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

//const apikey = "eb96440115b64cc1b73320697a02219b";
//iplocation = https://api.ipgeolocation.io/ipgeo?apiKey=API_KEY&ip=1.1.1.1

const rootRef = getDatabase()
  .ref()
  .child('BiljartWedstrijd');
let WedstrijdData1 = rootRef;
let WedstrijdData2 = rootRef;
let WedstrijdData3 = rootRef;
let WedstrijdData4 = rootRef;
//let WedstrijdData = "";

const styles = {
  card: {
    flex: 1,
    flexDirection: 'row',
    minWidth: 275,
    backgroundColor: '#E2FBFF',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
    flex: 1,
    paddingLeft: 2,
    paddingRight: 2,
  },
  Mededeling: {
    width: '100%',
    fontSize: 20,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'blue',
  },
  Smallekolom: {
    width: '15%',
    fontSize: 18,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'blue',
  },
  SmallekolomGray: {
    width: '15%',
    fontSize: 12,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'gray',
  },
  Spelernaam: {
    width: '100%',

    fontSize: 18,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'white',
  },
  SpelernaamGray: {
    width: '70%',
    fontSize: 12,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'gray',
  },
  ActiveSpeler: {
    backgroundColor: 'green',
  },
  NonActiveSpeler: {
    backgroundColor: 'white',
  },
  avatarBeurten: {
    //position: "relative",
    //postion: "float",
    // marginBottom: -80,
    // marginLeft: 0,
    width: 30,
    height: 30,
    fontSize: 18,
    backgroundColor: 'red',
    color: 'white',
  },
  avatarCaramboles: {
    //position: "relative",
    //postion: "float",
    // marginBottom: -80,
    // marginLeft: 0,
    width: 40,
    height: 40,
    fontSize: 28,
    backgroundColor: 'green',
    color: 'white',
  },
  avatarCarambolesGray: {
    width: 50,
    height: 50,
    fontSize: 28,
    backgroundColor: 'gray',
  },
  avatarSerie: {
    //position: "relative",
    //postion: "float",
    // marginBottom: -80,
    //paddingLeft: 50,
    width: 30,
    height: 30,
    fontSize: 12,
    backgroundColor: 'gold',
    color: 'black',
  },
  avatarSerieGray: {
    width: 25,
    height: 25,
    fontSize: 12,
    backgroundColor: 'gray',
  },
  avatarHuidigCaramboles: {
    width: 90,
    height: 90,
    fontSize: 68,
    backgroundColor: 'blue',
  },
  avatarHuidigCarambolesGray: {
    width: 90,
    height: 90,
    fontSize: 68,
    backgroundColor: 'gray',
  },
  appbarSpeler: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'steelblue',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
    marginTop: -15,
  },
  appbarSpelerGray: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'lightgray',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
    marginTop: -15,
  },
  appbarSpeler2: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'steelblue',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
    //marginTop: -15
  },
  appbarSpeler2Gray: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'lightgray',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
  },
  addCaramboles: {
    alignItems: 'right',
  },
};

class ScoreboardInvoertafels extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.match);
    this.state = {
      Wedstrijd1: [],
      Wedstrijd2: [],
      Wedstrijd3: [],
      Wedstrijd4: [],
      title: '',
      tafelNaam: this.props.tafel,
      vereniging: this.props.vereniging,
      ipadres: '0.0.0.0',
      editmode: false,
    };
  }

  componentDidMount() {
    let title = '';
    if (this.props.tafel === '1-4') {
      title = '';
      if (window.location.href.split('//')[1].split('/')[0] === 'localhost:3000') {
        WedstrijdData1 = rootRef.child('Tafelthuis').child('Tafel1');
      } else {
        WedstrijdData1 = rootRef.child(this.state.vereniging).child('Tafel1');
      }

      WedstrijdData2 = rootRef.child(this.state.vereniging).child('Tafel2');
      WedstrijdData3 = rootRef.child(this.state.vereniging).child('Tafel3');
      WedstrijdData4 = rootRef.child(this.state.vereniging).child('Tafel4');
    }
    if (this.props.tafel === '5-8') {
      title = '';
      WedstrijdData1 = rootRef.child(this.state.vereniging).child('Tafel5');
      WedstrijdData2 = rootRef.child(this.state.vereniging).child('Tafel6');
      WedstrijdData3 = rootRef.child(this.state.vereniging).child('Tafel7');
      WedstrijdData4 = rootRef.child(this.state.vereniging).child('Tafel8');
    }
    WedstrijdData1.on('value', (snap) => {
      this.setState({
        Wedstrijd1: snap.val(),
        currentPage: 0,
        title,
        //width: Dimensions.get("window").width
      });
    });
    WedstrijdData2.on('value', (snap2) => {
      this.setState({
        Wedstrijd2: snap2.val(),
        currentPage: 0,
        //width: Dimensions.get("window").width
      });
    });
    WedstrijdData3.on('value', (snap3) => {
      this.setState({
        Wedstrijd3: snap3.val(),
        currentPage: 0,
        //width: Dimensions.get("window").width
      });
    });
    WedstrijdData4.on('value', (snap4) => {
      this.setState({
        Wedstrijd4: snap4.val(),
        currentPage: 0,
        //width: Dimensions.get("window").width
      });
    });
  }

  componentWillUnmount() {
    WedstrijdData1.off();
    WedstrijdData2.off();
    WedstrijdData3.off();
    WedstrijdData4.off();
  }
  /**
   * @inheritDoc
   */

  render() {
    return (
      <div align="center">
        <div align="center" style={{ maxWidth: 600 }}>
          {/* Tafel 1 */}
          <div>
            {this.state.title !== '' && <h5> {this.state.title}</h5>}
            {!this.state.Wedstrijd1.PartijOpgeslagen && this.state.Wedstrijd1.Speler1 !== 'Wachten op' && (
              <div style={{ marginBottom: 20 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <table border="0" style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {this.state.Wedstrijd1.Speler1} {this.state.Wedstrijd1.Speler1Achternaam} (
                                    {this.state.Wedstrijd1.CarambolesSpeler1})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd1.HuidigCarambolesSp1}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd1.Speler1HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                          <td align="center" style={{ width: '10%' }}>
                            {' '}
                            <Avatar style={styles.avatarBeurten}>{this.state.Wedstrijd1.Beurten}</Avatar>
                          </td>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    !this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      !this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd1.Speler2} {this.state.Wedstrijd1.Speler2Achternaam}(
                                    {this.state.Wedstrijd1.CarambolesSpeler2})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd1.HuidigCarambolesSp2}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {!this.state.Wedstrijd1.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd1.Speler2HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Toolbar>
                </AppBar>
              </div>
            )}
            {!this.state.Wedstrijd1.PartijOpgeslagen &&
              this.state.Wedstrijd1.Starttijd === this.state.Wedstrijd1.Eindtijd &&
              this.state.Wedstrijd1.Speler1 === 'Wachten op' && (
                <div style={{ marginBottom: 20 }}>
                  <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                    <Toolbar style={{ justifyContent: 'flex-start' }}>
                      <Typography color="primary" style={styles.Mededeling}>
                        Geen partij actief op tafel {this.state.tafelNaam === '1-4' ? 1 : 5}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </div>
              )}
            {this.state.Wedstrijd1.PartijOpgeslagen && (
              <div style={{ marginBottom: 20 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <Typography color="primary" style={styles.Mededeling}>
                      Partij is afgelopen. {this.state.Wedstrijd1.Winnaar} heeft gewonnen
                    </Typography>
                  </Toolbar>
                </AppBar>
              </div>
            )}
            {/* Einde tafel 1 */}
            {!this.state.Wedstrijd2.PartijOpgeslagen && this.state.Wedstrijd2.Speler1 !== 'Wachten op' && (
              <div style={{ marginTop: 10 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <table border="0" style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd2.Speler1} {this.state.Wedstrijd2.Speler1Achternaam} (
                                    {this.state.Wedstrijd2.CarambolesSpeler1})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd2.HuidigCarambolesSp1}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd2.Speler1HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                          <td align="center" style={{ width: '10%' }}>
                            {' '}
                            <Avatar style={styles.avatarBeurten}>{this.state.Wedstrijd2.Beurten}</Avatar>
                          </td>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    !this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      !this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd2.Speler2} {this.state.Wedstrijd2.Speler2Achternaam} (
                                    {this.state.Wedstrijd2.CarambolesSpeler2})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd2.HuidigCarambolesSp2}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {!this.state.Wedstrijd2.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd2.Speler2HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Toolbar>
                </AppBar>{' '}
              </div>
            )}
            {!this.state.Wedstrijd2.PartijOpgeslagen &&
              this.state.Wedstrijd2.Starttijd === this.state.Wedstrijd2.Eindtijd &&
              this.state.Wedstrijd2.Speler1 === 'Wachten op' && (
                <div style={{ marginBottom: 20 }}>
                  <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                    <Toolbar style={{ justifyContent: 'flex-start' }}>
                      <Typography color="primary" style={styles.Mededeling}>
                        Geen partij actief op tafel {this.state.tafelNaam === '1-4' ? 2 : 6}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </div>
              )}
            {this.state.Wedstrijd2.PartijOpgeslagen && (
              <div style={{ marginBottom: 20 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <Typography color="primary" style={styles.Mededeling}>
                      Partij is afgelopen. {this.state.Wedstrijd2.Winnaar} heeft gewonnen
                    </Typography>
                  </Toolbar>
                </AppBar>
              </div>
            )}
            {/* Einde tafel 2 */}
            {!this.state.Wedstrijd3.PartijOpgeslagen && this.state.Wedstrijd3.Speler1 !== 'Wachten op' && (
              <div style={{ marginTop: 10 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <table border="0" style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd3.Speler1} {this.state.Wedstrijd3.Speler1Achternaam} (
                                    {this.state.Wedstrijd3.CarambolesSpeler1})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd3.HuidigCarambolesSp1}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd3.Speler1HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                          <td align="center" style={{ width: '10%' }}>
                            {' '}
                            <Avatar style={styles.avatarBeurten}>{this.state.Wedstrijd3.Beurten}</Avatar>
                          </td>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    !this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      !this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd3.Speler2} {this.state.Wedstrijd3.Speler2Achternaam} (
                                    {this.state.Wedstrijd3.CarambolesSpeler2})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd3.HuidigCarambolesSp2}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {!this.state.Wedstrijd3.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd3.Speler2HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Toolbar>
                </AppBar>
              </div>
            )}
            {!this.state.Wedstrijd3.PartijOpgeslagen &&
              this.state.Wedstrijd3.Starttijd === this.state.Wedstrijd3.Eindtijd &&
              this.state.Wedstrijd3.Speler1 === 'Wachten op' && (
                <div style={{ marginBottom: 20 }}>
                  <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                    <Toolbar style={{ justifyContent: 'flex-start' }}>
                      <Typography color="primary" style={styles.Mededeling}>
                        Geen partij actief op tafel {this.state.tafelNaam === '1-4' ? 3 : 7}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </div>
              )}
            {this.state.Wedstrijd3.PartijOpgeslagen && (
              <div style={{ marginTop: 10 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <Typography color="primary" style={styles.Mededeling}>
                      Partij is afgelopen. {this.state.Wedstrijd3.Winnaar} heeft gewonnen
                    </Typography>
                  </Toolbar>
                </AppBar>
              </div>
            )}
            {/* Einde tafel 3 */}
            {!this.state.Wedstrijd4.PartijOpgeslagen && this.state.Wedstrijd4.Speler1 !== 'Wachten op' && (
              <div style={{ marginTop: 10 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <table border="0" style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd4.Speler1} {this.state.Wedstrijd4.Speler1Achternaam} (
                                    {this.state.Wedstrijd4.CarambolesSpeler1})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd4.HuidigCarambolesSp1}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd4.Speler1HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                          <td align="center" style={{ width: '10%' }}>
                            {' '}
                            <Avatar style={styles.avatarBeurten}>{this.state.Wedstrijd4.Beurten}</Avatar>
                          </td>
                          <td style={{ width: '45%' }}>
                            <table
                              border="0"
                              style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  style={
                                    !this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                      ? styles.ActiveSpeler
                                      : styles.NonActiveSpeler
                                  }
                                >
                                  <Typography
                                    color="primary"
                                    style={
                                      !this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                        ? styles.Spelernaam
                                        : styles.SpelernaamGray
                                    }
                                  >
                                    {' '}
                                    {this.state.Wedstrijd4.Speler2} {this.state.Wedstrijd4.Speler2Achternaam} (
                                    {this.state.Wedstrijd4.CarambolesSpeler2})
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 30,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    width: 100,
                                  }}
                                >
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                        ? styles.avatarCaramboles
                                        : styles.avatarCarambolesGray
                                    }
                                  >
                                    {this.state.Wedstrijd4.HuidigCarambolesSp2}
                                  </Avatar>
                                </td>
                                <td>
                                  <Avatar
                                    style={
                                      !this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                        ? styles.avatarSerie
                                        : styles.avatarSerieGray
                                    }
                                  >
                                    {!this.state.Wedstrijd4.Speler1IsAanDeBeurt
                                      ? this.state.Wedstrijd4.Speler2HuidigeSerie
                                      : ''}
                                  </Avatar>
                                </td>
                              </tr>
                            </table>

                            <div />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Toolbar>
                </AppBar>
              </div>
            )}
            <div style={{ marginTop: 10 }} />
            {!this.state.Wedstrijd4.PartijOpgeslagen &&
              this.state.Wedstrijd4.Starttijd === this.state.Wedstrijd4.Eindtijd &&
              this.state.Wedstrijd4.Speler1 === 'Wachten op' && (
                <div style={{ marginBottom: 20 }}>
                  <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                    <Toolbar style={{ justifyContent: 'flex-start' }}>
                      <Typography color="primary" style={styles.Mededeling}>
                        Geen partij actief op tafel {this.state.tafelNaam === '1-4' ? 4 : 8}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </div>
              )}
            {this.state.Wedstrijd4.PartijOpgeslagen && (
              <div style={{ marginBottom: 20 }}>
                <AppBar position="static" color="default" style={{ backgroundColor: 'white' }}>
                  <Toolbar style={{ justifyContent: 'flex-start' }}>
                    <Typography color="primary" style={styles.Mededeling}>
                      Partij is afgelopen. {this.state.Wedstrijd4.Winnaar} heeft gewonnen
                    </Typography>
                  </Toolbar>
                </AppBar>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ScoreboardInvoertafels);
