import React, { Component } from "react";
import "firebase/auth";
import { firebase } from "../models/firestore";
import { Textfit } from "react-textfit";

export default class Uitleg extends Component {
  render() {
    return (
      <div style={{ margin: 10 }}>
        <p style={{ fontSize: 14 }}>
          {" "}
          Beste {firebase.auth().currentUser.displayName.split(" ")[0]},
        </p>
        <p>
          <Textfit>
            Versie 1.88 8 september 2022 <br />
            -Club 70 als Matrix toegevoegd
            <br />
          </Textfit>
          <Textfit>
            Versie 1.90 21 oktober 2022 <br />
            -Tafelsrestricties doorgevoerd in Matrixen
            <br />
          </Textfit>
        </p>
        <br />
        Groet,{" "}
        <p>
          {" "}
          <br />
          <i>Pierre</i>
        </p>
        <div />
      </div>
    );
  }
}
