import React, { Component } from 'react';
import Matrix from './Matrix';

export default class MatrixloaderWA3bgroot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Matrix avond="woensdagavond3bgroot" />;
  }
}
