import React, { Component } from "react";
import Matrix from "./Matrix";

export default class MatrixloaderDA3bklein extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Matrix avond="dinsdagavond3bklein" />;
  }
}
