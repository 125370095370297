import React, { Component } from "react";
import { View } from "react-native";
import firebase from "firebase";
import "firebase/firestore";

//import Tellijst from "./Tellijst";
//getFirebase();
const firestore = firebase.firestore();
//firestore.settings({ timestampsInSnapshots: true });

class Overzicht extends Component {
  state = { data: [] };

  componentDidMount() {
    // var gespeeldewedstrijdenRef = firestore.collection(
    //   "/OnderOns/Wedstrijden/Dins_mid/"
    // );
    // var gespeeldewedstrijden = await gespeeldewedstrijdenRef.get();
    // this.setState(gespeeldewedstrijden.docs);
    //console.log(gespeeldewedstrijden);
    let dagdeel = "";
    var urlParams = new URLSearchParams(window.location.search);
    var keys = urlParams.get("avond");
    console.log(urlParams);
    if (keys === null) {
      keys = "niets";
    }

    console.log(keys);
    switch (keys.toLowerCase()) {
      case "maandagavond":
        dagdeel = "Maan_av";

        break;

      case "dinsdagmiddag":
        dagdeel = "Dins_mid";
        break;

      case "dinsdagavond":
        dagdeel = "Dins_av";
        break;

      case "dinsdagavond3bklein":
        dagdeel = "Dins_av_3B_klein";
        break;

      case "dinsdagavond3bgroot":
        dagdeel = "Dins_av_3B_groot";
        break;

      case "woensdagavond":
        dagdeel = "Woens_av";
        break;

      case "woensdagavond3bgroot":
        dagdeel = "Woens_av_3B_groot";
        break;

      default:
        dagdeel = "niets";
        break;
    }
    const eenweekgeleden = new Date(
      new Date() - 60 * 60 * 24 * 7 * 1000
    ).getTime();

    var gespeeldewedstrijdenRef = firestore.collection(
      `/OnderOns/Wedstrijden/${dagdeel}/`
    );
    console.log(gespeeldewedstrijdenRef);
    gespeeldewedstrijdenRef
      //.where("Datum", ">", eenweekgeleden)
      //.where("Beurten", ">=", 30)

      //.limit(2)
      .get()
      .then(querySnapshot => {
        const data = [];
        querySnapshot.forEach(function(doc) {
          data.push(doc.data());
        });

        //!------ keysort toekennen aan de array
        data.keySort = function(keys) {
          keys = keys || {};

          var obLen = function(obj) {
            var size = 0,
              key;
            for (key in obj) {
              if (obj.hasOwnProperty(key)) size++;
            }
            return size;
          };

          var obIx = function(obj, ix) {
            var size = 0,
              key;
            for (key in obj) {
              if (obj.hasOwnProperty(key)) {
                if (size === ix) return key;
                size++;
              }
            }
            return false;
          };

          var keySort = function(a, b, d) {
            d = d !== null ? d : 1;
            // a = a.toLowerCase(); // this breaks numbers
            // b = b.toLowerCase();
            if (a === b) return 0;
            return a > b ? 1 * d : -1 * d;
          };

          var KL = obLen(keys);

          if (!KL) return this.sort(keySort);

          for (var k in keys) {
            // asc unless desc or skip
            keys[k] =
              keys[k] === "desc" || keys[k] === -1
                ? -1
                : keys[k] === "skip" || keys[k] === 0
                  ? 0
                  : 1;
          }

          this.sort(function(a, b) {
            var sorted = 0,
              ix = 0;

            while (sorted === 0 && ix < KL) {
              var k = obIx(keys, ix);
              if (k) {
                var dir = keys[k];
                sorted = keySort(a[k], b[k], dir);
                ix++;
              }
            }
            return sorted;
          });
          return this;
        };

        if (keys.toLowerCase() === "dinsdagmiddag") {
          const sortorder = { Thuis: "asc", Uit: "Asc" };
          data.keySort(sortorder);
        } else {
          const sortorderdatum = { Datum: "asc" };
          data.keySort(sortorderdatum);
        }
        this.setState({ data });
      });
  }

  render() {
    return (
      <View>
        {this.state.data.map(function(d, idx) {
          return (
            <div>
              <div id={idx}>
                {d.Thuis && (
                  <strong>
                    {d.Speler1} {d.Speler1Achternaam} Team {d.Thuis} tegen{" "}
                    {d.Speler2} {d.Speler2Achternaam} Team {d.Uit}
                  </strong>
                )}
                {!d.Thuis && (
                  <strong>
                    {d.Speler1} {d.Speler1Achternaam} tegen {d.Speler2}{" "}
                    {d.Speler2Achternaam}
                  </strong>
                )}
              </div>

              <p>
                <div>
                  {d.Speler1} {d.Speler1Achternaam}{" "}
                  <strong>{d.GemaaktSP1}</strong> van de{" "}
                  <strong>{d.TemakenCarambolesSpeler1}</strong> caramboles in{" "}
                  <strong>{d.Beurten}</strong> beurten. Hoogste serie{" "}
                  <strong>{d.Speler1HoogsteSerie}</strong>
                </div>
                <div>
                  {d.Speler2} {d.Speler2Achternaam}{" "}
                  <strong>{d.GemaaktSP2}</strong> van de{" "}
                  <strong>{d.TemakenCarambolesSpeler2}</strong> caramboles in{" "}
                  <strong>{d.Beurten}</strong> beurten. Hoogste serie{" "}
                  <strong>{d.Speler2HoogsteSerie}</strong>
                </div>
              </p>
            </div>
          );
        })}
      </View>
    );
  }
}
export default Overzicht;
