import React, { Component } from "react";
import ScoreboardInvoertafels from "./ScoreboardInvoertafels";

export default class Scoreboardloadertafels14 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <ScoreboardInvoertafels tafel="1-4" vereniging="OnderOns" />;
  }
}
