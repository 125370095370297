import React, { Component } from "react";
import Stand from "./Stand";

export default class StandloaderDM extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Stand avond="dinsdagmiddag" />;
  }
}
