import React, { Component } from 'react';
import ScoreboardInvoer from './ScoreboardInvoer';
const apikey = 'eb96440115b64cc1b73320697a02219b';
export default class ScoreboardLoaderThuis extends Component {
  constructor(props) {
    super(props);
    this.state = { editmode: true };
  }
  componentDidMount() {
    const ipurl = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + apikey;
    fetch(ipurl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ ipadres: data.ip });
        console.log('ipadres', data.ip);
      })

      .then((niets) =>
        this.state.ipadres === '80.127.228.55' ||
        this.state.ipadres === '62.207.255.217' ||
        this.state.ipadres === '2001:985:a046:1:904c:c009:2b1d:7032' ||
        this.state.ipadres === '92.65.111.34' ||
        this.state.ipadres === '84.241.193.8' ||
        true
          ? this.setState({ editmode: true })
          : this.setState({ editmode: false })
      );
  }

  render() {
    return (
      <div>
        {this.state.editmode && <ScoreboardInvoer tafel="Tafel1" vereniging="Tafelthuis" />}

        {!this.state.editmode && (
          <div
            align="center"
            style={{
              marginTop: 20,
              color: 'red',
              fontSize: 48,
              fontWeight: 'bold',
            }}
          >
            Access Denied voor {this.state.ipadres}
          </div>
        )}
      </div>
    );
  }
}
