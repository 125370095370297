import React, { Component } from 'react';
import Stand from './Stand';

export default class StandloaderDA3bgroot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Stand avond="dinsdagavond3bgroot" details="wedstrijden" />;
  }
}
