import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//import firebase from "@firebase/app";
//import "@firebase/firestore";
// import { FirestoreProvider } from "react-firestore";
//import { firestore } from "firebase-admin";
//import { initFirestorter, Collection } from "firestorter";
import "./index.css";
import App from "./App";
const theme = createMuiTheme({
  overrides: {
    body: {
      fontSize: 24
    }
  }
});

ReactDOM.render(
  // <FirestoreProvider firebase={firebase}>
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  // </FirestoreProvider>,
  document.getElementById("root")
);
