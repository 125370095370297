import React, { Component } from "react";

import Stand from "./Stand";

export default class StandloaderDA extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Stand avond="dinsdagavond" details="wedstrijden" />;
  }
}
