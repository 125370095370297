import React, { Component } from "react";
import Matrix from "./Matrix";

export default class MatrixloaderDM extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Matrix avond="dinsdagmiddag" />;
  }
}
