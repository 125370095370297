import React, { Component } from 'react';
import { View, Text } from 'react-native';
import 'firebase/auth';
import { getDatabase, firebase } from '../models/firestore';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
// import { ToastContainer, toast, Zoom, Slide } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function getParameterByName(name, url) {
//   if (!url) url = window.location.href;
//   name = name.replace(/[\[\]]/g, "\\$&");
//   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return "";
//   return decodeURIComponent(results[2].replace(/\+/g, " "));
// }

//let tafelNaam = "Tafel1";
// try {
//   tafelNaam = getParameterByName("tafel");
// } catch (err) {
//   tafelNaam = "Tafel1";
// }
//let vereniging = getParameterByName("vereniging");
//let vereniging = "Tafelthuis";
const apikey = 'eb96440115b64cc1b73320697a02219b';
//iplocation = https://api.ipgeolocation.io/ipgeo?apiKey=API_KEY&ip=1.1.1.1

const rootRef = getDatabase()
  .ref()
  .child('BiljartWedstrijd');
let WedstrijdData = '';
let Settings = '';

const styles = {
  card: {
    flex: 1,
    flexDirection: 'row',
    minWidth: 275,
    backgroundColor: '#E2FBFF',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
    flex: 1,
    paddingLeft: 7,
    paddingRight: 7,
  },
  Spelernaam: {
    fontSize: 28,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'white',
  },
  SpelernaamGray: {
    fontSize: 28,
    //fontWeight: "bold",
    paddingLeft: 10,
    color: 'gray',
  },
  avatarCaramboles: {
    width: 50,
    height: 50,
    fontSize: 28,
    backgroundColor: 'black',
  },
  avatarCarambolesGray: {
    width: 50,
    height: 50,
    fontSize: 28,
    backgroundColor: 'gray',
  },
  avatarHuidigCaramboles: {
    width: 90,
    height: 90,
    fontSize: 68,
    backgroundColor: 'blue',
  },
  avatarHuidigCarambolesGray: {
    width: 90,
    height: 90,
    fontSize: 68,
    backgroundColor: 'gray',
  },
  appbarSpeler: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'steelblue',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
    marginTop: -15,
  },
  appbarSpelerGray: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'lightgray',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
    marginTop: -15,
  },
  appbarSpeler2: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'steelblue',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
    //marginTop: -15
  },
  appbarSpeler2Gray: {
    flex: 1,
    //flexDirection: "row",
    backgroundColor: 'lightgray',
    //alignItems: "stretch",
    justifyContent: 'space-between',
    borderRadius: 5,
  },
  addCaramboles: {
    alignItems: 'right',
  },
};

class ScoreboardInvoer extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.match);
    this.state = {
      Wedstrijd: [],
      //Settings: [],
      tafelNaam: this.props.tafel,
      vereniging: this.props.vereniging,
      ipadres: '0.0.0.0',
      editmode: false,
    };
  }

  componentDidMount() {
    WedstrijdData = rootRef.child(this.state.vereniging).child(this.state.tafelNaam);
    // Settings = rootRef.child(this.state.vereniging).child('SB1920');

    WedstrijdData.on('value', (snap) => {
      this.setState({
        Wedstrijd: snap.val(),
        currentPage: 0,
        //width: Dimensions.get("window").width
      });
    });
    // Settings.on('value', (snap) => {
    //   this.setState({
    //     Settings: snap.val(),
    //     currentPage: 0,
    //     //width: Dimensions.get("window").width
    //   });
    // });

    const ipurl = 'https://api.ipgeolocation.io/ipgeo?apiKey=' + apikey;
    fetch(ipurl)
      .then((response) => response.json())
      .then((data) => this.setState({ ipadres: data.ip }))
      .then((niets) =>
        // this.state.ipadres === '80.127.228.55' ||
        // this.state.ipadres === '77.166.147.183' ||
        // this.state.ipadres === '62.207.255.217' ||
        // this.state.ipadres === '2001:985:a046:1:904c:c009:2b1d:7032' ||
        // this.state.ipadres === '2a02:a468:f7ef:1:548c:1f14:6ab7:b90a' ||
        // this.state.ipadres === '92.65.111.34' ||
        firebase.auth().currentUser.email === 'p@de-boer.net' ||
        firebase.auth().currentUser.email === 'franktrappmann@gmail.com' ||
        firebase.auth().currentUser.email === 'app@de-boer.net' 

          ? /* //this.state.Settings.ipadressen.indexOf(this.state.ipadres) > -1 */
            this.setState({ editmode: true })
          : null
      );
  }
  /**
   * @inheritDoc
   */

  render() {
    return (
      <div align="center">
        <div align="center" style={{ maxWidth: 300 }}>
          {!this.state.Wedstrijd.PartijOpgeslagen && (
            <div>
              <div>{this.state.Wedstrijd.Bericht}</div>
              <div style={styles.root}>
                <AppBar
                  position="static"
                  color="default"
                  style={this.state.Wedstrijd.Speler1IsAanDeBeurt ? styles.appbarSpeler : styles.appbarSpelerGray}
                >
                  <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Avatar
                      style={{
                        width: 80,
                        height: 80,
                        margin: 4,
                      }}
                      src={this.state.Wedstrijd.Speler1URL}
                      //sizes="200"
                      rounded
                    />
                    <Typography
                      color="primary"
                      style={this.state.Wedstrijd.Speler1IsAanDeBeurt ? styles.Spelernaam : styles.SpelernaamGray}
                      onClick={() =>
                        WedstrijdData.update({
                          Commando: 'wisselspelers',
                          Ipadres: this.state.ipadres,
                        })
                      }
                    >
                      {this.state.Wedstrijd.Speler1} {this.state.Wedstrijd.Speler1Achternaam}
                    </Typography>

                    <Avatar
                      style={
                        this.state.Wedstrijd.Speler1IsAanDeBeurt ? styles.avatarCaramboles : styles.avatarCarambolesGray
                      }
                    >
                      {this.state.Wedstrijd.CarambolesSpeler1}
                    </Avatar>
                  </Toolbar>
                </AppBar>
                <Card style={styles.card}>
                  <div
                    style={{
                      flex: 1,
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        float: 'left',
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 30,
                        paddinRight: 30,
                        width: '66%',
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: this.state.Wedstrijd.Speler1IsAanDeBeurt ? 'gold' : 'lightsteelblue',
                          color: 'black',
                          // backgroundColor: "red"
                          width: 48,
                          height: 48,
                          fontSize: 24,
                          marginLeft: 65,
                          marginBottom: -20,
                        }}
                      >
                        {this.state.Wedstrijd.Speler1HuidigeSerie}
                      </Avatar>
                      <Avatar
                        style={
                          this.state.Wedstrijd.Speler1IsAanDeBeurt
                            ? styles.avatarHuidigCaramboles
                            : styles.avatarHuidigCarambolesGray
                        }
                      >
                        {this.state.Wedstrijd.HuidigCarambolesSp1}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        float: 'left',
                        padding: 5,
                        width: '34%',
                      }}
                    >
                      <p
                        align="center"
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginBottom: -20,
                        }}
                      >
                        {this.state.Wedstrijd.GemSpeler1}
                      </p>
                      <Button
                        disabled={
                          (this.state.Wedstrijd.Speler1IsAanDeBeurt === true ? false : true) || !this.state.editmode
                        }
                        variant="fab"
                        color="primary"
                        style={{ width: 90, height: 90, marginTop: 25 }}
                        onClick={() =>
                          firebase.auth().currentUser.displayName === this.state.Wedstrijd.GebruikerInEditMode
                            ? WedstrijdData.update({
                                Commando: 'voegcaramboletoe',
                                Gebruiker: firebase.auth().currentUser.displayName,
                              })
                            : null
                        }
                      >
                        {this.state.Wedstrijd.CarNogOverSpeler1 === '' &&
                          firebase.auth().currentUser.displayName === this.state.Wedstrijd.GebruikerInEditMode && (
                            <AddIcon />
                          )}
                        {this.state.Wedstrijd.CarNogOverSpeler1 !== '' && (
                          <Text style={{ fontSize: 14 }}>en nog {this.state.Wedstrijd.CarNogOverSpeler1}</Text>
                        )}
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
              <div
                align="center"
                style={{
                  flex: 1,
                  fontsize: 24,
                  padding: 5,
                  marginLeft: 0,
                  marginRight: 0,
                  justifyContent: 'space-between',
                  //alignItems: "center",
                  backgroundColor: '#ACD5FC',
                  border: 1,
                  borderColor: '#cecece',
                  borderStyle: 'solid',
                  borderRadius: 5,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {this.state.Wedstrijd.GebruikerInEditMode === firebase.auth().currentUser.displayName &&
                  this.state.editmode && (
                    <Button
                      variant="contained"
                      style={{
                        marginRight: 30,
                        backgroundColor: 'tomato',
                        color: 'white',
                        height: 50,
                        //padding: 20,
                        fontSize: 12,
                      }}
                      onClick={() =>
                        WedstrijdData.update({
                          Commando: 'herstellaatsteactie',
                          Gebruiker: firebase.auth().currentUser.displayName,
                        })
                      }
                    >
                      Herstel
                    </Button>
                  )}

                <Text style={{ fontSize: 18, color: '#000000' }}>Beurt </Text>
                <Text style={{ fontSize: 24, color: 'blue', fontStyle: 'bold' }}>
                  {' '}
                  {/* <Avatar> {this.state.Wedstrijd.Beurten}</Avatar> */}
                  {this.state.Wedstrijd.Beurten}
                </Text>
                {this.state.Wedstrijd.GebruikerInEditMode === firebase.auth().currentUser.displayName &&
                  this.state.editmode && (
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: 30,
                        backgroundColor: '#66FF66',
                        color: 'black',
                        height: 50,
                        fontSize: 12,
                      }}
                      onClick={() =>
                        WedstrijdData.update({
                          Commando: 'volgendespeler',
                          Gebruiker: firebase.auth().currentUser.displayName,
                        })
                      }
                    >
                      Beurt ->{' '}
                      {this.state.Wedstrijd.Speler1IsAanDeBeurt
                        ? this.state.Wedstrijd.Speler2
                        : this.state.Wedstrijd.Speler1}
                    </Button>
                  )}
              </div>
              <div style={styles.root}>
                <AppBar
                  position="static"
                  color="default"
                  style={this.state.Wedstrijd.Speler1IsAanDeBeurt ? styles.appbarSpeler2Gray : styles.appbarSpeler2}
                >
                  <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Avatar
                      style={{
                        width: 80,
                        height: 80,
                        margin: 4,
                      }}
                      src={this.state.Wedstrijd.Speler2URL}
                      //sizes="200"
                      rounded
                    />
                    <Typography
                      color="primary"
                      style={this.state.Wedstrijd.Speler1IsAanDeBeurt ? styles.SpelernaamGray : styles.Spelernaam}
                      onClick={() =>
                        WedstrijdData.update({
                          Commando: 'wisselspelers',
                          Gebruiker: firebase.auth().currentUser.displayName,
                        })
                      }
                    >
                      {this.state.Wedstrijd.Speler2} {this.state.Wedstrijd.Speler2Achternaam}
                    </Typography>

                    <Avatar
                      style={
                        this.state.Wedstrijd.Speler1IsAanDeBeurt ? styles.avatarCarambolesgray : styles.avatarCaramboles
                      }
                    >
                      {this.state.Wedstrijd.CarambolesSpeler2}
                    </Avatar>
                  </Toolbar>
                </AppBar>
                <Card style={styles.card}>
                  <div
                    style={{
                      flex: 1,
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        float: 'left',
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 30,
                        paddinRight: 30,
                        width: '66%',
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: this.state.Wedstrijd.Speler1IsAanDeBeurt ? 'lightsteelblue' : 'gold',
                          color: 'black',
                          // backgroundColor: "red"
                          width: 48,
                          height: 48,
                          fontSize: 24,
                          marginLeft: 65,
                          marginBottom: -20,
                        }}
                      >
                        {this.state.Wedstrijd.Speler2HuidigeSerie}
                      </Avatar>
                      <Avatar
                        style={
                          this.state.Wedstrijd.Speler1IsAanDeBeurt
                            ? styles.avatarHuidigCarambolesGray
                            : styles.avatarHuidigCaramboles
                        }
                      >
                        {this.state.Wedstrijd.HuidigCarambolesSp2}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        float: 'left',
                        padding: 5,
                        width: '34%',
                      }}
                    >
                      <p
                        align="center"
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginBottom: -20,
                        }}
                      >
                        {this.state.Wedstrijd.GemSpeler2}
                      </p>

                      <Button
                        disabled={
                          (this.state.Wedstrijd.Speler1IsAanDeBeurt === true ? true : false) || !this.state.editmode
                        }
                        variant="fab"
                        color="primary"
                        style={{ width: 90, height: 90, marginTop: 25 }}
                        onClick={() =>
                          firebase.auth().currentUser.displayName === this.state.Wedstrijd.GebruikerInEditMode
                            ? WedstrijdData.update({
                                Commando: 'voegcaramboletoe',
                                Gebruiker: firebase.auth().currentUser.displayName,
                              })
                            : null
                        }
                      >
                        {this.state.Wedstrijd.CarNogOverSpeler2 === '' &&
                          firebase.auth().currentUser.displayName === this.state.Wedstrijd.GebruikerInEditMode && (
                            <AddIcon />
                          )}
                        {this.state.Wedstrijd.CarNogOverSpeler2 !== '' && (
                          <Text style={{ fontSize: 14 }}>en nog {this.state.Wedstrijd.CarNogOverSpeler2}</Text>
                        )}
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
              <div align="center" style={{ padding: 10, color: 'darkgray' }}>
                {this.state.tafelNaam} bij {this.state.vereniging}{' '}
                {this.state.Wedstrijd.GebruikerInEditMode !== ''
                  ? `Arbiter: ${this.state.Wedstrijd.GebruikerInEditMode}`
                  : ''}
              </div>
              {!this.state.editmode && (
                <div align="center" style={{ padding: 10, color: 'darkgray' }}>
                  U bent niet verbonden met het wifinetwerk van Onder Ons. De app is in alleen-lezen mode gestart
                </div>
              )}
              <div>
                {this.state.Wedstrijd.Gebruiker === firebase.auth().currentUser.displayName &&
                  (firebase.auth().currentUser.email === 'p@de-boer.net' ||
                    firebase.auth().currentUser.email === 'tablet@oo67.nl' ||
                    firebase.auth().currentUser.email === 'franktrappmann@gmail.com') && (
                    <View>
                      <View style={{ padding: 5 }}>
                        {this.state.editmode && (
                          <Button
                            variant="contained"
                            style={{
                              //marginLeft: 20,
                              backgroundColor: 'green',
                              color: 'white',
                            }}
                            onClick={() =>
                              WedstrijdData.update({
                                Commando: '',
                                GebruikerInEditMode: '',
                                Gebruiker: '',
                              })
                            }
                          >
                            Geef tafel vrij voor andere arbiter
                          </Button>
                        )}

                        {this.state.editmode &&
                          this.state.Wedstrijd.Beurten === 0 &&
                          this.state.Wedstrijd.Speler1 !== 'Wachten op' && (
                            <Button
                              variant="contained"
                              style={{
                                //marginLeft: 20,
                                backgroundColor: 'blue',
                                color: 'white',
                                marginTop: 10,
                              }}
                              onClick={() => {
                                WedstrijdData.update({
                                  Commando: 'wisselspelers',
                                  Gebruiker: firebase.auth().currentUser.displayName,
                                });
                              }}
                            >
                              Wissel spelers
                            </Button>
                          )}
                        <div className="jumbotron" style={{ marginTop: '250px' }}>
                          <h4 class="display-4">Opties</h4>
                          <div class="d-flex justify-content-around">
                            <div class="p-3">
                              {this.state.editmode && this.state.Wedstrijd.HuidigCarambolesSp1 > 1 && (
                                <Button
                                  variant="contained"
                                  style={{
                                    //marginLeft: 20,
                                    backgroundColor: 'blue',
                                    color: 'white',
                                    marginTop: 10,
                                    width: '90%',
                                    //width: '45%'
                                  }}
                                  onClick={() =>
                                    WedstrijdData.update({
                                      HuidigCarambolesSp1: this.state.Wedstrijd.HuidigCarambolesSp1 - 1,
                                    })
                                  }
                                >
                                  Bij {this.state.Wedstrijd.Speler1} een carambole er af halen
                                </Button>
                              )}
                            </div>
                            <div class="p-3">
                              {this.state.editmode && this.state.Wedstrijd.HuidigCarambolesSp2 > 1 && (
                                <Button
                                  variant="contained"
                                  style={{
                                    //marginLeft: 20,
                                    backgroundColor: 'blue',
                                    color: 'white',
                                    marginTop: 25,
                                    width: '90%',
                                    //width: "45%"
                                  }}
                                  onClick={() =>
                                    WedstrijdData.update({
                                      HuidigCarambolesSp2: this.state.Wedstrijd.HuidigCarambolesSp2 - 1,
                                    })
                                  }
                                >
                                  Bij {this.state.Wedstrijd.Speler2} een carambole er af halen
                                </Button>
                              )}
                            </div>
                            <div class="p-3">
                              {this.state.editmode && this.state.Wedstrijd.Beurten > 1 && (
                                <Button
                                  variant="contained"
                                  style={{
                                    //marginLeft: 20,
                                    backgroundColor: 'red',
                                    color: 'white',
                                    marginTop: 25,
                                    width: '90%',
                                    //width: "45%"
                                  }}
                                  onClick={() =>
                                    WedstrijdData.update({
                                      Beurten: this.state.Wedstrijd.Beurten - 1,
                                    })
                                  }
                                >
                                  Beurten met 1 beurt verminderen
                                </Button>
                              )}
                            </div>
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'salmon',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Formeel: this.state.Wedstrijd.Formeel ? false : true,
                                  })
                                }
                              >
                                {this.state.Wedstrijd.Formeel ? 'Toon alleen voornaam' : 'Toon volledige naam'}
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'lightsteelblue',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Spelsoort:
                                      this.state.Wedstrijd.Spelsoort === '3Banden' ? 'LibreKaderBS' : '3Banden',
                                  })
                                }
                              >
                                {this.state.Wedstrijd.Spelsoort === '3Banden'
                                  ? 'Zet om naar Libre / kader / Bandstoot partij'
                                  : 'Zet om naar 3Banden partij'}
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'lightsteelblue',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Spelsoort: '5Ball',
                                    CarambolesSpeler1: 0,
                                    CarambolesSpeler2: 0,
                                    HuidigCarambolesSp1: 101,
                                    HuidigCarambolesSp2: 101,
                                  })
                                }
                              >
                                'Zet om naar 5ball partij'
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'lightsteelblue',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Spelsoort: '5Pins',
                                  })
                                }
                              >
                                Zet om naar 5 Pins partij
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'yellow',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Beurtenlimiet: 0,
                                  })
                                }
                              >
                                Beurtenlimiet opheffen
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'yellow',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Beurtenlimiet: 15,
                                  })
                                }
                              >
                                Beurtenlimiet 15 beurten
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'yellow',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                  //width: '45%'
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Beurtenlimiet: 50,
                                  })
                                }
                              >
                                Beurtenlimiet 50 beurten
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'yellow',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    Beurtenlimiet: 60,
                                  })
                                }
                              >
                                Beurtenlimiet 60 beurten
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'pink',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    BestOf: 2,
                                  })
                                }
                              >
                                BestOf 2
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'pink',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    BestOf: 3,
                                  })
                                }
                              >
                                BestOf 3
                              </Button>
                            )}
                          </div>
                          <div class="p-3">
                            {this.state.editmode && (
                              <Button
                                variant="contained"
                                //className="primary"
                                style={{
                                  //marginLeft: 20,
                                  backgroundColor: 'pink',
                                  color: 'black',
                                  marginTop: 25,
                                  width: '90%',
                                }}
                                onClick={() =>
                                  WedstrijdData.update({
                                    BestOf: 5,
                                  })
                                }
                              >
                                BestOf 5
                              </Button>
                            )}
                          </div>
                        </div>
                      </View>
                    </View>
                  )}
                {/* TODO: Arbiter laten bevestigen door afstandsbediening */}
                {this.state.Wedstrijd.GebruikerInEditMode === '' &&
                  this.state.Wedstrijd.Gebruiker !== firebase.auth().currentUser.displayName &&
                  (firebase.auth().currentUser.email === 'p@de-boer.net' ||
                    firebase.auth().currentUser.email === 'tablet@oo67.nl' ||
                    firebase.auth().currentUser.email === 'franktrappmann@gmail.com') && (
                    <View style={{ padding: 20 }}>
                      {this.state.editmode && (
                        <Button
                          variant="contained"
                          style={{
                            //marginLeft: 20,
                            backgroundColor: 'green',
                            color: 'white',
                          }}
                          onClick={() =>
                            WedstrijdData.update({
                              Commando: '',
                              Gebruiker: firebase.auth().currentUser.displayName,
                              GebruikerInEditMode: firebase.auth().currentUser.displayName,
                            })
                          }
                        >
                          Wordt arbiter van deze partij.
                        </Button>
                      )}
                    </View>
                  )}

                {(this.state.Wedstrijd.Speler1 === 'Wachten op' || this.state.Wedstrijd.Speler2 === 'Wachten op') && (
                  <View style={{ padding: 5 }}>
                    <Button
                      variant="contained"
                      style={{
                        //marginLeft: 20,
                        backgroundColor: 'blue',
                        color: 'white',
                      }}
                      onClick={() =>
                        WedstrijdData.update({
                          Commando: 'showvolgendepartij',
                          Gebruiker: firebase.auth().currentUser.displayName,
                        })
                      }
                    >
                      Laad de volgende partijen.
                    </Button>
                  </View>
                )}
              </div>
            </div>
          )}

          {this.state.Wedstrijd.PartijOpgeslagen && (
            <div style={{ maxWidth: 600 }}>
              <View style={{ alignItems: 'center', flex: 1, marginBottom: 10 }}>
                <Text style={{ fontSize: 24, color: 'blue' }}>Partij is afgelopen:</Text>
              </View>
              <View
                style={{
                  borderRadius: 15,
                  flex: 1,
                  // flexDirection: "row",

                  position: 'absolute',
                  marginLeft: '5%',
                  marginRight: '5%',
                  //width: 520,
                  //width: "90%",
                  //height: 480,
                  backgroundColor: 'lightsteelblue',
                  opacity: 100,
                  //paddingTop: 60,
                  borderWidth: 2,
                  borderColor: 'blue',
                  alignSelf: 'center',
                }}
              >
                <View
                  style={{
                    flex: 1,
                    padding: 10,
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ fontSize: 18, color: 'blue', padding: 20 }}>
                    {this.state.Wedstrijd.Speler1} {this.state.Wedstrijd.Speler1Achternaam}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Temaken Caramboles: {this.state.Wedstrijd.CarambolesSpeler1}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Gemaakte Caramboles: {this.state.Wedstrijd.HuidigCarambolesSp1}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>Beurten: {this.state.Wedstrijd.Beurten}</Text>
                  {/* <Text style={{ fontSize: 12, padding: 2 }}>
                    Aantal punten: {this.state.Wedstrijd._speler1Punten}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Aantal BSK punten: {this.state.Wedstrijd._speler1PuntenBSK}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Gemiddelde: {this.state.Wedstrijd.GemSpeler1}
                  </Text> */}
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Hoogste serie: {this.state.Wedstrijd._speler1HoogsteSerie}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2, color: 'blue' }}>
                    {this.state.Wedstrijd.Winnaar === this.state.Wedstrijd.Speler1
                      ? 'Winnaar !!!'
                      : this.state.Wedstrijd.Winnaar === 'Remise'
                      ? 'Remise'
                      : ''}{' '}
                  </Text>
                </View>
                <View style={{ flex: 1, padding: 10, alignItems: 'center' }}>
                  <Text style={{ fontSize: 18, color: 'blue', padding: 20 }}>
                    {this.state.Wedstrijd.Speler2} {this.state.Wedstrijd.Speler2Achternaam}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Temaken Caramboles: {this.state.Wedstrijd.CarambolesSpeler2}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Gemaakte Caramboles: {this.state.Wedstrijd.HuidigCarambolesSp2}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>Beurten: {this.state.Wedstrijd.Beurten}</Text>
                  {/* <Text style={{ fontSize: 12, padding: 2 }}>
                    Aantal punten: {this.state.Wedstrijd._speler2Punten}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Aantal BSK punten: {this.state.Wedstrijd._speler2PuntenBSK}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Gemiddelde: {this.state.Wedstrijd.GemSpeler2}
                  </Text> */}
                  <Text style={{ fontSize: 12, padding: 2 }}>
                    Hoogste serie: {this.state.Wedstrijd._speler2HoogsteSerie}
                  </Text>
                  <Text style={{ fontSize: 12, padding: 2, color: 'blue' }}>
                    {this.state.Wedstrijd.Winnaar === this.state.Wedstrijd.Speler2
                      ? 'Winnaar !!!'
                      : this.state.Wedstrijd.Winnaar === 'Remise'
                      ? 'Remise'
                      : ''}{' '}
                  </Text>
                </View>
                {this.state.Wedstrijd.GebruikerInEditMode !== '' && (
                  <View style={{ padding: 5 }}>
                    <Button
                      variant="contained"
                      style={{
                        //marginLeft: 20,
                        backgroundColor: 'orange',
                        color: 'white',
                      }}
                      onClick={() => {
                        WedstrijdData.update({
                          Commando: 'showvolgendepartij',
                          Gebruiker: firebase.auth().currentUser.displayName,
                        }).then(alert('Kijk op scoreboard voor de partijen'));
                      }}
                    >
                      Laad de volgende partijen op scoreboard.
                    </Button>
                  </View>
                )}
              </View>
            </div>
          )}
        </div>

        {/* <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          closeButton
        /> */}
      </div>
    );
  }
}
export default withStyles(styles)(ScoreboardInvoer);
