import React, { Component } from "react";
import Stand5Pins from "./Stand5Pins";

export default class StandloaderBiatlon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Stand5Pins avond="Biatlon" details="wedstrijden" />;
  }
}
